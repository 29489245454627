import {
  CLEAR_SELECTED_FOLDER,
  DELETE_MAIN_FOLDER,
  DELETE_SUB_FOLDER,
  GET_FOLDERS,
  MOVE_FOLDER_TO_PARENT,
  MOVE_FOLDER_TO_SUB,
  NEW_MAIN_FOLDER,
  NEW_SUB_FOLDER,
  SEARCH_FOLDERS,
  SELECT_FOLDER,
  UPDATE_MAIN_FOLDER,
  UPDATE_SUB_FOLDER,
} from "../types/folder.types";

const initialState = {
  folders: [],
  loading: true,
  loadingOne: true,
  selectedFolder: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case NEW_MAIN_FOLDER:
      return {
        ...state,
        folders: [payload, ...state.folders],
      };
    case NEW_SUB_FOLDER:
      return {
        ...state,
        selectedFolder: {
          ...state.selectedFolder,
          folders: [payload, ...state.selectedFolder.folders],
        },
      };
    case GET_FOLDERS:
      return {
        ...state,
        folders: payload,
        loading: false,
      };

    case SELECT_FOLDER:
      return {
        ...state,
        loadingOne: false,
        selectedFolder: payload,
        folders: null,
      };

    case CLEAR_SELECTED_FOLDER:
      return {
        ...state,
        loadingOne: true,
        selectedFolder: null,
      };

    case UPDATE_SUB_FOLDER:
      return {
        ...state,
        selectedFolder: {
          ...state.selectedFolder,
          folders: state.selectedFolder.folders.map((el) =>
            el._id === payload._id ? { ...el, ...payload } : el
          ),
        },
      };
    case UPDATE_MAIN_FOLDER:
      return {
        ...state,

        folders: state.folders.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };
    case MOVE_FOLDER_TO_SUB:
      return {
        ...state,
        selectedFolder: {
          ...state.selectedFolder,
          folders: [payload, ...state.selectedFolder.folders],
        },
      };
    case MOVE_FOLDER_TO_PARENT:
      return {
        ...state,
        folders: [payload, ...state.folders],
      };
    case SEARCH_FOLDERS:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SUB_FOLDER:
      return {
        ...state,
        selectedFolder: {
          ...state.selectedFolder,
          folders: state.selectedFolder.folders.filter(
            (el) => el._id !== payload._id
          ),
        },
      };
    case DELETE_MAIN_FOLDER:
      return {
        ...state,
        folders: state.folders.filter((el) => el._id !== payload._id),
      };

    default:
      return state;
  }
}
