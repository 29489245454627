import axios from "axios";
import proxy from "../../utils/proxy";

import {
  GET_MSG_BOARDS,
  UPDATE_PARTICIPATE_MSG_ITEM,
  RESET_MSG_BOARD_STATES,
  UPDATE_MSG_BOARD,
  NEW_BOARD_ITEM,
  DELETE_BOARD_ITEM,
  INITIAL_MSG_BOARD_STATES,
  SET_SELECTED_BOARD,
} from "../types/messageBoard.types";
import { SOCKET_DISPLAY } from "../types/socket.types";
import { actionAlert } from "./action.alert";
import { actionClearModals2 } from "./action.modal";
import handleErrors from "./handleErrors";

export const actionNewBoardItem = ({
  title,
  description,
  status,
  start,
  stop,
  allCanEdit,
  pinned,
  customer,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    title,
    description,
    status,
    start,
    stop,
    allCanEdit,
    pinned,
    customer,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/msgBoard/new-msg-board`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({ type: NEW_BOARD_ITEM, payload: res.data });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionGetMsgBoards = (filter) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    filter,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/msgBoard/get-board-items`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({ type: GET_MSG_BOARDS, payload: res.data });
    }

    return true;
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionGetMsgBoardById = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${proxy}/api/company/msgBoard/get-board-by-id/${id}`,
      config
    );

    if (res.status === 200) {
      dispatch({ type: SET_SELECTED_BOARD, payload: res.data });
    }

    return true;
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionUpdateBoardItem = ({
  title,
  description,
  status,
  boardId,

  start,
  stop,
  askToJoin,
  allCanEdit,
  pinned,
  customer,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    title,
    description,
    status,
    boardId,

    start,
    stop,
    askToJoin,
    allCanEdit,
    pinned,
    customer,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/msgBoard/update-item`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({ type: SET_SELECTED_BOARD, payload: res.data.data });
      dispatch({ type: SOCKET_DISPLAY, payload: res.data.socket });
      dispatch(actionAlert("Onnistui", "success"));
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    dispatch(actionAlert("Epäonnistui", "danger"));

    return null;
  }
};

export const actionIamIn = ({ boardId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ boardId });
  try {
    const res = await axios.put(
      `${proxy}/api/company/msgBoard/participate`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({ type: UPDATE_PARTICIPATE_MSG_ITEM, payload: res.data });
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionCancelParticipate = ({ boardId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ boardId });
  try {
    const res = await axios.put(
      `${proxy}/api/company/msgBoard/cancel-participate`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({ type: UPDATE_PARTICIPATE_MSG_ITEM, payload: res.data });
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionToggleParticipate = ({ boardId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // const body = JSON.stringify({ boardId });
  try {
    const res = await axios.put(
      `${proxy}/api/company/msgBoard/toggle-participate/${boardId}`,
      config
    );

    if (res.status === 200) {
      dispatch({ type: SET_SELECTED_BOARD, payload: res.data });
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionDeleteBoardItem = ({ boardId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/msgBoard/delete/${boardId}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: DELETE_BOARD_ITEM,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    dispatch(actionAlert("Epäonnistui", "danger"));

    return null;
  }
};

export const actionResetMsgBoardStates = () => async (dispatch) => {
  dispatch({ type: RESET_MSG_BOARD_STATES });
};

export const actionInitialMsgBoardStates = () => async (dispatch) => {
  dispatch({ type: INITIAL_MSG_BOARD_STATES });
};

export const actionLinkDislayBoard = ({ boardId, displays }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ boardId, displays });
  try {
    const res = await axios.put(
      `${proxy}/api/company/msgBoard/link-display`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({ type: SET_SELECTED_BOARD, payload: res.data });
      dispatch(actionAlert("Onnistui", "success"));

      dispatch(actionClearModals2());
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionToggleShowOnDb = ({ boardId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // const body = JSON.stringify({ boardId });
  try {
    const res = await axios.put(
      `${proxy}/api/company/msgBoard/toggle-display/${boardId}`,
      config
    );

    if (res.status === 200) {
      dispatch({ type: SET_SELECTED_BOARD, payload: res.data.data });
      dispatch({ type: SOCKET_DISPLAY, payload: res.data.socket });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionUpdateBoardFields = (newFields) => async (dispatch) => {
  dispatch({ type: UPDATE_MSG_BOARD, payload: newFields });
};

///////////////////////////////////////////

export const actionGetMsgBoardsCustomer = (filter) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    filter,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/customer/msgBoard/get-board-items`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({ type: GET_MSG_BOARDS, payload: res.data });
    }

    return true;
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};
