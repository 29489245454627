import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { actionGetParts } from "../../../redux/actions/action.companyData";
import commaToDot from "../../../utils/commaToDot";
import { havePermissions } from "../../../utils/havePermissions";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import IconButton from "../../IconButton/IconButton";
import { iconPlus } from "../../Icons/Icons";
import HideCheckComponent from "../../RestrictComponents/HideCheckComponent";

import "./Selects.scss";

const SelectPartsV2 = ({ parts, setParts, setPartsPrice, disableInputs }) => {
  const plussIcon = <FontAwesomeIcon icon={iconPlus} color="black" size="lg" />;

  const dispatch = useDispatch();
  const { partsList, loadingParts } = useSelector((state) => state.companyData);

  const { permissions2 } = useSelector((state) => state.auth.user);

  const readParts = havePermissions(permissions2, "partList", ["READ"]);
  const noPrices = havePermissions(permissions2, "reports", ["noPrices"]);

  useEffect(() => {
    if (readParts && !disableInputs) {
      if (!partsList || partsList.length === 0) {
        dispatch(actionGetParts());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableInputs]);

  useEffect(() => {
    let partTotal = 0;

    if (parts.length > 0) {
      parts.map((part) => {
        const quantity = part.quantity ? part.quantity : 0;
        const price = part.salesPrice ? part.salesPrice : 0;
        return (partTotal += quantity * price);
      });
    }

    setPartsPrice(partTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parts]);

  const selectPart = (e) => {
    // manuaalisesti lisätyille ei tehdä tässä mitään
    const manualParts = parts.filter((el) => el.manual);

    // myös delete käyttää tätä -> jos poistetaan viimeinen osa listatsta tulee "null" -> halutaan tyhjä array
    // lisätään manuaalisesti lisättyihin uudet valitut osat eli "e"
    const data = e ? [...manualParts, ...e] : manualParts;

    setParts(data);
  };

  const addPartRow = () => {
    const part = {
      title: "",
      quantity: "",
      salesPrice: "",
      warranty: 0,
      manual: true,
    };

    const data = [...parts, part];

    setParts(data);
  };

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    const list = [...parts];

    if (name === "title") {
      list[i][name] = value;
    } else {
      list[i][name] = commaToDot(value);
    }

    setParts(list);
  };

  const removePartsRow = (i) => {
    //  const index = formData.parts.length - 1
    const list = [...parts];
    list.splice(i, 1);

    setParts(list);
  };

  const partsTable = (data) => (
    <Table striped responsive>
      <thead>
        <tr>
          <th className="th1">Nimi</th>
          {!noPrices && <th className="th2">Hinta</th>}
          <th className="th2">Määrä</th>
          {!noPrices && <th className="th2">Yht.</th>}
          <th className="th2">Takuu (kk)</th>

          <th className="th2">Poista</th>
        </tr>
      </thead>

      <tbody>
        {data &&
          data.map((part, i) => (
            <tr key={i}>
              <td>
                <Form.Control
                  name="title"
                  value={part.title || ""}
                  onChange={(e) => handleInputChange(e, i)}
                  disabled={disableInputs}
                />
              </td>
              {!noPrices && (
                <td>
                  <Form.Control
                    name="salesPrice"
                    value={part.salesPrice || ""}
                    onChange={(e) => handleInputChange(e, i)}
                    disabled={disableInputs}
                  />
                </td>
              )}
              <td>
                <Form.Control
                  name="quantity"
                  value={part.quantity || ""}
                  onChange={(e) => handleInputChange(e, i)}
                  type="number"
                  disabled={disableInputs}
                />
              </td>
              {!noPrices && (
                <td>
                  <Form.Control
                    name="total"
                    value={part.quantity * part.salesPrice || 0}
                    type="number"
                    readOnly
                  />
                </td>
              )}

              <td>
                <Form.Control
                  name="warranty"
                  value={part.warranty || ""}
                  onChange={(e) => handleInputChange(e, i)}
                  type="number"
                  disabled={disableInputs}
                />
              </td>

              {part._id ? (
                <td>-</td>
              ) : (
                <td>
                  <Button
                    variant="link"
                    onClick={() => removePartsRow(i)}
                    disabled={disableInputs}
                  >
                    Poista
                  </Button>
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </Table>
  );

  return (
    <div className="Select">
      <div className="selectTitle">
        <h3>Lisätyt tuotteet</h3>
        <div className="divider" />

        <HideCheckComponent required={["READ", "WRITE"]} field="reports">
          {!disableInputs && (
            <IconButton
              type="add"
              onClick={addPartRow}
              icon={plussIcon}
              text={null}
              disabled={disableInputs}
            />
          )}
        </HideCheckComponent>
      </div>

      {loadingParts && !disableInputs ? (
        <CustomSpinner color="blue" />
      ) : (
        <HideCheckComponent required={["READ", "WRITE"]} field="reports">
          <Form.Group>
            <Form.Label>Valitse listasta</Form.Label>
            <Select
              options={partsList}
              onChange={selectPart}
              placeholder="valitse tuotteet"
              isMulti
              value={parts ? parts.filter((el) => !el.manual) : []}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option._id}
              className="select"
              captureMenuScroll={false}
              isDisabled={disableInputs}
            />
            <Form.Text>
              Voit lisätä tuotteita myös suoraan tuotelistasta
            </Form.Text>
          </Form.Group>
        </HideCheckComponent>
      )}

      <br />

      <div>{parts.length > 0 && partsTable(parts)}</div>
    </div>
  );
};

export default SelectPartsV2;
