import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { actionLogout } from "../../redux/actions/action.auth";

import "./UserChanged.scss";

const UserChanged = () => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(15);

  const { userGroup } = useSelector((state) => state.auth.user);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time === 0) {
        logOut();
        clearInterval(interval);
      } else {
        setTime(time - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const logOut = () => {
    dispatch(actionLogout());
  };

  return (
    <div className="changedContainer">
      <div className="mainDiv">
        <p className="p-title">
          {userGroup === "consumer"
            ? "Tunnuksesi on suljettu"
            : "Käyttäjätaso/oikeudet ovat muuttuneet"}
        </p>

        <p>
          Sinut kirjataan ulos <strong>{time}</strong> sekunnin kuluttua{" "}
        </p>

        <Button className="secondaryBtn" onClick={logOut} block>
          Kirjaudu ulos
        </Button>
      </div>
    </div>
  );
};

export default UserChanged;
/*<Row>
        <Col>
          <h1>Käyttäjäoikeudet ovat muuttuneet</h1>
          <p>Ole hyvä ja kirjaudu uudelleen sisään muutosten varmistamiseksi</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            className="secondaryBtn"
            onClick={() => dispatch(actionLogout())}
            block
          >
            Kirjaudu ulos
          </Button>
        </Col>
      </Row>*/
