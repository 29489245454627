import React, { useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { actionGetCompanyCustomers2 } from "../../../redux/actions/action.company";
import "./Selects.scss";

import { havePermissions } from "../../../utils/havePermissions";

export default function SelectCustomerCompany({
  selectCustomer,
  selected,
  disabled,
  disabledText,
  isMulti,
}) {
  const dispatch = useDispatch();
  const { companyCustomers, companyCustomersLoading } = useSelector(
    (state) => state.company
  );
  const { permissions2 } = useSelector((state) => state.auth.user);

  const readCustomerAccess = havePermissions(permissions2, "customersControl", [
    "readCustomers",
  ]);

  useEffect(() => {
    if (readCustomerAccess) {
      if (!companyCustomers || companyCustomers.length === 0) {
        dispatch(actionGetCompanyCustomers2());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Select2">
      {companyCustomersLoading && readCustomerAccess ? (
        <Spinner animation="grow" />
      ) : !companyCustomersLoading && readCustomerAccess ? (
        <div>
          <Form.Group>
            <Select
              isMulti={isMulti}
              onChange={(e) => selectCustomer(e, "company")}
              options={companyCustomers}
              value={selected?.id || selected?._id ? selected : null}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id || option.id}
              placeholder="Valitse..."
              captureMenuScroll={false}
              isDisabled={disabled}
              className="number-1"
            />
            {disabled && <Form.Text>{disabledText || "Ei käytössä"}</Form.Text>}

            {selected && !disabled ? (
              <Button
                variant="link"
                size="sm"
                onClick={() => selectCustomer(null, "company")}
              >
                Poista
              </Button>
            ) : null}
          </Form.Group>
        </div>
      ) : (
        <p>Ei oikeutta asiakasyrityksiin!</p>
      )}
    </div>
  );
}
