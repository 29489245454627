import { Form } from "react-bootstrap";

const Sorting = ({ name, title, value, setSort }) => {
  const options = [
    { label: "Uusin", value: "-createdAt" },
    { label: "Vanhin", value: "createdAt" },
  ];

  return (
    <Form.Group>
      {title && <Form.Label>{title}</Form.Label>}
      <Form.Control
        as="select"
        name={name || "sortBy"}
        onChange={(e) => setSort(e.target.value)}
        value={value}
      >
        {options.map((el, i) => (
          <option value={el.value} key={i}>
            {el.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default Sorting;
