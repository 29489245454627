import {
  SET_SESSION,
  SELECT_TAB,
  CLEAR_TAB,
  SET_SWITCH,
  SET_PAGE_BOTTOM,
  LOCK_PAGE_BOTTOM,
} from "../actions/types";

const initialState = {
  title: null,
  from: null,
  showMenu: false,
  activeTab: 1,
  onlyUserItems: false,
  isBottom: false,
  refresBottom: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SESSION:
      return {
        ...state,
        title: payload.title,
        from: payload.from,
        showMenu: payload.showMenu,
        activeTab: payload.tab,
        refresBottom: payload.refresBottom,
      };

    case SELECT_TAB:
      return {
        ...state,
        activeTab: payload,
      };

    case CLEAR_TAB:
      return {
        ...state,
        activeTab: 1,
      };
    case SET_SWITCH:
      return {
        ...state,
        onlyUserItems: payload,
      };
    case SET_PAGE_BOTTOM:
      return {
        ...state,
        isBottom: payload,
      };
    case LOCK_PAGE_BOTTOM:
      return {
        ...state,
        refresBottom: !payload,
      };

    default:
      return state;
  }
}
