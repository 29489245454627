import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoSizeTextArea from "../../components/AutoSizeTextArea/AutoSizeTextArea";
import CustomSpinner2 from "../../components/CustomSpinner/CustomSpinner2";
import { actionGetMsgBoardsCustomer } from "../../redux/actions/action.messageboard";
import { dateWithDayNameAndTime } from "../../utils/toDates";

import "./MessageBoard.scss";

const MessageBoardsCustomer = () => {
  const dispatch = useDispatch();

  const { messageBoards, loadingBoards } = useSelector(
    (state) => state.messageBoard
  );

  useEffect(() => {
    dispatch(actionGetMsgBoardsCustomer({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const msgCard = (card, i) => (
    <div className="msgCard" key={i}>
      <div className="header">
        <div>
          <small>{dateWithDayNameAndTime(card.createdAt)}</small>
          <small>{dateWithDayNameAndTime(card.updatedat)}</small>
        </div>
        <div>
          <div />
          <p className="p-title">{card.title}</p>
          <div />
        </div>
      </div>

      <div className="content">
        <AutoSizeTextArea
          readOnly={true}
          value={card.description || "-"}
          rows="5"
        />
      </div>

      <div className="footer">
        <div>
          {card.start ? (
            <>
              <p>
                <strong>{dateWithDayNameAndTime(card.start)}</strong>
              </p>
              <p>
                <strong>{dateWithDayNameAndTime(card.stop)}</strong>
              </p>
            </>
          ) : (
            <p>--:--</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="basicPage MessageBoard">
      <h1>Ilmoitustaulu</h1>

      {loadingBoards ? (
        <CustomSpinner2 />
      ) : (
        <div className="items">
          {messageBoards.length ? (
            messageBoards.map((el, i) => msgCard(el, i))
          ) : (
            <p>Ei ilmoituksia</p>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBoardsCustomer;
