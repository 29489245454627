export const NEW_MAIN_FOLDER = "NEW_MAIN_FOLDER";
export const NEW_SUB_FOLDER = "NEW_SUB_FOLDER";
export const GET_FOLDERS = "GET_FOLDERS";
export const SELECT_FOLDER = "SELECT_FOLDER";
export const CLEAR_SELECTED_FOLDER = "CLEAR_SELECTED_FOLDER";
export const UPDATE_MAIN_FOLDER = "UPDATE_MAIN_FOLDER";
export const UPDATE_SUB_FOLDER = "UPDATE_SUB_FOLDER";

export const MOVE_FOLDER_TO_PARENT = "MOVE_FOLDER_TO_PARENT";
export const MOVE_FOLDER_TO_SUB = "MOVE_FOLDER_TO_SUB";
export const SEARCH_FOLDERS = "SEARCH_FOLDERS";
export const DELETE_MAIN_FOLDER = "DELETE_MAIN_FOLDER";
export const DELETE_SUB_FOLDER = "DELETE_SUB_FOLDER";
