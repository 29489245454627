import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import {
  actionClearSelectedReport,
  actionEditReportFiles,
  actionGetCompanyFinalReportById,
  actionGetSelectedSummary,
  actionNewFinalReport,
  actionSelectReport,
  actionUpdateFinalReport,
} from "../../../redux/actions/action.report";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Collapse, Form, Image } from "react-bootstrap";
import { actionGetItemById } from "../../../redux/actions/action.item";
import { actionSetSession } from "../../../redux/actions/action.session";

import Switch from "../../../components/Switch/Switch";
import SelectCosts from "../../../components/Select/Company/SelectCosts";
import SelectPartsV2 from "../../../components/Select/Company/SelectPartsV2";
import { actionAlert } from "../../../redux/actions/action.alert";
import SelectFile from "../../../components/SelectFile/SelectFile";
import { actionSetModals2 } from "../../../redux/actions/action.modal";

import NoResults from "../../../components/NoResults/NoResults";
import { actionDeleteImageCompany } from "../../../redux/actions/action.company";

import FileSaver from "file-saver";
import HideCheckComponent from "../../../components/RestrictComponents/HideCheckComponent";
import { havePermissions } from "../../../utils/havePermissions";
import BasicFileCard from "../../../components/SelectFile/BasicFileCard";

const OneCompanyFinalReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { reportId, itemId } = useParams();
  // HUOM !!!!!

  // jos selectedReport on array se sisältää vain valitut raportit koosteeseen
  // muuten sisältää muokattavan koosteen
  const {
    selectedReport,
    loadingSummary,
    selectedSummary,
    loadingReport,
  } = useSelector((state) => state.report);

  const { selectedItem, loadingItem } = useSelector((state) => state.item);

  const { permissions2 } = useSelector((state) => state.auth.user);

  const [cooldown, setCooldown] = useState(2);

  const [formData, setFormData] = useState({
    title: "",
    workDesc: "",
    parts: [],
    costs: [],
    prices: {
      costs: {},
      partsPrice: 0,
      totalPrice: 0,
    },
    date: null,

    showToCustomer: false,
    showPrices: false,
    discount: 0,
    warranty: 0,
    files: [],
  });

  const [states, setStates] = useState({
    submitting: false,
    disableEdit: reportId === "new" ? false : true,
    showFiles: false,
    selectedFile: {
      name: null,
      uuid: null,
    },
    newLegend: "",
    newFileDesc: "-",
    fileShowToCustomer: true,
    isFileSelected: false,
  });

  const {
    submitting,
    disableEdit,
    showFiles,
    selectedFile,
    newLegend,
    fileShowToCustomer,
    newFileDesc,
  } = states;

  const canWrite = havePermissions(permissions2, "reports", ["READ", "WRITE"]);
  const noPrices = havePermissions(permissions2, "reports", ["noPrices"]);

  useEffect(() => {
    dispatch(actionSetSession("KOOSTE", `/item/${itemId}`, true, 5));

    if (!selectedItem) {
      dispatch(actionGetItemById(itemId));
    }

    // Ollaan tekemässä uutta koostetta
    if (reportId && reportId === "new") {
      if (selectedReport && selectedReport.length > 0) {
        // katsotaan onko valittuja raportteja, joista kooste esitäytetään

        dispatch(actionGetSelectedSummary(selectedReport));
      }
    } else if (!selectedReport && reportId.length === 24) {
      dispatch(actionGetCompanyFinalReportById(reportId));
    } else {
      dispatch(actionAlert("Virheellinen raportin id", "danger"));
      history.replace({
        pathname: `/item/${itemId}`,
        state: { ...location.state, tab: 5 },
      });
    }

    return () => {
      dispatch(actionClearSelectedReport());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedSummary) {
      setFormData((state) => ({
        ...state,
        parts: selectedSummary.parts,
        costs: selectedSummary.costs,
        workDesc: selectedSummary.workDescs,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSummary]);

  useEffect(() => {
    // jos selectedReport on array se sisältää vain valitut raportit koosteeseen
    // muuten sisältää muokattavan koosteen
    if (selectedReport && !Array.isArray(selectedReport)) {
      const {
        title,
        workDesc,
        parts,
        costs,
        prices,
        hours,
        date,
        showPrices,
        showToCustomer,
        files,
      } = selectedReport;

      setFormData((state) => ({
        ...state,
        title: title,
        workDesc: workDesc,
        parts: parts,
        costs: costs,
        prices: prices ? prices : { costs: {} },
        hours: hours,
        date: date,
        showPrices: showPrices,
        showToCustomer: showToCustomer,
        files: files,
      }));
    }

    if (selectedItem && reportId === "new") {
      setFormData((state) => ({
        ...state,
        custWorkDesc: selectedItem.desc || "-",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport, selectedItem]);

  useEffect(() => {
    let timer;

    if (!disableEdit) {
      if (cooldown > 0) {
        timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
      } else {
        clearTimeout(timer);
      }
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cooldown, disableEdit]);

  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Pakollinen tieto"),
      workDesc: Yup.string().required("Pakollinen tieto"),
    }),

    onSubmit: (values) => {
      setStates((state) => ({ ...state, submitting: true }));

      if (reportId === "new") {
        submitNew(values);
      } else {
        submitEdit(values);
      }
    },
  });

  const submitNew = (values) => {
    const { customer } = selectedItem;

    const customer2 = customer
      ? { id: customer.id, name: customer.name }
      : null;

    const content = {
      itemId: itemId,
      customer: customer2,
      ...values,
    };

    dispatch(actionNewFinalReport(content)).then((data) => {
      if (!data) {
        setStates((state) => ({ ...state, submitting: false }));
        dispatch(actionAlert("Epännistui", "danger"));
      } else {
        dispatch(actionAlert("Onnistui", "success"));
        // valitaan tehty raportti, jotta ei jää "loading" tila päälle
        dispatch(actionSelectReport(data));

        history.replace(`/item/${itemId}/company/final/${data._id}`);
        setStates((state) => ({
          ...state,
          disableEdit: true,
          submitting: false,
          showFiles: false,
        }));
      }
    });
  };

  const submitEdit = (values) => {
    const content = {
      reportId: reportId,
      itemId: itemId,
      ...values,
    };
    dispatch(actionUpdateFinalReport(content)).then((data) => {
      if (!data) {
        setStates((state) => ({ ...state, submitting: false }));
      } else {
        dispatch(actionAlert("Onnistui", "success"));
        setStates((state) => ({
          ...state,
          disableEdit: true,
          submitting: false,
        }));
      }
    });
  };

  useEffect(() => {
    if (!noPrices) {
      // hinnat sallittu
      let total = 0;
      if (formik.values.prices) {
        if (formik.values.prices.costs.total) {
          total += parseFloat(formik.values.prices.costs.total);
        }

        if (formik.values.prices.partsPrice) {
          total += parseFloat(formik.values.prices.partsPrice);
        }

        if (formik.values.discount && formik.values.discount > 0) {
          total -= formik.values.discount;
        }
      }
      formik.setFieldValue("prices.totalPrice", total);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.prices.partsPrice,
    formik.values.prices.costs.total,
    formik.values.discount,
  ]);

  const handleSwitch = (e) => {
    formik.setFieldValue(e.target.name, e.target.checked);
  };

  const setCosts = (costs) => {
    formik.setFieldValue("costs", costs);
  };

  const setCostsPrices = (prices) => {
    formik.setFieldValue("prices.costs", prices);
  };

  const setCostsHours = (hours) => {
    formik.setFieldValue("hours", hours);
  };

  const setParts = (parts) => {
    formik.setFieldValue("parts", parts);
  };

  const setPartsPrice = (price) => {
    formik.setFieldValue("prices.partsPrice", price);
  };

  const setFiles = (newFile) => {
    const data = [newFile, ...formik.values.files];
    formik.setFieldValue("files", data);
  };

  const handleShowFiles = () => {
    setStates({ ...states, showFiles: !showFiles });
  };

  const downloadPdf = (url, name) => {
    FileSaver.saveAs(url, name);
  };
  const deleteFile = (name, uuid) => {
    const content = {
      folder: "finalReport",
      id: reportId,
      name: name,
      uuid: uuid,
    };

    dispatch(actionDeleteImageCompany(content)).then((data) => {
      if (data) {
        dispatch(actionAlert("Onnistui", "success"));

        const files = formik.values.files.filter(
          (el) => el.name !== name && el.uuid !== uuid
        );
        formik.setFieldValue("files", files);
      } else {
        dispatch(actionAlert("Epäonnistui", "danger"));
      }
    });
  };

  const toggleFileEdit = (name, uuid, legend, showToCustomer, fileDesc) => {
    if (name && uuid && legend) {
      setStates((state) => ({
        ...state,
        selectedFile: {
          name: name,
          uuid: uuid,
        },
        newLegend: legend,
        newFileDesc: fileDesc || "",

        fileShowToCustomer: showToCustomer,
      }));
    } else {
      setStates((state) => ({
        ...state,
        selectedFile: {
          name: null,
          uuid: null,
        },
        newLegend: "",
      }));
    }
  };

  const handleLegend = (e) => {
    setStates({ ...states, [e.target.name]: e.target.value });
  };

  const submitFileEdit = () => {
    const content = {
      newFile: {
        ...selectedFile,
        legend: newLegend,
        showToCustomer: fileShowToCustomer,
        newFileDesc: newFileDesc,
      },
      reportId: reportId,
      folder: "finalReport",
    };

    dispatch(actionEditReportFiles(content)).then((success) => {
      if (success) {
        dispatch(actionAlert("Onnistui", "success"));

        let tempFiles = formik.values.files;
        const index = tempFiles.findIndex(
          (el) => el.uuid === selectedFile.uuid
        );

        tempFiles[index].legend = newLegend;
        tempFiles[index].showToCustomer = fileShowToCustomer;
        tempFiles[index].fileDesc = newFileDesc;

        formik.setFieldValue("files", tempFiles);

        setStates((state) => ({
          ...state,
          selectedFile: {
            name: null,
            uuid: null,
          },
          newLegend: "",
          fileShowToCustomer: true,
        }));
      } else {
        dispatch(actionAlert("Epäonnistui", "danger"));
      }
    });
  };

  const handleSwitchFile = (e) => {
    setStates({ ...states, [e.target.name]: e.target.checked });
  };

  const handleFormCancel = () => {
    if (reportId === "new") {
      history.replace({
        pathname: `/item/${itemId}`,
        state: { ...location.state, tab: 5 },
      });
    } else {
      formik.resetForm();
      setStates((state) => ({
        ...state,
        disableEdit: !state.disableEdit,
      }));
    }
  };

  const fileDiv = (
    <div className="appImgCollapseDiv">
      {reportId === "new" ? (
        <div className="appInfoText">
          Liitteitä voi lisätä vasta, kun kooste on luotu
        </div>
      ) : (
        <>
          <div>
            <HideCheckComponent required={["READ", "WRITE"]} field="reports">
              <Form.Group>
                <SelectFile
                  folder="finalReport"
                  id={reportId}
                  setFiles={setFiles}
                  supportShowToCustomer={true}
                  supportAudio={true}
                  setIsFileSelected={(value) =>
                    setStates((state) => ({ ...state, isFileSelected: value }))
                  }
                />
              </Form.Group>
            </HideCheckComponent>
          </div>
          {showFiles && (
            <div>
              {formik.values.files && formik.values.files.length > 0 ? (
                <div className="appImagesDiv">
                  {formik.values.files.map((file, i) =>
                    file.format === "webm" ? (
                      <BasicFileCard
                        key={i}
                        file={file}
                        selectedFile={selectedFile}
                        toggleFileEdit={toggleFileEdit}
                        handleSwitchFile={handleSwitchFile}
                        handleLegend={handleLegend}
                        submitFileEdit={submitFileEdit}
                        newLegend={newLegend}
                        newFileDesc={newFileDesc}
                        fileShowToCustomer={fileShowToCustomer}
                        permField="reports"
                        deleteFile={deleteFile}
                        requiredPerms={["READ", "WRITE"]}
                        supportShowToCustomer={true}
                        isReport={true}
                      >
                        <audio
                          controls
                          muted
                          controlsList="nodownload"
                          src={file.url}
                          alt={file.uuid}
                        />
                      </BasicFileCard>
                    ) : file.format === "mp4" || file.format === "mkv" ? (
                      <BasicFileCard
                        key={i}
                        file={file}
                        selectedFile={selectedFile}
                        toggleFileEdit={toggleFileEdit}
                        handleSwitchFile={handleSwitchFile}
                        handleLegend={handleLegend}
                        submitFileEdit={submitFileEdit}
                        newLegend={newLegend}
                        newFileDesc={newFileDesc}
                        fileShowToCustomer={fileShowToCustomer}
                        permField="reports"
                        deleteFile={deleteFile}
                        requiredPerms={["READ", "WRITE"]}
                        supportShowToCustomer={true}
                        isReport={true}
                      >
                        <video
                          controls
                          muted
                          controlsList="nodownload"
                          src={file.url}
                          alt={file.uuid}
                        />

                        {file.format === "mkv" && (
                          <>
                            <Form.Text>
                              .mkv videot voi katsoa ainakin Chrome-selaimella
                            </Form.Text>
                            <Button
                              variant="link"
                              onClick={() => downloadPdf(file.url, file.legend)}
                            >
                              Lataa
                            </Button>
                          </>
                        )}
                      </BasicFileCard>
                    ) : file.format === "pdf" ? (
                      <BasicFileCard
                        key={i}
                        file={file}
                        selectedFile={selectedFile}
                        toggleFileEdit={toggleFileEdit}
                        handleSwitchFile={handleSwitchFile}
                        handleLegend={handleLegend}
                        submitFileEdit={submitFileEdit}
                        newLegend={newLegend}
                        newFileDesc={newFileDesc}
                        fileShowToCustomer={fileShowToCustomer}
                        permField="reports"
                        deleteFile={deleteFile}
                        requiredPerms={["READ", "WRITE"]}
                        supportShowToCustomer={true}
                        isReport={true}
                      >
                        <Button
                          variant="link"
                          onClick={() =>
                            dispatch(
                              actionSetModals2({
                                modalType: 7,
                                data: file.url,
                              })
                            )
                          }
                        >
                          Avaa
                        </Button>

                        <Button
                          variant="link"
                          onClick={() => downloadPdf(file.url, file.legend)}
                        >
                          Lataa
                        </Button>
                      </BasicFileCard>
                    ) : (
                      <BasicFileCard
                        key={i}
                        file={file}
                        selectedFile={selectedFile}
                        toggleFileEdit={toggleFileEdit}
                        handleSwitchFile={handleSwitchFile}
                        handleLegend={handleLegend}
                        submitFileEdit={submitFileEdit}
                        newLegend={newLegend}
                        newFileDesc={newFileDesc}
                        fileShowToCustomer={fileShowToCustomer}
                        permField="reports"
                        deleteFile={deleteFile}
                        requiredPerms={["READ", "WRITE"]}
                        supportShowToCustomer={true}
                        isReport={true}
                      >
                        <Image
                          src={file.url}
                          alt={file.uuid}
                          thumbnail
                          fluid
                          onClick={() =>
                            dispatch(
                              actionSetModals2({
                                modalType: 36,
                                title: "KUVA",
                                data: {
                                  image: file,
                                },
                              })
                            )
                          }
                        />
                      </BasicFileCard>
                    )
                  )}
                </div>
              ) : (
                <NoResults text="Ei tiedostoja" />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );

  if (reportId === "new") {
    if (selectedReport && selectedReport.length > 0 && loadingSummary) {
      return <CustomSpinner color="blue" />;
    }
  } else if (reportId) {
    if (loadingItem || loadingReport) {
      return <CustomSpinner color="blue" />;
    }
  }

  if (reportId !== "new" && !loadingReport && !selectedReport) {
    dispatch(actionAlert("Raporttia ei löydy", "danger"));
    return (
      <Redirect
        to={{
          pathname: `/item/${itemId}`,
          state: { ...location.state, tab: 5 },
        }}
      />
    );
  }

  return (
    <div className="OneCompanyreport">
      <div className="buttons">
        {reportId === "new"
          ? "Uusi kooste"
          : canWrite
          ? "Koosteen muokkaus"
          : "Ei muokkaus oikeutta"}
        <HideCheckComponent required={["READ", "WRITE"]} field="reports">
          <div>
            {submitting ? null : disableEdit ? (
              <Button
                className="primaryBtn"
                onClick={() => {
                  setStates((state) => ({
                    ...state,
                    disableEdit: !state.disableEdit,
                  }));
                  setCooldown(2);
                }}
              >
                Muokkaa
              </Button>
            ) : (
              <>
                <Button className="primaryBtn" onClick={handleFormCancel}>
                  Peruuta
                </Button>{" "}
                <Button
                  className="primaryBtn"
                  onClick={formik.handleSubmit}
                  disabled={cooldown > 0}
                >
                  Tallenna {cooldown > 0 ? `(${cooldown})` : null}
                </Button>
              </>
            )}
          </div>
        </HideCheckComponent>
      </div>

      <div className="titleHeader">
        <div>
          <h1>Kooste</h1>
          <Button variant="link" onClick={handleShowFiles}>
            LIITTEET (BETA)
          </Button>
        </div>

        <div className="switches">
          <Switch
            name="showToCustomer"
            checked={formik.values.showToCustomer}
            handleChange={handleSwitch}
            label="Näytä asiakkaalle"
            disabled={disableEdit}
          />

          {formik.values.showToCustomer ? (
            <Switch
              name="showPrices"
              checked={formik.values.showPrices}
              handleChange={handleSwitch}
              label="Näytä hinnat asiakkaalle"
              disabled={disableEdit}
            />
          ) : null}
        </div>
      </div>

      {showFiles ? fileDiv : null}

      <Form>
        <Form.Group>
          <Form.Label>Otsikko</Form.Label>
          <Form.Control
            name="title"
            onChange={formik.handleChange}
            isInvalid={formik.touched.title && formik.errors.title}
            onBlur={formik.handleBlur}
            value={formik.values.title || ""}
            disabled={disableEdit}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.title}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Kuvaus</Form.Label>
          <Form.Control
            name="workDesc"
            as="textarea"
            rows="5"
            onChange={formik.handleChange}
            isInvalid={formik.touched.workDesc && formik.errors.workDesc}
            onBlur={formik.handleBlur}
            value={formik.values.workDesc || ""}
            disabled={disableEdit}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.workDesc}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Asiakkaan/tehtävän kuvaus</Form.Label>
          <Form.Control
            name="workDesc"
            as="textarea"
            rows="5"
            onChange={formik.handleChange}
            value={(selectedItem && selectedItem.desc) || "-"}
            readOnly
          />
        </Form.Group>

        <div className="space" />

        <SelectCosts
          setCosts={setCosts}
          costs={formik.values.costs || []}
          setCostsPrices={setCostsPrices}
          setCostsHours={setCostsHours}
          disableInputs={disableEdit}
        />

        <br />

        <SelectPartsV2
          parts={formik.values.parts}
          setParts={setParts}
          setPartsPrice={setPartsPrice}
          disableInputs={disableEdit}
        />

        <div className="space" />

        <Form.Group>
          <Form.Label>Takuu (kk)</Form.Label>
          <Form.Control
            name="warranty"
            onChange={formik.handleChange}
            isInvalid={formik.touched.warranty && formik.errors.warranty}
            onBlur={formik.handleBlur}
            value={formik.values.warranty || ""}
            disabled={disableEdit}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.warranty}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Alennus (€)</Form.Label>
          <Form.Control
            name="discount"
            type="number"
            onChange={formik.handleChange}
            isInvalid={formik.touched.discount && formik.errors.discount}
            onBlur={formik.handleBlur}
            value={
              (formik.values.discount && formik.values.discount.toString()) ||
              ""
            }
            disabled={disableEdit}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.discount}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>

      <div className="space" />
      {formik.values.prices && !noPrices && (
        <div className="summary">
          <p className="p-title">YHTEENVETO</p>

          <hr />
          <div className="prices">
            <p className="p-title">HINNAT</p>

            <div className="div1">
              <p>Työ: {formik.values.prices.costs.work}€</p>

              <p>Osat: {formik.values.prices.partsPrice}€</p>
              <strong>Yht. {formik.values.prices.totalPrice}€</strong>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OneCompanyFinalReport;
