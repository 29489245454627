import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionSetSession } from "../../redux/actions/action.session";
import { actionLogin } from "../../redux/actions/action.auth";

import { useFormik } from "formik";
import * as Yup from "yup";

import "./Login.scss";
import { Form, Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router";
import { InputGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconEye, iconEye2 } from "../../components/Icons/Icons";
import { Link } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const hostname = window.location.hostname;
  const eyeIcon = (
    <FontAwesomeIcon icon={iconEye} size="lg" className="eyeIcon" />
  );
  const eyeIcon2 = (
    <FontAwesomeIcon icon={iconEye2} size="lg" className="eyeIcon" />
  );

  const {
    isAuthenticated,
    user: { userGroup, name },
  } = useSelector((state) => state.auth);

  const [active, setActive] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showPw, setShowPw] = useState("password");

  useEffect(() => {
    dispatch(actionSetSession("Kirjaudu", null, true));

    // haetaan tallennettu viimeisin kirjautumis tyyppi ja valitaan oletuksena
    const lastLoginType = localStorage.getItem("lastLoginType");
    setActive(parseInt(lastLoginType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(hostname);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      //   email: Yup.string().email("Ei kelpaa").required("Pakollinen tieto"),
      password: Yup.string().required("Pakollinen tieto"),
    }),
    onSubmit: (values) => {
      setSubmitting(true);
      const { email, password } = values;

      if (active === 1) {
        // COMPANY login
        const cUser = true;
        dispatch(actionLogin({ email, password, cUser })).then((error) => {
          if (error) {
            setSubmitting(false);
          } else {
            localStorage.setItem("lastLoginType", 1);
          }
        });
      } else if (active === 2) {
        // CUSTOMER(company) login
        const cUser = false;
        dispatch(actionLogin({ email, password, cUser })).then((error) => {
          if (error) {
            setSubmitting(false);
          } else {
            localStorage.setItem("lastLoginType", 2);
          }
        });
      }
    },
  });

  const handleActive = (i) => {
    if (i === active) {
      setActive(null);
    } else {
      setActive(i);
    }
  };

  const handleShowPw = () => {
    if (showPw === "password") {
      setShowPw("text");
    } else {
      setShowPw("password");
    }
  };

  // valitaan "kortti" aktiivisen valinnan mukaan
  const companyLogin =
    active === 1 || !active ? (
      <div
        className={
          active === 1
            ? "loginCard-active companyLogin"
            : "loginCard companyLogin"
        }
        onClick={() => handleActive(1)}
      >
        <p className="p-title">YRITYKSET</p>
      </div>
    ) : null;

  const customerLogin =
    active === 2 || !active ? (
      <div
        className={
          active === 2
            ? "loginCard-active customerLogin"
            : "loginCard customerLogin"
        }
        onClick={() => handleActive(2)}
      >
        <p className="p-title">ASIAKASYRITYKSET</p>
      </div>
    ) : null;

  const loginForm = (
    <Form onSubmit={formik.handleSubmit}>
      <div className="loginForm">
        {hostname === "palvelupaallikko.fi" ? (
          <h6>
            HUOM! Palvelupaallikon uusi nimi on Info247! Myös ohjelman sivun
            osoite muuttuu, se on jatkossa "info247.fi". <br />
            Muutos tulee voimaan lähiaikoina, jonka jälkeen{" "}
            <strong>vanha osoite lakkaa toimimasta</strong>
            <br /> Tiedotamme asiasta vielä erikseen tarkemman ajankohdan
          </h6>
        ) : null}
        <div className="buttons">
          <Link to="#" onClick={() => setActive(null)}>
            Takaisin valintaan
          </Link>

          {active === 1 ? (
            <Link to="/company/forgot-pw">Unohtuiko salasana ?</Link>
          ) : active === 2 ? (
            <Link to="/customer/forgotpw">Unohtuiko salasana ?</Link>
          ) : null}
        </div>
        <Form.Group>
          <Form.Label>Sähköposti</Form.Label>
          <Form.Control
            name="email"
            onChange={formik.handleChange}
            isInvalid={formik.touched.email && formik.errors.email}
            onBlur={formik.handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Salasana</Form.Label>

          <InputGroup className="pwGroup">
            <Form.Control
              name="password"
              onChange={formik.handleChange}
              isInvalid={formik.touched.password && formik.errors.password}
              onBlur={formik.handleBlur}
              type={showPw}
              autoComplete="on"
              className="pwControl"
            />
            <InputGroup.Append>
              <Button onClick={handleShowPw} variant="none">
                {showPw === "password" ? eyeIcon : eyeIcon2}
              </Button>
            </InputGroup.Append>
          </InputGroup>

          <Form.Control.Feedback type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
        </Form.Group>

        <br />

        {submitting ? (
          <Spinner animation="grow" />
        ) : (
          <Button type="submit" className="secondaryBtn" block>
            Kirjaudu
          </Button>
        )}
      </div>
    </Form>
  );

  if (isAuthenticated && userGroup > 10) {
    return <Redirect to="/" />;
  }

  if (isAuthenticated && userGroup !== null && userGroup < 11) {
    return <Redirect to="/" />;
  }

  if (isAuthenticated && !userGroup && name) {
    return <Redirect to="/display/home" />;
  }

  return (
    <div className="Login">
      <div className={active ? "container1-active" : "container1"}>
        <div className={active ? "loginCards-active" : "loginCards"}>
          {companyLogin} {customerLogin}
        </div>

        {active ? loginForm : null}
      </div>
    </div>
  );
}
//
