import React from "react";
import "./Switch.scss";

const Switch = ({ checked, name, handleChange, disabled, label }) => {
  return (
    <div className="switchDiv">
      <p>{label}</p>
      <input
        className="react-switch-checkbox"
        id={name}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        name={name}
        disabled={disabled}
      />
      <label style={{ background: checked && '#adc90b'}} className={!disabled ? "react-switch-label" : "react-switch-label-disabled"} htmlFor={name} disabled={disabled}>
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};

export default Switch;
