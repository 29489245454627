import {
  CLEAR_COMPANY,
  COMPANY_CUSTOMERS,
  COMPANY_SELECT_CUSTOMER,
  COMPANY_MAIN_MENU,
  COMPANY_NEW_CUSTOMER,
} from "../actions/types";
import {
  COMPANY_ADD_FILES,
  COMPANY_BASIC_USERLIST,
  COMPANY_DELETE_FILES,
  COMPANY_GET_CUSTOMER_GROUPS,
  COMPANY_UPDATE_CUSTOMER,
  COMPANY_UPDATE_CUSTOMERS,
  COMPANY_UPDATE_CUSTOMER_GROUPS,
  COMPANY_UPLOADING,
  SET_COMPANY_SOCKET_DATA,
} from "../types/company.types";

const initialState = {
  companies: null,
  companyInfo: {},

  companyCustomers: [],
  selectedCustomer: null,
  loadingCustomers: true,
  loadingCustomer: true,

  customerGroups: [],
  loadingCustomerGroups: true,

  basicUserList: [],

  mainMenu: {},
  loadingMenu: true,

  onlineUsers: null,

  uploading: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case COMPANY_CUSTOMERS:
      return {
        ...state,
        companyCustomers: payload,
        loadingCustomers: false,
      };

    case COMPANY_GET_CUSTOMER_GROUPS:
      return {
        ...state,
        customerGroups: payload,
        loadingCustomerGroups: false,
      };

    case COMPANY_SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: payload,
        loadingCustomer: false,
      };
    case COMPANY_NEW_CUSTOMER:
      return {
        ...state,
        companyCustomers: [payload, ...state.companyCustomers],
      };

    case COMPANY_MAIN_MENU:
      return {
        ...state,
        mainMenu: payload,
        loadingMenu: false,
      };

    case COMPANY_BASIC_USERLIST:
      return {
        ...state,
        basicUserList: payload,
      };
    case SET_COMPANY_SOCKET_DATA:
      return {
        ...state,
        onlineUsers: payload.onlineUsers,
      };

    case COMPANY_UPDATE_CUSTOMERS:
      return {
        ...state,
        companyCustomers: state.companyCustomers.map((el) =>
          el._id === payload.customer._id ? { ...el, ...payload.customer } : el
        ),
      };

    case COMPANY_UPDATE_CUSTOMER_GROUPS:
      return {
        ...state,
        customerGroups: state.customerGroups.map((el) =>
          el._id === payload.customer._id ? { ...el, ...payload.customer } : el
        ),
      };

    case COMPANY_UPDATE_CUSTOMER:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          ...payload,
        },
      };

    case COMPANY_UPLOADING:
      return {
        ...state,
        uploading: payload,
      };

    // upload jälkeen lisätään kuva "livenä"
    case COMPANY_ADD_FILES:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          files: [payload, ...state.selectedCustomer.files],
        },
      };
    case COMPANY_DELETE_FILES:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          files: state.selectedCustomer.files.filter(
            (el) => el.name !== payload.name && el.uuid !== payload.uuid
          ),
        },
      };

    case CLEAR_COMPANY:
      return initialState;

    default:
      return state;
  }
}
