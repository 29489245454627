import {
  UPDATE_USER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOAD_USER,
  FINALIZE_USER,
  LOGOUT,
  GET_PUSH_SUB_STATUS,
  DELETE_PUSH_SUB,
  PUSH_SUB_ERROR,
  LOAD_USER_INFO,
  NOT_VALID_VERIFY,
  SET_ONLINE_STATUS,
  LOAD_USER_TO_FINALIZE,
  FINALIZE_ERROR,
  AUTH_ERROR,
  GET_APP_DATA,
  REGISTER_OPEN_FORM,
} from "../actions/types";
import { COMPANY_UPDATE_RECENT_ITEMS } from "../types/company.types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  loadingInfo: true,
  userGroup: null,
  user: {},
  connectionSynced: true,
  registerSuccess: false,
  finalizeSuccess: false,
  loginFailed: false,
  pushSubStatus: false,
  online: undefined,
  userToFinalize: null,
  loadingFinalize: true,
  finalizeFailed: false,
  appData: {},
  openRegistrationForm: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ONLINE_STATUS:
      return {
        ...state,
        online: payload,
      };
    case GET_PUSH_SUB_STATUS:
      return {
        ...state,
        pushSubStatus: payload,
      };

    case DELETE_PUSH_SUB:
      return {
        ...state,
        pushSubStatus: payload,
      };

    case FINALIZE_USER:
      return {
        ...state,
        finalizeSuccess: true,
      };

    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };

    case LOAD_USER:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        userGroup: payload.userGroup,
        user: payload,
      };
    case LOAD_USER_INFO:
      return {
        ...state,
        isAuthenticated: false,
        loadingInfo: false,
        user: payload,
      };
    case NOT_VALID_VERIFY:
      return {
        ...state,
        loadingInfo: false,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        registerSuccess: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        connectionSynced: false,
        loginFailed: false,
      };

    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        userGroup: null,
        user: {},
        connectionSynced: true,
        registerSuccess: false,
        finalizeSuccess: false,
      };

    case LOAD_USER_TO_FINALIZE:
      return {
        ...state,
        isAuthenticated: false,
        loadingFinalize: false,
      };

    case PUSH_SUB_ERROR:
    case FINALIZE_ERROR:
      return {
        ...state,
        finalizeFailed: true,
        loadingFinalize: false,
      };

    case AUTH_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };

    case COMPANY_UPDATE_RECENT_ITEMS:
      return {
        ...state,
        user: {
          ...state.user,
          recentItems: payload,
        },
      };
    case GET_APP_DATA:
      return {
        ...state,
        appData: payload,
      };
    case REGISTER_OPEN_FORM:
      return {
        ...state,
        openRegistrationForm: payload,
      };
    default:
      return state;
  }
}
