import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { actionAlert } from "../../../redux/actions/action.alert";
import { countCosts } from "../../../utils/countReport";
import { havePermissions } from "../../../utils/havePermissions";
import IconButton from "../../IconButton/IconButton";
import { iconPlus } from "../../Icons/Icons";
import HideCheckComponent from "../../RestrictComponents/HideCheckComponent";

import "./Selects.scss";

export default function SelectCosts({
  setCosts,
  costs,
  setCostsPrices,
  setCostsHours,
  disableInputs,
}) {
  const plussIcon = <FontAwesomeIcon icon={iconPlus} color="black" size="lg" />;
  const dispatch = useDispatch();

  const { permissions2 } = useSelector((state) => state.auth.user);

  const noPrices = havePermissions(permissions2, "reports", ["noPrices"]);

  // PRICES
  useEffect(() => {
    let costsCount = null;
    if (costs.length > 0) {
      costsCount = countCosts(costs);
      setCostsPrices(costsCount.prices);
      setCostsHours && setCostsHours(costsCount.hours);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costs]);

  const handleInputChangeCosts = (e, i) => {
    const { name, value } = e.target;
    const list = [...costs];

    if (name === "price" && value < 0) {
      return dispatch(actionAlert("Hinta alle 0", "warning"));
    }

    if (name === "quantity" && value < 0) {
      return dispatch(actionAlert("Määrä alle 0", "warning"));
    }

    if (list[i]["price"] && name === "quantity") {
      // ensin syötetty hinta
      const total = list[i]["price"] * value;
      list[i]["total"] = total;
    } else if (list[i]["quantity"] && name === "price") {
      // ensin annettu määrä
      const total = list[i]["quantity"] * value;
      list[i]["total"] = total;
    }

    list[i][name] = value;

    setCosts(list);
  };

  const addCostRow = () => {
    const cost = { type: "work", title: "", quantity: "", price: "", total: 0 };
    const list = [...costs, cost];
    setCosts(list);
  };

  // poista varaosa rivi
  const removeCostsRow = (i) => {
    //  const index = formData.parts.length - 1
    const list = [...costs];
    list.splice(i, 1);
    setCosts(list);
  };

  return (
    <div className="Select">
      <div className="selectTitle">
        <h3>Lisätyt kulut</h3>
        <div className="divider" />

        <HideCheckComponent required={["READ", "WRITE"]} field="reports">
          {!disableInputs && (
            <IconButton
              type="add"
              onClick={addCostRow}
              icon={plussIcon}
              text={null}
              disabled={disableInputs}
            />
          )}
        </HideCheckComponent>
      </div>

      {costs.length > 0 ? (
        <Table striped responsive>
          <thead>
            <tr>
              <th className="th1">Tyyppi</th>
              <th className="th1">Nimi</th>
              <th className="th2">Määrä</th>
              {!noPrices && <th className="th2">Hinta</th>}
              {!noPrices && <th className="th2">Yht.</th>}
              <th className="th2">Poista</th>
            </tr>
          </thead>

          <tbody>
            {costs.map((cost, i) => (
              <tr key={i}>
                <td>
                  <Form.Control
                    name="type"
                    value={cost.type || ""}
                    as="select"
                    onChange={(e) => handleInputChangeCosts(e, i)}
                    disabled={disableInputs}
                  >
                    <option value="work">Työtunnit</option>
                    <option value="benefits">Päivärahat</option>
                    <option value="travel">Matkakulut</option>
                    <option value="distance">Km-kulut</option>
                    {/*<option value="accommodation">Majoitus</option>*/}
                    <option value="other">Muu</option>
                  </Form.Control>
                </td>
                <td>
                  <Form.Control
                    name="title"
                    value={cost.title || ""}
                    onChange={(e) => handleInputChangeCosts(e, i)}
                    disabled={disableInputs}
                  />
                </td>

                <td>
                  <Form.Control
                    name="quantity"
                    value={cost.quantity || ""}
                    onChange={(e) => handleInputChangeCosts(e, i)}
                    type="number"
                    disabled={disableInputs}
                  />
                </td>
                {!noPrices && (
                  <td>
                    <Form.Control
                      name="price"
                      value={cost.price || ""}
                      onChange={(e) => handleInputChangeCosts(e, i)}
                      type="number"
                      disabled={disableInputs}
                    />
                  </td>
                )}
                {!noPrices && (
                  <td>
                    <Form.Control
                      name="total"
                      value={
                        cost.total
                          ? cost.total
                          : cost.quantity * cost.price || ""
                      }
                      type="number"
                      readOnly
                    />
                  </td>
                )}

                <td>
                  <Button
                    variant="link"
                    onClick={() => removeCostsRow(i)}
                    disabled={disableInputs}
                  >
                    Poista
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>EI kuluja lisätty</p>
      )}
    </div>
  );
}
