import {
  SET_SESSION,
  SELECT_TAB,
  CLEAR_TAB,
  SET_SWITCH,
  SET_PAGE_BOTTOM,
  LOCK_PAGE_BOTTOM,
} from "./types";

// Aseta sovelluksessa käynnissä olevan sivun tiedot sovelluksen muistiin ja yläpalkkiin
export const actionSetSession = (title, from, showMenu, tab, refresBottom) => (dispatch) => {
  dispatch({
    type: SET_SESSION,
    payload: { title, from, showMenu, tab, refresBottom},
  });
};

export const actionSelectTab = (index) => (dispatch) => {
  dispatch({
    type: SELECT_TAB,
    payload: index,
  });
};

export const actionClearTab = () => (dispatch) => {
  dispatch({
    type: CLEAR_TAB,
  });
};

export const actionSetSwitch = (status) => (dispatch) => {
  dispatch({
    type: SET_SWITCH,
    payload: status,
  });
};

export const actionSetBottom = (isBottom) => (dispatch) => {
  dispatch({
    type: SET_PAGE_BOTTOM,
    payload: isBottom,
  });
};

export const actionLockBottom = (locked) => (dispatch) => {
  dispatch({
    type: LOCK_PAGE_BOTTOM,
    payload: locked,
  });
};
