// React & Routing
import React from "react";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionRemoveAlert } from "../../redux/actions/action.alert";

// Bootstrap
import Alert from "react-bootstrap/Alert";

// Styling
import "./Alert.scss";

const AlertComponent = ({ alerts, actionRemoveAlert }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map((alert) => (
    <Alert
      key={alert.id}
      className="appAlert animated fadeInUp"
      variant={`${alert.alertType}`}
      onClose={() => actionRemoveAlert(alert.id)}
      dismissible
    >
      <p>{alert.msg}</p>
    </Alert>
  ));

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

Alert.propTypes = {
  alerts: PropTypes.array,
};

export default connect(mapStateToProps, { actionRemoveAlert })(AlertComponent);
