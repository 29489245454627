import {
  ANSWER_QUESTION,
  ASK_ANSWER,
  ASK_EMPTY_ANSWERS,
  ASK_INITIAL,
  SET_ASK,
} from "../types/ask.types";

const initialState = {
  questions: [],
  askAnswers: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ASK:
      return {
        ...state,
        questions: [payload, ...state.questions],
      };

    case ASK_ANSWER:
      return {
        ...state,
        questions: state.questions.filter(
          (el) => el.id !== payload.question.id
        ),
        askAnswers: [
          {
            id: payload.id,
            answer: payload.answer,
            question: payload.question,
          },
          ...state.askAnswers,
        ],
      };
    case ANSWER_QUESTION:
      return {
        ...state,
        questions: state.questions.filter((el) => el.id !== payload.id),
      };

    case ASK_EMPTY_ANSWERS:
      return {
        ...state,
        askAnswers: [],
      };
    case ASK_INITIAL:
      return initialState;

    default:
      return state;
  }
}
