import { dateWithDayNameAndTime } from "../../../utils/toDates";

const LinkUserMsgCard = ({ data, children, onSelect }) => {
  const msgStyle = data.fromMe ? "myMsg" : "otherMsg";

  return (
    <div className={`LinkUserMsgCard boxShadow ${msgStyle}`}>
      <div className="header">
        <p>{dateWithDayNameAndTime(data.createdAt)}</p>
        <p className="p-author">{data.author?.name || data.author?.email}</p>
      </div>

      <div className="content">
        <p className="p-desc">{data.message}</p>
      </div>

      <div className="footer">{children}</div>
    </div>
  );
};

export default LinkUserMsgCard;
