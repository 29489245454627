import store from "./../../store";
import { v4 as uuidv4 } from "uuid";
import { SET_TOAST, REMOVE_TOAST, CLEAR_TOASTS, UPDATE_TOAST } from "./types";

// Toast

export const actionToast = ({
  title,
  text,
  event,
  link,
  tab,
  data,
  author,
  logo,
}) => (dispatch) => {
  const id = uuidv4();
  const timeNow = new Date().toLocaleTimeString();

  const state = store.getState();
  const toasts = state.toast;

  const duplicate = toasts.find(
    (el) => el.timeNow === timeNow || el.title === title
  );

  if (!duplicate) {
    dispatch({
      type: SET_TOAST,
      payload: {
        title,
        text,
        timeNow,
        id,
        event,
        link,
        tab,
        pcs: 1,
        data,
        author,
        logo,
      },
    });
  } else {
    dispatch({
      type: UPDATE_TOAST,
      payload: { timeNow, id: duplicate.id, pcs: duplicate.pcs + 1, author },
    });
  }
};

// Poista yksi toast ID:n perusteella
export const actionRemoveToast = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_TOAST,
    payload: id,
  });
};

// Tyhjennä toast muisti
export const actionClearToasts = () => (dispatch) => {
  dispatch({
    type: CLEAR_TOASTS,
  });
};
