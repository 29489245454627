import {
  ADD_DB_ITEMS,
  DISPLAYS_ONLINE,
  GET_DB_HOME,
  GET_DISPLAYS,
  UPDATE_DB_ITEMS,
  UPDATE_DISPLAY,
  UPDATE_SELECTED_DISPLAY,
  REMOVE_DB_ITEM,
  NEW_DISPLAY,
  DELETE_DISPLAY,
  SORT_DB_ITEMS,
} from "../types/display.types";

const initialState = {
  displays: [],
  loadingDisplays: true,

  items: [],
  display: null,
  onlineDisplays: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DISPLAYS:
      return {
        ...state,
        displays: payload,
        loadingDisplays: false,
      };
    case GET_DB_HOME:
      return {
        ...state,
        items: payload.items,
        display: payload.display,
      };
    case UPDATE_DB_ITEMS:
      return {
        ...state,
        items: state.items.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };
    case ADD_DB_ITEMS:
      return {
        ...state,
        items: [payload, ...state.items],
      };
    case REMOVE_DB_ITEM:
      return {
        ...state,
        items: state.items.filter((el) => el._id !== payload._id),
      };

    case DISPLAYS_ONLINE:
      return {
        ...state,
        onlineDisplays: payload,
      };
    case SORT_DB_ITEMS:
      return {
        ...state,
        items: state.items.sort((a, b) => a.status < b.status),
      };
    case UPDATE_DISPLAY:
      return {
        ...state,
        displays: state.displays.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };
    case UPDATE_SELECTED_DISPLAY:
      return {
        ...state,

        display: { ...state.display, ...payload },
      };

    case NEW_DISPLAY:
      return {
        ...state,
        displays: [payload, ...state.displays],
      };

    case DELETE_DISPLAY:
      return {
        ...state,
        displays: state.displays.filter((el) => el._id !== payload._id),
      };

    default:
      return state;
  }
}
