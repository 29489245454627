import axios from "axios";

import proxy from "../../utils/proxy";
import {
  FINALREPORT_NEW,
  FINALREPORT_UPDATE,
  REPORTS_GET_COMPANY,
  REPORTS_GET_CUSTOMER,
  REPORT_CLEAR_STATES,
  REPORT_DELETE,
  REPORT_NEW,
  REPORT_INITIAL,
  REPORT_SELECT,
  REPORT_SELECTED_SUMMARY,
  REPORT_UPDATE,
  SEARCH_REPORTS,
  CLEAR_SELECTED_REPORT,
  DELETE_FINAL,
} from "../types/report.types";

import { actionAlert } from "./action.alert";

import { actionSocketBasic2 } from "./action.socket";

import handleErrors from "./handleErrors";

export const actionGetReports = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/report/get-reports/${id}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: REPORTS_GET_COMPANY,
        payload: {
          reports: res.data.reports,
          finalReports: res.data.finalReports,
        },
      });

      return false;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return true;
  }
};

// Tee uusi raportti
export const actionNewReport = ({
  itemId,
  status,
  custWorkDesc,
  workDesc,
  title,
  costs,
  parts,
  prices,
  workDayId,
  workDuration,
  date,
  hours,
  showOnCalendar,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    status,
    custWorkDesc,
    workDesc,
    title,
    costs,
    parts,
    prices,
    workDayId,
    workDuration,
    date,
    hours,
    showOnCalendar,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/report/new-report`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: REPORT_NEW,
        payload: res.data,
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

// Tee uusi raportti
export const actionUpdateReport = ({
  reportId,
  itemId,
  status,
  workDesc,
  title,
  costs,
  parts,
  prices,
  workDuration,
  date,
  hours,
  showOnCalendar,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    reportId,
    itemId,
    status,
    workDesc,
    title,
    costs,
    parts,
    prices,
    workDuration,
    date,
    hours,
    showOnCalendar,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/report/update-report`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: REPORT_UPDATE,
        payload: res.data,
      });

      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionGetSelectedSummary = (ids) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ ids });

  try {
    const res = await axios.post(
      `${proxy}/api/company/report/get-summary`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: REPORT_SELECTED_SUMMARY,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionNewFinalReport = ({
  itemId,
  custWorkDesc,
  workDesc,
  totalPrice,
  customer,
  parts,
  costs,
  discount,
  warranty,
  title,
  showToCustomer,
  prices,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    custWorkDesc,
    workDesc,
    totalPrice,
    parts,
    costs,
    discount,
    warranty,
    title,
    customer,
    showToCustomer,
    prices,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/report/new-final-report`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: FINALREPORT_NEW,
        payload: res.data.data,
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return res.data.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionUpdateFinalReport = ({
  reportId,
  itemId,
  workDesc,
  parts,
  costs,
  discount,
  warranty,
  title,
  showToCustomer,
  prices,
  showPrices,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    reportId,
    itemId,
    workDesc,
    parts,
    costs,
    discount,
    warranty,
    title,
    showToCustomer,
    prices,
    showPrices,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/report/update-final-report`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: FINALREPORT_UPDATE,
        payload: res.data.data,
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Hae tilauksia otsikolla
export const actionSearchReports = ({ title }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/report/search-reports/${title}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: SEARCH_REPORTS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err.message);
  }
};

export const actionClearReportStates = () => (dispatch) => {
  dispatch({
    type: REPORT_CLEAR_STATES,
  });
};

export const actionDeleteSelected = ({ ids, itemId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/report/delete-reports`,
      { data: { ids, itemId } },
      config
    );

    if (res.status === 200) {
      dispatch({
        type: REPORT_DELETE,
        payload: res.data,
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionReportsInitialStates = () => (dispatch) => {
  dispatch({
    type: REPORT_INITIAL,
  });
};

export const actionSelectReport = (report) => (dispatch) => {
  dispatch({
    type: REPORT_SELECT,
    payload: report,
  });
};

export const actionClearSelectedReport = (report) => (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_REPORT,
  });
};

export const actionGetCustomerFinalReportById = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/customer/report/get-final-report/${id}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: REPORT_SELECT,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionGetCompanyFinalReportById = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/report/get-final-report/${id}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: REPORT_SELECT,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionGetCompanyReportById = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/report/get-report/${id}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: REPORT_SELECT,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionGetCustomerReports = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.get(
    `${proxy}/api/customer/report/get-reports/${id}`,
    config
  );

  dispatch({
    type: REPORTS_GET_CUSTOMER,
    payload: res.data,
  });
};

export const actionGetLatestReports = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/report/get-latest-reports`,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return true;
  }
};

export const actionEditReportFiles = ({ newFile, reportId, folder }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    newFile,
    reportId,
    folder,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/report/edit-files`,
      body,
      config
    );

    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionDeleteFinalReport = ({ finalId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/report/delete-final/${finalId}`,
      config
    );

    if (res.status === 200) {
      dispatch(actionAlert("Onnistui", "success"));
      dispatch({
        type: DELETE_FINAL,
        payload: res.data,
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};
