import { Badge } from "react-bootstrap";
import { transferItemStatus } from "../../../utils/item/itemHelpers";
import { dateWithDayNameAndTime } from "../../../utils/toDates";

const LinkUserBasicCard = ({ data, children, onSelect }) => {
  return (
    <div
      className="LinkUserBasicCard boxShadow"
      onClick={onSelect ? () => onSelect(data) : undefined}
    >
      <div className="header">
        <h4>{data.title}</h4>
      </div>

      <div className="alerts">
        {data.alerts?.map((el, i) => (
          <Badge variant={el.variant} pill key={i}>
            {el.title}
          </Badge>
        ))}
      </div>

      <div className="content">
        {data.date ? <p>AIKA: {dateWithDayNameAndTime(data.date)}</p> : null}
        {data.deadline ? (
          <p>Deadline: {dateWithDayNameAndTime(data.deadline)}</p>
        ) : null}

        <br />

        {data.status ? <p>TILA: {transferItemStatus(data.status)}</p> : null}

        {data.desc ? (
          <div className="textBox">
            <p className="p-sub">KUVAUS</p>
            <p className="p-desc">{data.desc}</p>
          </div>
        ) : null}

        {data.whatDone ? (
          <div className="textBox">
            <p className="p-sub">Asiakkaan toimenpiteet ja huomiot</p>
            <p className="p-desc">{data.whatDone}</p>
          </div>
        ) : null}
        {data.companyAnswers?.whatDone ? (
          <div className="answerBox">
            <div className="textBox answerBox">
              <p className="p-sub">Yrityksen vastaus</p>
              <p className="p-desc">{data.companyAnswers.whatDone}</p>
            </div>
          </div>
        ) : null}

        {data.notice ? (
          <div className="textBox">
            <p className="p-sub">Yrityksen toimenpiteet ja huomiot</p>
            <p className="p-desc">{data.notice}</p>
          </div>
        ) : null}
      </div>

      <div className="footer">{children}</div>
    </div>
  );
};

export default LinkUserBasicCard;
