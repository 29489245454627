import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

import "./AutoSizeTextArea.scss";

const defaultStyle = {
  display: "block",
  overflow: "auto",
  resize: "none",
  width: "100%",
};

export default function AutoSizeTextArea({
  style = defaultStyle,

  value,
  readOnly,
  label,
  formikError,
  name,
  onChange,
  isInvalid,
  onBlur,
  disabled,
  helpText,
  rows,
  placeholder,
}) {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (!rows) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Form.Group className="AutoSize">
      {label && <Form.Label>{label}</Form.Label>}
      {helpText && <Form.Text>{helpText}</Form.Text>}
      <Form.Control
        as="textarea"
        value={value || ""}
        readOnly={readOnly}
        ref={textareaRef}
        style={style}
        className="customText"
        name={name}
        onChange={onChange}
        isInvalid={isInvalid}
        onBlur={onBlur}
        disabled={disabled}
        rows={rows}
        placeholder={placeholder || `Teksti... \n\n`}
      />

      <Form.Control.Feedback type="invalid">
        {formikError || ""}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
