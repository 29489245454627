import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  actionGetMyCalendar,
  actionSetLoadingAndEvents,
} from "../../redux/actions/action.calendar";
import {
  addToDate,
  dateWithDayNameAndTime,
  formatDate,
  momentEndOf,
  momentStartOf,
  reduceToDate,
} from "../../utils/toDates";
import { Overlay, Popover } from "react-bootstrap";
import { useRef } from "react";
import { Link } from "react-router-dom";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

const localizer = momentLocalizer(moment);

const Calendar3 = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { allEvents, loading } = useSelector((state) => state.calendar);

  const [states, setStates] = useState({
    events: [],
    loadedMonths: [],
    target: null,
    showPop: false,
    data: null,
  });
  const { events, loadedMonths, target, showPop, data } = states;

  const loadEvents = (date) => {
    let first = momentStartOf("month", date);
    let end = momentEndOf("month", date);

    first = reduceToDate(first, "days", 7);
    end = addToDate(end, "days", 7);

    const loadedMonth = formatDate(date, "MMMM");

    const filter = { first, end };
    dispatch(actionGetMyCalendar({ filter }));
    setStates((state) => ({
      ...state,
      loadedMonths: [...state.loadedMonths, loadedMonth],
    }));
  };
  useEffect(() => {
    const today = new Date();
    loadEvents(today);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allEvents.length) {
      // poistetaan mahdolliset dublicated
      let tempEvents = Object.values(
        allEvents.reduce((a, c) => {
          a[c._id] = c;
          return a;
        }, {})
      );

      tempEvents = tempEvents.map((el) => {
        return {
          ...el,
          start: new Date(el.start),
          end: new Date(el.end),
        };
      });

      setStates((state) => ({ ...state, events: tempEvents }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents]);

  const onNavigate = (date, view) => {
    if (view === "month") {
      const month = formatDate(date, "MMMM");

      if (!loadedMonths.includes(month)) {
        dispatch(actionSetLoadingAndEvents(allEvents, true));
        loadEvents(date);
      }
    } else if (view === "day") {
      const month = formatDate(date, "MMMM");

      if (!loadedMonths.includes(month)) {
        dispatch(actionSetLoadingAndEvents(allEvents, true));

        loadEvents(date);
      }
    } else if (view === "week") {
      let firstWeek = momentStartOf("week", date);
      let endWeek = momentEndOf("week", date);

      const month1 = formatDate(firstWeek, "MMMM");
      const month2 = formatDate(endWeek, "MMMM");

      if (!loadedMonths.includes(month1)) {
        dispatch(actionSetLoadingAndEvents(allEvents, true));

        loadEvents(firstWeek);
      } else if (!loadedMonths.includes(month2)) {
        dispatch(actionSetLoadingAndEvents(allEvents, true));

        loadEvents(endWeek);
      }
    }
  };

  const eventStyle = (event) => {
    if (event.type === "item") {
      return {
        className: "itemEvent",
      };
    } else if (event.type === "report") {
      return {
        className: "reportEvent",
      };
    }
  };

  const openPop = (data, event) => {
    setStates({
      ...states,
      showPop: !showPop,
      target: event.target,
      data: data,
    });
  };

  const pop = (
    <Overlay
      show={showPop}
      target={target}
      placement="top"
      container={ref.current}
      rootClose
      onHide={() =>
        setStates((state) => ({
          ...state,
          target: null,
          showPop: false,
          info: null,
        }))
      }
    >
      <Popover className="PopoverEvent smallShadow">
        <Popover.Content>
          <p className="p-date">{`${dateWithDayNameAndTime(
            data?.start
          )} - ${dateWithDayNameAndTime(data?.end)}`}</p>
          <p className="p-duration">Kesto: {data?.duration}</p>
          <p className="pop-title">{data?.title}</p>

          <p className="p-desc ellipsis">{data?.desc}</p>

          {data && (
            <>
              <hr />
              <div className="links">
                {data?.type === "item" ? (
                  <Link to={`/item/${data._id}`}>Tehtävään</Link>
                ) : null}
              </div>
            </>
          )}
        </Popover.Content>
      </Popover>
    </Overlay>
  );

  return (
    <div className="Calendar3">
      <div className="labels">
        <div className="item">
          <p>Tehtävät</p>
          <div className="itemEvent circle" />
        </div>

        <div className="item">
          <p>Raportit</p>
          <div className="reportEvent circle" />
        </div>
      </div>
      {loading && (
        <div className="loadingDiv">
          <CustomSpinner color="blue" />
        </div>
      )}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        messages={{
          month: "Kuukausi",
          day: "Päivä",
          today: "Tänään",
          week: "Viikko",
          previous: "Edellinen",
          next: "Seuraava",
          allDay: "Koko päivä",
        }}
        views={["month", "week", "day", "agenda"]}
        onNavigate={onNavigate}
        eventPropGetter={eventStyle}
        onSelectEvent={(data, event) => openPop(data, event)}
      />
      {pop}
    </div>
  );
};
export default Calendar3;
