import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinkUserBasicCard from "../../../components/LinkUser/Cards/LinkUserBasicCard";
import {
  actionGetTokenItemImages,
  actionGetTokenItems,
} from "../../../redux/actions/action.links";
import useQueryParam from "../../../utils/useQueryParam";

import CustomSpinner2 from "../../../components/CustomSpinner/CustomSpinner2";

import "../LinkUser.scss";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import FilesList from "../../../components/Files/FilesList";

const LinkUserItems = () => {
  const [token] = useQueryParam("token");
  const dispatch = useDispatch();

  const { tokenItems, loadingItems, files } = useSelector(
    (state) => state.linkUser
  );

  useEffect(() => {
    dispatch(actionGetTokenItems({ token: token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImages = (id, files) => {
    dispatch(
      actionGetTokenItemImages({ itemId: id, files: files, token: token })
    );
  };
  return (
    <div className="basicPage">
      <h1>TEHTÄVÄT</h1>

      {loadingItems ? <CustomSpinner2 /> : null}

      <div className="mappedItems">
        {tokenItems?.map((el, i) => (
          <LinkUserBasicCard data={el} key={i}>
            <div>
              <div className="buttons">
                {el.hasChat ? (
                  <Link
                    to={`/link-user/chats/${el._id}?token=${token}`}
                    className="outLine-blue"
                  >
                    Chat
                  </Link>
                ) : null}
                {el.images ? (
                  <Button
                    variant="link"
                    onClick={() => getImages(el._id, el.images)}
                    className="outLine-blue"
                  >
                    Tiedostot ({el.images ? el.images.length : 0})
                  </Button>
                ) : null}
              </div>

              <br />

              {files && files[el._id] ? (
                <FilesList
                  files={files[el._id]}
                  itemId={el._id}
                  supportDelete={false}
                />
              ) : null}
            </div>
          </LinkUserBasicCard>
        ))}
      </div>
    </div>
  );
};

export default LinkUserItems;
