import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Badge, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../components/IconButton/IconButton";
import { iconFail, iconPlus, iconSuccess } from "../../components/Icons/Icons";
import {
  actionForseReloadDb,
  actionGetCompanyDisplays,
} from "../../redux/actions/action.display";
import { actionSetModals2 } from "../../redux/actions/action.modal";
import { actionDeleteDisplay } from "../../redux/actions/action.display";
import { dateWithDayNameAndTime } from "../../utils/toDates";

import { Link } from "react-router-dom";

import "./Display.scss";
import ConfirmPopOver from "../../components/Popovers/ConfirmPopOver";
import { actionSetSession } from "../../redux/actions/action.session";
const Displays = () => {
  const dispatch = useDispatch();
  const { displays, onlineDisplays } = useSelector((state) => state.display);

  const addIcon = (
    <FontAwesomeIcon icon={iconPlus} className="addIcon" size="lg" />
  );

  useEffect(() => {
    dispatch(actionSetSession("", "/", true));

    dispatch(actionGetCompanyDisplays());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newDisplay = () => {
    dispatch(
      actionSetModals2({
        modalType: 400,
        data: {
          isNew: true,
        },
        title: "Uusi näyttö",
      })
    );
  };

  const setAlerts = (alerts, dbId) => {
    dispatch(
      actionSetModals2({
        modalType: 402,
        data: { list: alerts, dbId: dbId },
        title: "Huomiot",
      })
    );
  };

  const deleteDb = (id) => {
    dispatch(actionDeleteDisplay(id));
  };

  const updateDisplay = (display) => {
    dispatch(
      actionSetModals2({
        modalType: 400,
        data: {
          isNew: false,
          ...display,
        },
        title: "Muokkaa näyttöä",
      })
    );
  };

  const forceReload = (id) => {
    dispatch(actionForseReloadDb(id));
  };

  const dbCard = (db, i) => (
    <div className="dbCard" key={i} onClick={() => updateDisplay(db)}>
      <div className="header">
        <div className="div1">
          <ConfirmPopOver
            title="Vahvista poisto"
            text="Näyttö poistetaan"
            btnText="Poista"
            action={() => deleteDb(db._id)}
          />
          <div>
            <p>Viimeksi päivittynyt: </p>
            <p>{dateWithDayNameAndTime(db.lastReceived)}</p>
          </div>
        </div>
        <div className="nameDiv">
          <p className="p-name">{`${db.name}`}</p>
          <small>{`id: ${db._id}`}</small>
        </div>
      </div>

      <p className="p-desc">{db.desc}</p>

      <p>{`${db.userName} : ${db.pw}`}</p>

      <hr />

      {onlineDisplays[db._id] ? (
        <p>
          Online: <FontAwesomeIcon icon={iconSuccess} className="iconSuccess" />{" "}
          ({onlineDisplays[db._id].length})
        </p>
      ) : (
        <p>
          Online: <FontAwesomeIcon icon={iconFail} className="iconFail" />
        </p>
      )}

      <Button
        variant="link"
        onClick={(e) => {
          e.stopPropagation();
          setAlerts(db.alerts, db._id);
        }}
      >
        Huomiot
      </Button>

      <Button
        variant="link"
        onClick={(e) => {
          e.stopPropagation();
        }}
        as={Link}
        to={`/display/home/${db._id}`}
      >
        Esikatselu
      </Button>

      <Button
        variant="link"
        onClick={(e) => {
          e.stopPropagation();
        }}
        as={Link}
        to={`/message-boards`}
      >
        Siirry ilmoitustaululle
      </Button>

      <Button
        variant="link"
        onClick={(e) => {
          e.stopPropagation();
          forceReload(db._id);
        }}
      >
        Pakota lataus
      </Button>

      <div>
        {db.alerts?.length &&
          db.alerts.map((el, i) => (
            <Badge pill variant={el.variant} key={i}>
              {el.title}
            </Badge>
          ))}
      </div>
    </div>
  );

  return (
    <div className="basicPage Displays">
      <div>
        <h1>NÄYTÖT</h1>
        <IconButton
          icon={addIcon}
          text={null}
          onClick={newDisplay}
          type="add"
        />
      </div>

      <hr />

      {displays.map((el, i) => dbCard(el, i))}
    </div>
  );
};

export default Displays;
