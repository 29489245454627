import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { actionSetSession } from "../../redux/actions/action.session";
import "./ConsumerMenu.scss";
//zIE9aBSQ
export default function ConsumerMenu() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionSetSession("VALIKKO", null, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="ConsumerMenu boxShadow">
      <h2>VALIKKO</h2>

      <Link to="/consumer/items">Tehtävät</Link>
    </div>
  );
}
