import { combineReducers } from "redux";
import { localizeReducer } from "react-localize-redux";
import admin from "./reducer.admin";
import alert from "./reducer.alert";
import auth from "./reducer.auth";
import company from "./reducer.company";
import item from "./reducer.item";
import session from "./reducer.session";
import toast from "./reducer.toast";
import report from "./reducer.report";
import errors from "./reducer.validator";
import adminCustomer from "./reducer.customer.admin";
import customer from "./reduser.customer";
import feedback from "./reducer.feedback";
import modals2 from "./reducer.modals2";
import messageBoard from "./reducer.messageboard";
import axiosErrors from "./reducer.errors";
import companyData from "./reducer.companyData";
import calendar from "./reducer.calendar";
import chatRoom from "./reducer.chatRoom";
import contacts from "./reducer.contatcs";

import consumer from "./reducer.consumer";
import socket from "./reducer.socket";
import folder from "./reducer.folder";
import display from "./reducer.display";
import linkUser from "./reducer.links";
import ask from "./reducer.ask";
import common from "./reducer.commonData";
import notification from "./reducer.notification";

// Yhdistetään kaikki reducerit rootReduceriin (index)
export default combineReducers({
  localize: localizeReducer,
  auth,
  admin,
  adminCustomer,
  customer,
  company,
  item,
  alert,
  session,
  toast,
  report,
  errors,
  feedback,
  modals2,
  messageBoard,
  axiosErrors,
  companyData,
  calendar,
  chatRoom,
  contacts,
  consumer,
  socket,
  folder,
  display,
  linkUser,
  ask,
  common,
  notification,
});
