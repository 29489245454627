import {
  GET_MSG_BOARDS,
  INITIAL_MSG_BOARD_STATES,
  DELETE_BOARD_ITEM,
  NEW_BOARD_ITEM,
  SET_SELECTED_BOARD,
} from "../types/messageBoard.types";

const initialState = {
  messageBoards: [],
  loadingBoards: true,

  selectedBoard: null,
  loadingSelected: true,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MSG_BOARDS:
      return {
        ...state,
        loadingBoards: false,
        messageBoards: payload,
      };
    case SET_SELECTED_BOARD:
      return {
        ...state,
        selectedBoard: payload,
        loadingSelected: false,

        messageBoards: state.messageBoards.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };
    case NEW_BOARD_ITEM:
      return {
        ...state,
        loadingSelected: false,
        selectedBoard: payload,
      };
    case DELETE_BOARD_ITEM:
      return {
        ...state,
        messageBoards: state.messageBoards.filter(
          (el) => el._id !== payload._id
        ),
      };
    case INITIAL_MSG_BOARD_STATES:
      return initialState;

    default:
      return state;
  }
}
