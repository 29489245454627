import React, { useEffect, useState } from "react";

import "./IconButton.scss";

const IconButton = ({ text, icon, type, onClick, disabled, size }) => {
  const [color, setColor] = useState({
    back: "#1e3138",
    font: "white",
  });

  useEffect(() => {
    if (type) {
      switch (type) {
        case "add":
          return setColor({ ...color, back: "#adc90b" });
        case "delete":
          return setColor({ ...color, back: "#ef9a9a" });
        case "undo":
          return setColor({ ...color, back: "#eceff1" });
        case "edit":
          return setColor({
            back: "#eceff1",
            font: "black",
          });
        case "default":
          return setColor({
            back: "#eceff1",
          });

        default:
          return setColor("#9fa5a5");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const styles = { backgroundColor: color.back, color: color.font };
  const styles2 = { color: color.font };

  return (
    <div
      className={size === "sm" ? "iconButtonSm" : "iconButton"}
      style={styles}
      onClick={disabled ? null : onClick}
    >
      {icon}
      {text && <p style={styles2}>{text}</p>}
    </div>
  );
};

export default IconButton;
