export const transferItemStatus = (status) => {
  const status2 = status ? status.toString() : "";
  const statusText =
    status2 === "0"
      ? "Vapaana kaikille"
      : status2 === "1"
      ? "Ei aloitettu"
      : status2 === "2"
      ? "Työnalla"
      : status2 === "3"
      ? "Odottaa"
      : status2 === "4"
      ? "Valmis"
      : status2 === "9"
      ? "Arkistossa"
      : "Ei määritetty";

  return statusText;
};
