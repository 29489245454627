import {
  CUSTOMER_ADMIN_ACCOUNT_EDIT,
  CUSTOMER_ADMIN_LOAD_USERS,
} from "../actions/types";

const initialState = {
  customerUsers: [],
  loadingUsers: true,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CUSTOMER_ADMIN_LOAD_USERS:
      return {
        ...state,
        customerUsers: payload,
        loadingUsers: false,
      };

    case CUSTOMER_ADMIN_ACCOUNT_EDIT:
      return {
        ...state,
        loadingUsers: true,
      };

    default:
      return state;
  }
}
