import {
  FINALREPORT_NEW,
  FINALREPORT_UPDATE,
  REPORTS_GET_COMPANY,
  REPORTS_GET_CUSTOMER,
  REPORT_CLEAR_STATES,
  REPORT_DELETE,
  REPORT_NEW,
  REPORT_INITIAL,
  REPORT_SELECT,
  REPORT_SELECTED_SUMMARY,
  REPORT_UPDATE,
  SEARCH_REPORTS,
  CLEAR_SELECTED_REPORT,
  DELETE_FINAL,
} from "../types/report.types";

const initialState = {
  reportList: [],
  finalReports: [],
  foundReports: [],

  selectedSummary: null,
  selectedReport: null,

  loadingReports: true,
  loadingReport: true,
  loadingSummary: true,
  reportData: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REPORTS_GET_COMPANY:
      return {
        ...state,
        reportList: payload.reports,
        finalReports: payload.finalReports,
        loadingReports: false,
      };
    case REPORT_NEW:
      return {
        ...state,
        reportData: payload,
      };
    case REPORT_UPDATE:
      return {
        ...state,
        reportData: payload,
      };
    case REPORT_SELECTED_SUMMARY:
      return {
        ...state,
        selectedSummary: payload,
        loadingSummary: false,
      };
    case FINALREPORT_NEW:
      return {
        ...state,
        reportData: payload,
      };
    case FINALREPORT_UPDATE:
      return {
        ...state,
        reportData: payload,
      };

    case SEARCH_REPORTS:
      return {
        ...state,
        foundReports: payload,
      };

    case REPORT_DELETE:
      return {
        ...state,
        reportData: {
          type: "delete",
          ...payload,
        },
        reportList: state.reportList.filter(
          ({ _id }) => !payload.includes(_id)
        ),
      };

    case REPORT_CLEAR_STATES:
      return {
        ...state,
        selectedSummary: null,
        foundReports: [],

        reportData: null,
      };
    case REPORT_SELECT:
      return {
        ...state,
        selectedReport: payload,
        loadingReport: false,
      };
    case CLEAR_SELECTED_REPORT:
      return {
        ...state,
        selectedReport: null,
        loadingReport: true,
      };

    case REPORT_INITIAL:
      return {
        ...initialState,
      };

    case REPORTS_GET_CUSTOMER:
      return {
        ...state,
        finalReports: payload,
        loadingReports: false,
      };

    case DELETE_FINAL:
      return {
        ...state,
        finalReports: state.finalReports.filter((el) => el._id !== payload._id),
      };

    default:
      return state;
  }
}
