export const ITEM_GET_BY_ID = "ITEM_GET_BY_ID";
export const ITEM_USER_ITEMS = "ITEM_USER_ITEMS";
export const ITEM_SELECT = "ITEM_SELECT";

export const ITEM_RESET_ITEMSOCKET = "ITEM_RESET_ITEMSOCKET";
export const ITEM_CLEAR_SELECTED_ITEM = "ITEM_CLEAR_SELECTED_ITEM";
export const ITEM_INITIAL_STATES = "ITEM_INITIAL_STATES";

export const ITEM_UPDATE = "ITEM_UPDATE";
export const ITEMS_UPDATE = "ITEMS_UPDATE";

export const ITEM_NEW = "ITEM_NEW";

export const ITEM_UPDATE_TAGS = "ITEM_UPDATE_TAGS";

export const ITEM_SET_VALID_FILES = "ITEM_SET_VALID_FILES";

export const ITEM_UPDATE_FAVORITES = "ITEM_UPDATE_FAVORITES";

export const ITEM_ADD_DATA = "ITEM_ADD_DATA";

export const ITEM_SET_ITEMS_LOADING = "ITEM_SET_ITEMS_LOADING";

export const ITEM_SET_SUBMITTING = "ITEM_SET_SUBMITTING";

export const ITEM_COUNTS = "ITEM_COUNTS";

export const ITEM_DELETE = "ITEM_DELETE";

export const NEED_TO_LOAD_ITEMS = "NEED_TO_LOAD_ITEMS";

export const UPDATE_ITEM_TODOS = "UPDATE_ITEM_TODOS";
export const UPDATE_ITEMS_TODOS = "UPDATE_ITEMS_TODOS";
export const UPDATE_MY_ITEM_TODOS = "UPDATE_MY_ITEM_TODOS";
export const UPDATE_MY_ITEMS_TODOS = "UPDATE_MY_ITEMS_TODOS";

export const GET_MY_ITEM_SUMMARY = "GET_MY_ITEM_SUMMARY";
export const SET_MY_ITEM_SUMMARY = "SET_MY_ITEM_SUMMARY";
export const UPDATE_COMBINED_ITEMS_TODO = "UPDATE_COMBINED_ITEMS_TODO";

export const ITEM_COPY = "ITEM_COPY";

export const SET_ITEM_REDIRECT_URL = "SET_ITEM_REDIRECT_URL";
