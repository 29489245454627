import axios from "axios";
import { actionAlert } from "./action.alert";
import setAuthToken from "../../utils/setAuthToken";
import proxy from "../../utils/proxy";
import {
  UPDATE_USER,
  LOAD_USER,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
 // REGISTER_FAIL,
  LOGOUT,
  TOGGLE_EMAIL_SUB,
  GET_PUSH_SUB_STATUS,
  DELETE_PUSH_SUB,
  PUSH_SUB_ERROR,
  SET_ONLINE_STATUS,
  LOAD_USER_TO_FINALIZE,
  FINALIZE_ERROR,
  AUTH_ERROR,
  GET_APP_DATA,
  REGISTER_OPEN_FORM,
} from "./types";
import store from "./../../store";
import handleErrors from "./handleErrors";
import { COMPANY_UPDATE_RECENT_ITEMS } from "../types/company.types";

// Headerin tokenin avulla pyydetään käyttäjän tiedot tietokannasta ja tuodaan user object Redux storeen
export const actionLoadUser = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${proxy}/api/common/auth/auth`, config);

    if (res.data.verified && res.data.active) {
      dispatch({
        type: LOAD_USER,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });

    dispatch(handleErrors(err));
  }
};

// Sisäänkirjataan käyttäjä syötetyn sähköpostin ja salasanan avulla
// Jos tietokannasta löytyy osuma, antaa rajapinta vastaukseksi tokenin, joka tallennetaan Redux storeen ja headeriin
export const actionLogin = ({ email, password, cUser, lang }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password, cUser, lang });

  if (!cUser) {
    try {
      const res = await axios.post(
        `${proxy}/api/customer/auth/login`,
        body,
        config
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      setAuthToken(res.data.token);
      dispatch(actionLoadUser());
      return false;
    } catch (err) {
      dispatch(handleErrors(err));

      return true;
    }
  } else {
    try {
      const res = await axios.post(
        `${proxy}/api/company/auth/login`,
        body,
        config
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      setAuthToken(res.data.token);
      dispatch(actionLoadUser());
      return false;
    } catch (err) {
      dispatch(handleErrors(err));

      return true;
    }
  }
};

export const actionConsumerLogin = ({ userName, pw }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ userName, pw });

  try {
    const res = await axios.post(
      `${proxy}/api/consumer/auth/login`,
      body,
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    setAuthToken(res.data.token);
    dispatch(actionLoadUser());
    return true;
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionUpdateProfile = ({
  id,
  firstName,
  lastName,
  companyName,
  address,
  city,
  zip,
  phone,
  email,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    firstName,
    lastName,
    companyName,
    address,
    city,
    zip,
    phone,
    email,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/customer/user/update-profile`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionUpdateProfileCompanyUser = ({
  firstName,
  lastName,
  address,
  city,
  zip,
  phone,
  workDayLength,
  email,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    firstName,
    lastName,
    address,
    city,
    zip,
    phone,
    workDayLength,
    email,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/companyUser/update-profile`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionSubForEmails = (id, value) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ id: id, subForEmails: value });

  try {
    const res = await axios.put(`${proxy}/api/sub-for-emails`, body, config);

    if (res.status === 200) {
      dispatch({
        type: TOGGLE_EMAIL_SUB,
      });

      if (res.data) {
        dispatch(actionAlert("Sähköposti ilmoitukset käytössä!", "success"));
      } else {
        dispatch(
          actionAlert("Sähköposti ilmoitukset pois käytöstä.", "warning")
        );
      }
    }
  } catch (err) {
    const lang = store.getState().auth.user.language;
    const errors = err.response.data.errors;

    if (errors) {
      if (lang === "fi") {
        errors.forEach((error) =>
          dispatch(actionAlert(error.msg.fi, "danger"))
        );
      } else {
        errors.forEach((error) =>
          dispatch(actionAlert(error.msg.en, "danger"))
        );
      }
    }
  }
};

// Täydennä yrityksen peruskäyttäjän tiedot ensimmäisen kerran
export const actionGetPushSubStatus = (endpoint) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = { endpoint: endpoint };

  try {
    const res = await axios.post(
      `${proxy}/api/get-push-sub-status`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PUSH_SUB_STATUS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err);

    dispatch({
      type: PUSH_SUB_ERROR,
    });
  }
};

// Täydennä yrityksen peruskäyttäjän tiedot ensimmäisen kerran
export const actionDeletePushSub = (endpoint) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = { endpoint: endpoint };

  try {
    const res = await axios.post(`${proxy}/api/delete-push-sub`, body, config);

    if (res.status === 200) {
      dispatch({
        type: DELETE_PUSH_SUB,
        payload: false,
      });
    }
  } catch (err) {
    console.error(err);

    dispatch({
      type: PUSH_SUB_ERROR,
    });
  }
};

// Täydennä yrityksen peruskäyttäjän tiedot ensimmäisen kerran
export const actionGetCompanyUserToFinalize = ({ token }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/auth/get-user-to-finalize?id=${token}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: LOAD_USER_TO_FINALIZE,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    dispatch({
      type: FINALIZE_ERROR,
    });
  }
};

// Täydennä asiakkaan tiedot ensimmäisen kerran
export const actionGetCustomerToFinalize = ({ token }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/customer/auth/get-user-to-finalize?id=${token}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: LOAD_USER_TO_FINALIZE,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    dispatch({
      type: FINALIZE_ERROR,
    });
  }
};

// Täydennä asiakkaan tiedot ensimmäisen kerran
export const actionConsumerGetToFinalize = ({ token }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/consumer/auth/get-consumer-to-finalize?id=${token}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: LOAD_USER_TO_FINALIZE,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    dispatch({
      type: FINALIZE_ERROR,
    });
  }
};

// Täydennä yrityksen peruskäyttäjän tiedot ensimmäisen kerran
export const actionFinalizeCompanyUser = ({
  token,
  firstName,
  lastName,
  phone,
  address,
  zip,
  city,
  password,
  language,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    token,
    firstName,
    lastName,
    phone,
    address,
    zip,
    city,
    password,
    language,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/auth/finalize-company-user`,
      body,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Täydennä asiakkaan tiedot ensimmäisen kerran
export const actionFinalizeCustomerUser = ({
  token,
  firstName,
  lastName,
  phone,
  address,
  zip,
  city,
  password,
  language,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    token,
    firstName,
    lastName,
    phone,
    address,
    zip,
    city,
    password,
    language,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/customer/auth/finalize-customer-user`,
      body,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};
// Täydennä asiakkaan tiedot ensimmäisen kerran
export const actionConsumerFinalize = ({
  token,
  firstName,
  lastName,
  phone,
  address,
  zip,
  city,
  password,
  language,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    token,
    firstName,
    lastName,
    phone,
    address,
    zip,
    city,
    password,
    language,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/consumer/auth/finalize`,
      body,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Täydennä asiakkaan tiedot ensimmäisen kerran
export const actionFinalizeFirstUser = ({ token }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    token,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/auth/finalize-first-user`,
      body,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionUpdateLanguage = ({ id, language, userGroup }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ id, language });
  let group;
  if (userGroup > 10) {
    group = "";
  } else {
    group = "/company";
  }

  try {
    const res = await axios.post(
      `${proxy}/api${group}/update-language`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

// Kirjaa käyttäjä ulos, tyhjennä token headereistä ja nollaa user objekti
export const actionLogout = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: LOGOUT });

    await axios.post(`${proxy}/api/logout`, config);

    window.location.reload();

    return;
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionSetOnlineStatus = (status) => async (dispatch) => {
  dispatch({
    type: SET_ONLINE_STATUS,
    payload: status,
  });
};

export const actionChangePw = ({ oldPw, pw1, pw2 }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    oldPw,
    pw1,
    pw2,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/common/auth/change-pw`,
      body,
      config
    );

    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionLogOutAllDevices = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.put(
      `${proxy}/api/common/auth/log-out-all-devices`,
      config
    );

    if (res.status === 200) {
      dispatch({
        payload: res.data.userId,
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionUpdateRecentItems = (items) => (dispatch) => {
  dispatch({
    type: COMPANY_UPDATE_RECENT_ITEMS,
    payload: items,
  });
};

export const actionGetAppData = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/common/auth/get-app-data`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_APP_DATA,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionCompanyFirstRegitration = ({ user, company }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ user, company });

  try {
    const res = await axios.post(
      `${proxy}/api/company/auth/first-registration`,
      body,
      config
    );

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionCompanyCheckRegistrationPin = ({ pin, token }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ pin, token });

  try {
    const res = await axios.post(
      `${proxy}/api/company/auth/check-registration-pin`,
      body,
      config
    );

    dispatch({
      type: REGISTER_OPEN_FORM,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionCompanyOpenRegistration = ({
  firstName,
  lastName,
  address,
  city,
  zip,
  phone,
  email,
  token,
  pin,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    firstName,
    lastName,
    address,
    city,
    zip,
    phone,
    email,
    token,
    pin,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/auth/user-open-registration`,
      body,
      config
    );

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
  }
};
