import moment from "moment";
import "moment/locale/fi"; // without this line it didn't work

// exp output  to 01.04.2021
export function onlyDateWithDayName(date, lang) {
  return moment(date)
    .locale(lang || "fi")
    .format("ddd DD.MM.YYYY");
}
// exp output 20:15 (24h)
export function onlyTime(date, seconds) {
  if (seconds) {
    return moment(date).format("HH:mm:ss");
  }
  return moment(date).format("HH:mm");
}

export function dateWithDayNameAndTime(date, lang) {
  return moment(date)
    .locale(lang || "fi")
    .format("ddd DD.MM.YYYY HH:mm");
}

export function dateAndTimeToFileName(date, lang) {
  return moment(date)
    .locale(lang || "fi")
    .format("DD-MM-YYYYTHH:mm");
}
export function getDateWithDayName(date) {
  if (!date) {
    return moment().format("ddd DD.MM.YYYY");
  } else {
    return moment(date).format("ddd DD.MM.YYYY");
  }
}

export function getWeekNumber() {
  return moment().format("w");
}

export function minToHours(minutes) {
  const hours = minutes / 60;

  return parseFloat(hours.toFixed(1));
}

export function hoursToMin(hours) {
  const minutes = hours * 60;

  return parseFloat(minutes.toFixed(0));
}

export function addToDate(date, unit, number, format) {
  if (format) {
    return moment(date).add(number, unit).format(format);
  }

  return moment(date).add(number, unit).toDate();
}

export function reduceToDate(date, unit, number) {
  return moment(date).subtract(number, unit).toDate();
}

export function isFuture(date) {
  return moment(date).isAfter();
}

export function toMomentObject(date) {
  return moment(date);
}

export function getDuration(a, b, unit, asTime) {
  if (asTime) {
    const diff = moment(b).diff(moment(a), unit);
    const duration = moment.duration(diff, unit);

    return {
      years: duration._data.years,
      months: duration._data.months,
      days: duration._data.days,
      hours: duration._data.hours,
      minutes: duration._data.minutes,
      seconds: duration._data.seconds,
    };
  } else {
    return moment(b).diff(moment(a), unit);
  }
}

export function momentEndOf(field, date) {
  return moment(date).endOf(field).toDate();
}

export function momentStartOf(field, date) {
  return moment(date).startOf(field).toDate();
}

export function formatTimeZeros(time) {
  if (time < 0) {
    const time2 = time.toString().replace("-", "");
    if (time2 < 10) {
      return `-0${time2}`;
    } else {
      return time;
    }
  } else if (time > 0) {
    if (time < 10) {
      return `0${time}`;
    } else {
      return time;
    }
  } else if (time === 0) {
    return `0${time}`;
  }
}

export function formatDate(date, format) {
  return moment(date).format(format);
}

export function isInRange(date, range) {
  // päivää ei annettu esim se on "undefined"
  if (!date) {
    return false;
  }

  let start;
  let end;
  const date2 = moment(date);

  // tehdään "aikaväli" annetuilla tiedoilla
  //esim. "today" -> xx.xx.xxxxT00:00:00 - xx.xx.xxxxT59:59:59
  if (range === "today") {
    start = moment().locale("fi").startOf("day").toDate();
    end = moment().locale("fi").endOf("day").toDate();
  } else if (range === "tomorrow") {
    start = moment().locale("fi").add(1, "days").startOf("day").toDate();
    end = moment().locale("fi").add(1, "days").endOf("day").toDate();
  } else if (range === "week") {
    start = moment().locale("fi").startOf("week").toDate();
    end = moment().locale("fi").endOf("week").toDate();
  }

  // jos annettu date on välillä
  if (date2 >= start && date2 <= end) {
    return true;
  }

  return false;
}
