import {
  DELETE_NOTIFICATION,
  EMPTY_NOTIFICATIONS,
  SET_NOTIFICATIONS,
} from "../types/notification.types";

const initialState = {
  allNotifications: null,
  loadingNotifications: true,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: payload,
        loadingNotifications: false,
      };

    case DELETE_NOTIFICATION:
      return {
        ...state,
        allNotifications: state.allNotifications?.filter(
          (el) => el._id !== payload.id
        ),
      };

    case EMPTY_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: null,
        loadingNotifications: true,
      };

    default:
      return state;
  }
}
