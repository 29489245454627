import {
  COMPANYDATA_DELETE_PART,
  COMPANYDATA_GET_PARTS,
  COMPANYDATA_NEW_PART,
  COMPANYDATA_RESET_STATES,
  COMPANYDATA_Update_PART,
} from "../types/companyData.types";

const initialState = {
  partsList: [],
  loadingParts: true,
  newPartSuccess: false,
  updatePartSuccess: false,
  deletePartSuccess: false,

  partData: null,

};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case COMPANYDATA_GET_PARTS:
      return {
        ...state,
        partsList: payload,
        loadingParts: false,
      };
    case COMPANYDATA_NEW_PART:
      return {
        ...state,
        partData: payload,
        newPartSuccess: true,
      };
    case COMPANYDATA_Update_PART:
      return {
        ...state,
        partData: payload,
        updatePartSuccess: true,
      };
    case COMPANYDATA_DELETE_PART:
      return {
        ...state,
        deletePartSuccess: true,
        partData: payload,
      };
    case COMPANYDATA_RESET_STATES:
      return {
        ...state,
        newPartSuccess: false,
        updatePartSuccess: false,
        deletePartSuccess: false,

        partData: null,
      };

    default:
      return state;
  }
}
