export const COMPANY_RESET_STATES = "COMPANY_RESET_STATES";
export const COMPANY_BASIC_USERLIST = "COMPANY_BASIC_USERLIST";
export const COMPANY_UPDATE_RECENT_ITEMS = "COMPANY_UPDATE_RECENT_ITEMS";

export const SET_COMPANY_SOCKET_DATA = "SET_COMPANY_SOCKET_DATA";
export const COMPANY_UPDATE_CUSTOMERS = "COMPANY_UPDATE_CUSTOMERS";

export const COMPANY_UPDATE_CUSTOMER = "COMPANY_UPDATE_CUSTOMER";

export const COMPANY_UPLOADING = "COMPANY_UPLOADING";

export const COMPANY_ADD_FILES = "COMPANY_ADD_FILES";
export const COMPANY_DELETE_FILES = "COMPANY_DELETE_FILES";

export const COMPANY_GET_CUSTOMER_GROUPS = "COMPANY_GET_CUSTOMER_GROUPS";

export const COMPANY_UPDATE_CUSTOMER_GROUPS = "COMPANY_UPDATE_CUSTOMER_GROUPS";
