import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconSignal } from "../components/Icons/Icons";

import socketIOClient from "socket.io-client";
import proxy from "./proxy";
import useQueryParam from "./useQueryParam";
import {
  actionSetSocket,
  actionSocketInitialStates,
} from "../redux/actions/action.socket";

const LinkUserSocket = ({ linkValidated, decodedLink }) => {
  const connectedIcon = <FontAwesomeIcon icon={iconSignal} color="#8ce300" />;
  const disconnectedIcon = <FontAwesomeIcon icon={iconSignal} color="red" />;

  const [token] = useQueryParam("token");

  const dispatch = useDispatch();

  const [states, setStates] = useState({
    connected: false,
    socket: undefined,
  });
  const { connected, socket } = states;

  const { event, subEvent, author, data, response } = useSelector(
    (state) => state.socket
  );

  useEffect(() => {
    const socket = socketIOClient.connect(proxy, {
      query: { linkToken: token },
    });

    dispatch(actionSetSocket(socket));

    socket.on("connect", () => {
      setStates((state) => ({
        ...state,
        connected: true,
        socket: socket,
      }));
    });

    socket.on("disconnect", () => {
      setStates((state) => ({
        ...state,
        connected: false,
        socket: undefined,
      }));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChatEmit = () => {
    const content = {
      author,
      event: event,
      subEvent: subEvent,
      response: response,
      ...data,
    };

    if (content) {
      socket.emit("chat", JSON.stringify(content));
    }
  };

  useEffect(() => {
    if (event === "chat") {
      handleChatEmit();
      dispatch(actionSocketInitialStates());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  if (!linkValidated) {
    return null;
  }

  return (
    <div className="wsConnection">
      {connected ? connectedIcon : disconnectedIcon}
    </div>
  );
};

export default LinkUserSocket;
