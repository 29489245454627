import React, { useState, useEffect } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import './CustomSpinner.scss'


const CustomSpinner = (props) => {

    const [color, setColor] = useState('#00b8d4')

    useEffect(() => {
        if (props.color) {
            switch(props.color) {
                case 'black':
                    return setColor('#0f1416')
                case 'blue':
                    return setColor('#3289ca')
                case 'brown':
                    return setColor('#d6b99f')
                case 'white':
                    return setColor('#ffffff')
                default:
                    return setColor('#00b8d4')
            }
        }

    },[props.color])

    return (
        <Container fluid>
            
            <Spinner animation="border" className="customSpinner" style={{ border: `0.4em solid ${color}` }} />          
            
        </Container>
    )
}

export default CustomSpinner