import {
  CLEAR_LINK_SELECTED,
  GET_ITEM_IMAGES,
  GET_TOKEN_CHATS,
  GET_TOKEN_ITEMS,
  SELECT_TOKEN_CHATS,
  SEND_TOKEN_MSG,
  VALIDATE_LINK_USER,
  VALIDATE_LINK_USER_FAIL,
} from "../types/links.types";

const initialState = {
  loadingValidate: true,
  linkValidated: false,
  decodedLink: undefined,

  loadingItems: true,
  tokenItems: undefined,

  loadingChats: true,
  tokenChats: undefined,

  selected: undefined,
  loadingSelected: true,

  files: [],

  error: {
    status: undefined,
    message: undefined,
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VALIDATE_LINK_USER:
      return {
        ...state,
        loadingValidate: false,
        linkValidated: payload.validated,
        decodedLink: payload.decoded,
      };
    case VALIDATE_LINK_USER_FAIL:
      return {
        ...state,
        loadingValidate: false,
        linkValidated: false,
        decodedLink: undefined,
        error: {
          message: payload.message,
          status: payload.status,
        },
      };
    case GET_TOKEN_ITEMS:
      return {
        ...state,
        loadingItems: false,
        tokenItems: payload,
      };

    case GET_TOKEN_CHATS:
      return {
        ...state,
        loadingChats: false,
        tokenChats: payload,
      };

    case SELECT_TOKEN_CHATS:
      return {
        ...state,
        selected: payload,
        loadingSelected: false,
      };

    case SEND_TOKEN_MSG:
      return {
        ...state,
        selected: {
          ...state.selected,
          messages: [payload, ...state.selected.messages],
        },
      };
    case CLEAR_LINK_SELECTED:
      return {
        ...state,
        selected: undefined,
        loadingSelected: true,
      };

    case GET_ITEM_IMAGES:
      return {
        ...state,
        files: { [payload.itemId]: payload.files },
      };
    default:
      return state;
  }
}
