export const GET_DISPLAYS = "GET_DISPLAYS";
export const GET_DB_HOME = "GET_DB_HOME";

export const UPDATE_DB_ITEMS = "UPDATE_DB_ITEMS";

export const DISPLAYS_ONLINE = "DISPLAYS_ONLINE";

export const UPDATE_DISPLAY = "UPDATE_DISPLAY";
export const UPDATE_SELECTED_DISPLAY = "UPDATE_SELECTED_DISPLAY";

export const ADD_DB_ITEMS = "ADD_DB_ITEMS";
export const REMOVE_DB_ITEM = "REMOVE_DB_ITEM";

export const NEW_DISPLAY = "NEW_DISPLAY";
export const DELETE_DISPLAY = "DELETE_DISPLAY";

export const SORT_DB_ITEMS = "SORT_DB_ITEMS";
