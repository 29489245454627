import React from "react";
import { Container } from "react-bootstrap";
import "./CustomSpinner.scss";
import logo from "../../images/Info247_logo_500x200.png";

const CustomSpinner2 = () => {
  return (
    <Container fluid className="customSpinner2">
      <img alt="load" src={logo} className="fadeInOut" />
    </Container>
  );
};

export default CustomSpinner2;
