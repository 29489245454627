import React from "react";
import { Form } from "react-bootstrap";
import { Table } from "react-bootstrap";

import "./CostsTables.scss";
export const SimpleCostsTable = ({ costs, customer, showPrices }) => (
  <div className="CostTable">
    {costs.length > 0 ? (
      <Table striped responsive>
        <thead>
          <tr>
            <th className="th1">Tyyppi</th>
            <th className="th1">Nimi</th>
            <th className="th2">Määrä</th>
            {showPrices && <th className="th2">Hinta</th>}
            {showPrices && <th>Yht.</th>}
          </tr>
        </thead>

        <tbody>
          {costs.map((cost, i) => (
            <tr key={i}>
              <td>
                <Form.Control
                  name="type"
                  value={cost.type || ""}
                  as="select"
                  readOnly
                  className="costSelect"
                  disabled={customer}
                >
                  <option value="work">Työtunnit</option>
                  <option value="benefits">Päivärahat</option>
                  <option value="travel">Matkakulut</option>
                  <option value="distance">Km-kulut</option>
                  {/*<option value="accommodation">Majoitus</option>*/}
                  <option value="other">Muu</option>
                </Form.Control>
              </td>
              <td>
                <Form.Control name="title" value={cost.title || ""} readOnly />
              </td>

              <td>
                <Form.Control
                  name="quantity"
                  value={cost.quantity || ""}
                  readOnly
                  type="number"
                />
              </td>

              {showPrices && (
                <td>
                  <Form.Control
                    name="price"
                    value={cost.price || ""}
                    type="number"
                    readOnly
                  />
                </td>
              )}
              {showPrices && (
                <td>
                  <p>{cost.total ? cost.total : "-"}</p>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p>EI kuluja lisätty</p>
    )}
  </div>
);

export const SimplePartsTable = ({ parts, showPrices }) =>
  parts.length > 0 ? (
    <Table striped responsive className="CostTable">
      <thead>
        <tr>
          <th className="th1">Nimi</th>
          <th className="th1">Takuu (kk)</th>

          <th className="th2">Määrä</th>
          {showPrices && <th className="th2">Hinta</th>}
          {showPrices && <th>Yht.</th>}
        </tr>
      </thead>

      <tbody>
        {parts.map((cost, i) => (
          <tr key={i}>
            <td>
              <Form.Control name="title" value={cost.title || ""} readOnly />
            </td>
            <td>
              <Form.Control name="title" value={cost.warranty || 0} readOnly />
            </td>

            <td>
              <Form.Control
                name="quantity"
                value={cost.quantity || ""}
                readOnly
                type="number"
              />
            </td>
            {showPrices && (
              <td>
                <Form.Control
                  name="salesPrice"
                  value={cost.salesPrice || ""}
                  type="number"
                  readOnly
                />
              </td>
            )}
            {showPrices && (
              <td>
                <p>
                  {cost.total
                    ? cost.total
                    : cost.quantity && cost.salesPrice
                    ? cost.quantity * cost.salesPrice
                    : "-"}
                </p>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <p>EI tuotteita lisätty</p>
  );
