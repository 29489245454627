import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import IconButton from "../IconButton/IconButton";
import { iconTrash } from "../Icons/Icons";

export default function ConfirmPopOver({
  title,
  text,
  btnText,
  type,
  action,
  placement,
  children,
}) {
  const deleteIcon = (
    <FontAwesomeIcon icon={iconTrash} color="black" size="sm" />
  );

  const popover = (
    <Popover>
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>
        <div className="popDiv">
          <p>{text}</p>
          <Button size="sm" onClick={action} className="secondaryBtn" block>
            {btnText || "Vahvista"}
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={placement || "bottom"}
      overlay={popover}
      rootClose
    >
      {children ? (
        children
      ) : (
        <IconButton type="delete" icon={deleteIcon} text={null} size="sm" />
      )}
    </OverlayTrigger>
  );
}
