import { RESET_VALIDATION_ERRORS, VALIDATION_ERRORS } from "../actions/types";

const initialState = {
  errors: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VALIDATION_ERRORS:
      return {
        errors: payload,
      };

    case RESET_VALIDATION_ERRORS:
      return initialState;

    default:
      return state;
  }
}
