import { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { actionHomeDB } from "../../redux/actions/action.displayUI";
import logo from "../../images/pp-logo.png";

import "./Display.scss";
import { actionSetModals2 } from "../../redux/actions/action.modal";
import { actionSetSession } from "../../redux/actions/action.session";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconCalendar, iconClock } from "../../components/Icons/Icons";

import {
  dateWithDayNameAndTime,
  getDateWithDayName,
  getWeekNumber,
  onlyTime,
} from "../../utils/toDates";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { actionSortDBItems } from "../../redux/actions/action.display";

const DisplayHome = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dbId } = useParams();

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { items, display } = useSelector((state) => state.display);
  const [dateNow, setDateNow] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDateNow(new Date()), 2000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actionSetSession("", undefined, true));
      dispatch(actionHomeDB(dbId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (items && items.length) {
      // järjestetään statuksen mukaan
      dispatch(actionSortDBItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  const card = (db, i) => {
    const statusStyle =
      parseInt(db.status) === 1
        ? "status1"
        : parseInt(db.status) === 2
        ? "status2"
        : parseInt(db.status) === 3
        ? "status3"
        : "status0";
    return (
      <div key={i} className="boardCard">
        <div className={`dates ${statusStyle}`}>
          <p>{db.start ? `${dateWithDayNameAndTime(db.start)} -` : "--:--"}</p>

          <p>{db.stop ? dateWithDayNameAndTime(db.stop) : "--:--"}</p>
        </div>

        <p className="p-title">{db.title}</p>

        <p className="p-desc">{db.description}</p>
      </div>
    );
  };

  const noItems = (
    <div className="emptyScreen">
      <img alt="logo" src={logo} />
      <h2>EI kohteita</h2>
    </div>
  );

  const openLogin = () => {
    dispatch(
      actionSetModals2({
        modalType: 403,
        data: {},
        title: "Kirjaudu näyttöön",
      })
    );
  };

  const goToLogin = () => {
    history.replace("/login");
  };

  if (!isAuthenticated) {
    return (
      <div className="basicPage NotLoggedIn">
        <h2>Kirjaudu</h2>

        <Button className="primaryBtn" onClick={openLogin}>
          Näytön kirjautuminen
        </Button>

        <Button className="primaryBtn" onClick={goToLogin}>
          Takaisin kirjautumis sivulle
        </Button>
      </div>
    );
  }

  return (
    <div className="basicPage DisplayHome">
      {dbId && (
        <>
          <h2>ÄLÄ JÄTÄ TÄTÄ NÄKYMÄÄ AUKI!!!!</h2>
          <p>Tämä on tarkoitettu vain näytön esikatseluun</p>
          <small>
            Näytön näkymä vaihtelee käytössä olevan näytön resoluution ja koon
            mukaan
          </small>
          <hr />
        </>
      )}

      <div className="infoDiv">
        <div className="date">
          <p className="p-date">
            <FontAwesomeIcon icon={iconCalendar} />{" "}
            {getDateWithDayName(dateNow)}
          </p>
          <p className="p-time">
            <FontAwesomeIcon icon={iconClock} /> {onlyTime(dateNow, true)}
          </p>

          <p className="p-date">Vko: {getWeekNumber(dateNow)}</p>
        </div>

        <h2>{display?.name}</h2>

        {display && display.lastReceived && (
          <p>Päivittynyt: {dateWithDayNameAndTime(display.lastReceived)}</p>
        )}
      </div>

      {display && (
        <div className="dbInfo">
          <br />

          <div className="alerts">
            {display.alerts?.length &&
              display.alerts.map((el, i) => (
                <Badge pill variant={el.variant} key={i} className="badge">
                  {el.title}
                </Badge>
              ))}
          </div>
        </div>
      )}

      {items?.length ? (
        <div className="items">{items.map((el, i) => card(el, i))}</div>
      ) : (
        noItems
      )}
    </div>
  );
};

export default DisplayHome;
