import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import RecordAudio from "./RecordAudio";

import "./SelectFile.scss";
import useUpload from "./useUpload";
import { useDispatch, useSelector } from "react-redux";
import { actionSetModals2 } from "../../redux/actions/action.modal";
export default function SelectFile({
  folder,
  id,
  setFiles,
  supportShowToCustomer,
  supportAudio,
  disableForCustomer,
  categories,
  setIsFileSelected,
}) {
  const dispatch = useDispatch();
  const [states, setStates] = useState({
    showToCustomer: true,
    recordAudio: false,
  });

  const { showToCustomer, recordAudio } = states;

  const { userGroup } = useSelector((state) => state.auth.user);
  const categories2 = categories || [];

  // select file and upload logic
  const {
    fileData,
    uploading,
    selectFile,
    uploadFileCompany,
    uploadFileCustomer,
    setFileData,
  } = useUpload(setFiles, folder, id, showToCustomer);

  useEffect(() => {
    if (fileData && fileData.myFile) {
      setIsFileSelected(true);
    } else {
      setIsFileSelected(false);
    }
  }, [fileData]);

  const onLegendChange = (e) => {
    setFileData({ ...fileData, legend: e.target.value });
  };

  const onCategoryChange = (e) => {
    setFileData({ ...fileData, category: e.target.value });
  };

  const handleSwitch = (e) => {
    setStates({ ...states, [e.target.name]: e.target.checked });
  };

  if (disableForCustomer && userGroup > 10) {
    return null;
  }

  return (
    <div className="fileForm">
      {supportAudio && (
        <Form.Group>
          <Form.Check
            label="Nauhoita ääntä (testi)"
            onChange={handleSwitch}
            checked={recordAudio}
            name="recordAudio"
          />
        </Form.Group>
      )}
      {recordAudio && supportAudio ? (
        <RecordAudio
          id={id}
          folder={folder}
          uploadFile={
            userGroup < 11
              ? uploadFileCompany
              : userGroup > 10
              ? uploadFileCustomer
              : null
          }
          uploading={uploading}
        />
      ) : (
        <Form
          onSubmit={(e) =>
            userGroup < 11
              ? uploadFileCompany(e)
              : userGroup > 10
              ? uploadFileCustomer(e)
              : null
          }
        >
          <div className="formButtons">
            <Form.File
              id="upload-file1"
              label="Kamera"
              data-browse=""
              name="upload-file"
              accept="image/*"
              capture="camera"
              onChange={(e) => selectFile(e)}
              custom
            />

            <Form.File
              id="upload-file2"
              label="Selaa"
              data-browse="Selaa"
              name="upload-file"
              accept="image/* aplication/pdf"
              onChange={(e) => selectFile(e)}
              custom
            />
          </div>
          <Form.Text>
            Kelpaavat tiedostot: jpg, jpeg, png, pdf, mp4, (mkv - varauksin)
          </Form.Text>
          <Form.Text>Maksimi tiedosto koko: 80Mb</Form.Text>

          {userGroup > 10 ? null : supportShowToCustomer && fileData.myFile ? (
            <Form.Group>
              <Form.Check
                label="Näytä asiakkaalle"
                onChange={handleSwitch}
                checked={showToCustomer}
                name="showToCustomer"
              />
            </Form.Group>
          ) : null}

          <hr />

          <p>
            Tiedosto: {fileData.fileName ? fileData.fileName : "ei valittu"}
            <br />
          </p>
          {fileData.fileExtension === "mkv" && (
            <Form.Text className="text-warning">
              HUOM! Videoille paras formaatti on "mp4. "Mkv"-videoita tukee
              toistaiseksi vain Chrome.
            </Form.Text>
          )}

          <div>
            {uploading ? (
              <Spinner animation="border" />
            ) : fileData.myFile && fileData.myFile !== "" ? (
              <>
                <Form.Group>
                  <Form.Label>Nimi</Form.Label>
                  <Form.Control
                    name="legend"
                    value={fileData.legend}
                    onChange={onLegendChange}
                    disabled={uploading}
                  />
                </Form.Group>

                {folder === "item" && userGroup < 11 && (
                  <>
                    <Form.Label>Kategoria</Form.Label>

                    <div className="categoryDiv">
                      <Button
                        onClick={() =>
                          dispatch(
                            actionSetModals2({
                              modalType: 39,
                              title: "Kategoria",
                              data: {},
                            })
                          )
                        }
                      >
                        +Kategoria
                      </Button>{" "}
                      <Form.Control
                        as="select"
                        onChange={onCategoryChange}
                        defaultValue={"0"}
                      >
                        {[{ title: "Kaikki", id: "0" }, ...categories2].map(
                          (option, i) => (
                            <option key={i} value={option.id}>
                              {option.title}
                            </option>
                          )
                        )}
                      </Form.Control>
                    </div>
                  </>
                )}

                <Button type="submit" className="primaryBtn">
                  Lisää tiedosto
                </Button>
              </>
            ) : null}
          </div>
        </Form>
      )}
    </div>
  );
}

SelectFile.propTypes = {
  folder: PropTypes.string.isRequired,
  supportShowToCustomer: PropTypes.bool,
  supportAudio: PropTypes.bool,
  disableForCustomer: PropTypes.bool,
};

SelectFile.defaultProps = {
  folder: undefined,
  supportShowToCustomer: false,
  supportAudio: false,
  disableForCustomer: true,
};
