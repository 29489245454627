import {
  CALENDAR_GET_EVENTS,
  SET_CALENDAR_EVENTS,
} from "../types/calendar.types";

const initialState = {
  loading: true,
  allEvents: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CALENDAR_GET_EVENTS:
      return {
        ...state,
        allEvents: [...state.allEvents, ...payload],
        loading: false,
      };

    case SET_CALENDAR_EVENTS:
      return {
        ...state,
        allEvents: payload.events,
        loading: payload.loading,
      };
    default:
      return state;
  }
}
