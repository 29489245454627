import React from "react";

export default function MakeError() {
  const x = undefined;
  return (
    <div>
      <h1>Virhe sivu</h1>

      {x.map((x) => console.log(x))}
    </div>
  );
}
