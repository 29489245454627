export function countCosts(costs) {
  let prices = {
    total: 0,
    work: 0,
    benefits: 0,
    travel: 0,
    distance: 0,
    other: 0,
  };

  let work = {
    zeroHours: 0,
    pricedHours: 0,
    total: 0,
  };
  let travel = {
    zeroHours: 0,
    pricedHours: 0,
    total: 0,
  };

  let total = {
    zeroHours: 0,
    pricedHours: 0,
    total: 0,
  };

  costs.forEach((cost, i) => {
    const type = cost.type;
    const quantity = cost.quantity ? cost.quantity : 0;
    const price = cost.price ? cost.price : 0;

    if (type === "work") {
      prices.work += quantity * price;
      //  hours += parseFloat(quantity);

      if (price && price !== "" && price > 0) {
        work.pricedHours += parseFloat(quantity);
        total.pricedHours += parseFloat(quantity);
      } else {
        work.zeroHours += parseFloat(quantity);
        total.zeroHours += parseFloat(quantity);
      }
      work.total += parseFloat(quantity);
      total.total += parseFloat(quantity);
    } else if (type === "benefits") {
      prices.benefits += quantity * price;
    } else if (type === "travel") {
      prices.travel += quantity * price;

      if (price && price !== "" && price > 0) {
        travel.pricedHours += parseFloat(quantity);
        total.pricedHours += parseFloat(quantity);
      } else {
        travel.zeroHours += parseFloat(quantity);
        total.zeroHours += parseFloat(quantity);
      }
      travel.total += parseFloat(quantity);
      total.total += parseFloat(quantity);
    } else if (type === "distance") {
      prices.distance += quantity * price;
    } else if (type === "other") {
      prices.other += quantity * price;
    }
    prices.total += quantity * price;
  });

  const hours = {
    work,
    travel,
    total,
  };

  return { prices, hours };
}

export function countParts(parts) {
  let partTotal = 0;

  parts.forEach((part) => {
    const quantity = part.quantity ? part.quantity : 0;
    const price = part.salesPrice ? part.salesPrice : 0;

    partTotal += quantity * price;
  });

  return partTotal;
}
