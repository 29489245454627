import { SET_ERRORS } from "../types/errors.types";

const initialState = {
  status: null,
  data: null,
  showType: null,
  // uusi idea
  errors: null,
  type: "one",
  variant: "danger",
  redirectUrl: null,
  reloadPage: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ERRORS:
      return {
        ...state,
        status: payload.status,

        data: payload.data,
        showType: payload.showType,

        // uusi idea
        errors: payload.errors,
        type: payload.type,
        redirectUrl: payload.redirectUrl,
        reloadPage: payload.reloadPage,
      };

    default:
      return state;
  }
}
