import {
  CONSUMER_CLEAR_ITEM,
  CONSUMER_GET_ITEMS,
  CONSUMER_GET_ITEM_REPORTS,
  CONSUMER_SELECT_ITEM,
  CONSUMER_SELECT_REPORT,
} from "../types/consumer.types";

const initialState = {
  items: [],
  loadingItems: true,

  reports: [],
  loadingReports: true,
  selectedReport: null,
  loadingReport: true,

  item: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONSUMER_GET_ITEMS:
      return {
        ...state,
        items: payload,
        loadingItems: false,
      };
    case CONSUMER_SELECT_ITEM:
      return {
        ...state,
        item: payload,
        loadingItems: false,
      };
    case CONSUMER_GET_ITEM_REPORTS:
      return {
        ...state,
        reports: payload,
        loadingReports: false,
      };
    case CONSUMER_CLEAR_ITEM:
      return {
        ...state,
        items: [],
        loadingItems: true,

        reports: [],
        loadingReports: true,

        item: null,
      };
    case CONSUMER_SELECT_REPORT:
      return {
        ...state,
        selectedReport: payload,
        loadingReport: false,
      };

    default:
      return state;
  }
}
