import {
  SET_TOAST,
  REMOVE_TOAST,
  CLEAR_TOASTS,
  UPDATE_TOAST,
} from "../actions/types";

const initialState = [];

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_TOAST:
      return [...state, payload];

    case REMOVE_TOAST:
      return state.filter((toast) => toast.id !== payload);

    case UPDATE_TOAST:
      return state.map((el) =>
        el.id === payload.id ? { ...el, ...payload } : el
      );

    case CLEAR_TOASTS:
      return initialState;

    default:
      return state;
  }
}
