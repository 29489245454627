import {
  COMMON_DELETE_TAG,
  COMMON_GET_TAGS,
  COMMON_UPDATE_TAG_LIST,
  COMMON_UPDATE_TAG,
  COMMON_NEW_TAG,
} from "../types/common.types";

const initialState = {
  tags: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case COMMON_GET_TAGS:
      return {
        ...state,
        tags: payload,
      };
    case COMMON_UPDATE_TAG_LIST:
      return {
        ...state,
        tags: [...payload, ...state.tags],
      };

    case COMMON_DELETE_TAG:
      return {
        ...state,
        tags: state.tags.filter((el) => el._id !== payload.id),
      };

    case COMMON_UPDATE_TAG:
      return {
        ...state,
        tags: state.tags.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };

    case COMMON_NEW_TAG:
      return {
        ...state,
        tags: [payload, ...state.tags],
      };

    default:
      return state;
  }
}
