import { useState } from "react";
import useQueryParam from "../../utils/useQueryParam";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  actionCompanyCheckRegistrationPin,
  actionCompanyOpenRegistration,
} from "../../redux/actions/action.auth";

import "./Register.scss";

const RegisterCompanyUser = () => {
  const dispatch = useDispatch();
  const [id] = useQueryParam("id");
  const {
    openRegistrationForm,
    registerSuccess,
    isAuthenticated,
  } = useSelector((state) => state.auth);

  const [states, setStates] = useState({
    pin: "",
  });
  const { pin } = states;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",

      address: "",
      city: "",
      zip: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Pakollinen tieto"),
      lastName: Yup.string().required("Pakollinen tieto"),

      email: Yup.string().required("Pakollinen tieto"),

      zip: Yup.string()
        .matches(/^[0-9]+$/, "Pelkästään numerot sallittu")
        .min(5, "Pitää olla 5 numeroa")
        .max(5, "Pitää olla 5 numeroa"),
    }),
    onSubmit: (values) => {
      const content = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        address: values.address,
        city: values.city,
        zip: values.zip,
        token: id,
        pin: pin,
      };

      dispatch(actionCompanyOpenRegistration(content));
    },
  });

  const onChange = (e) => {
    setStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const submitPin = () => {
    const content = {
      pin: pin,
      token: id,
    };

    dispatch(actionCompanyCheckRegistrationPin(content));
  };

  const forms = !openRegistrationForm ? (
    <Form>
      <Form.Group>
        <Form.Label>PIN-koodi</Form.Label>
        <Form.Control name="pin" onChange={onChange} value={pin} />
        <Form.Text>Tämä koodi on yrityksellä tiedossa</Form.Text>
      </Form.Group>

      <Button variant="link" onClick={submitPin} className="outLine-blue">
        Avaa rekisteröityminen
      </Button>
    </Form>
  ) : (
    <Form className="forms formUser boxShadow">
      <h2>KÄYTTÄJÄN TIEDOT</h2>
      <div className="flex">
        <Form.Group className="flex-1">
          <Form.Label>Etunimi</Form.Label>
          <Form.Control
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            isInvalid={formik.touched.firstName && formik.errors.firstName}
            onBlur={formik.handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.firstName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="flex-1">
          <Form.Label>Sukunimi</Form.Label>
          <Form.Control
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            isInvalid={formik.touched.lastName && formik.errors.lastName}
            onBlur={formik.handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.lastName}
          </Form.Control.Feedback>
        </Form.Group>
      </div>

      <Form.Group className="flex-1">
        <Form.Label>Sähköposti *</Form.Label>
        <Form.Control
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          isInvalid={formik.touched.email && formik.errors.email}
          onBlur={formik.handleBlur}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.email}
        </Form.Control.Feedback>
      </Form.Group>

      <p className="p-sub">Vapaaehtoiset kentät</p>

      <Form.Group>
        <Form.Label>Puhelin</Form.Label>
        <Form.Control
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          isInvalid={formik.touched.phone && formik.errors.phone}
          onBlur={formik.handleBlur}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.phophonene}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="flex">
        <Form.Group className="flex-3">
          <Form.Label>Osoite</Form.Label>
          <Form.Control
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            isInvalid={formik.touched.address && formik.errors.address}
            onBlur={formik.handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.address}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="flex-2">
          <Form.Label>Paikkakunta</Form.Label>
          <Form.Control
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            isInvalid={formik.touched.city && formik.errors.city}
            onBlur={formik.handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.city}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="flex-1">
          <Form.Label>Postinumero</Form.Label>
          <Form.Control
            name="zip"
            value={formik.values.zip}
            onChange={formik.handleChange}
            isInvalid={formik.touched.zip && formik.errors.zip}
            onBlur={formik.handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.zip}
          </Form.Control.Feedback>
        </Form.Group>
      </div>

      <hr />

      <p>
        Pääset antamaan salasanan sähköpostiin tulevan vahvistuslinkin kautta
      </p>

      <Button onClick={formik.handleSubmit}>Tallenna</Button>
    </Form>
  );

  if (registerSuccess) {
    return (
      <div className="basicPage RegisterCompanyAndAdmin">
        <h2>Tunnuksen luonti onnistui</h2>

        <p>
          Tunnuksesi sähköpostiin ({formik.values.email}) on lähetetty
          vahvistusviesti
        </p>
        <p>
          Viestin kautta pääset asettamaan tunnuksellesi salasanan ja
          varmistamaan tietosi
        </p>

        <p>
          HUOM! Sähköpostin linkki on voimassa 7 päivää, joten vahvistathan
          tunnuksesi ennen sitä!
        </p>
      </div>
    );
  }

  return (
    <div className="basicPage RegisterCompanyAndAdmin">
      {isAuthenticated ? (
        <p>
          <strong>HUOM! olet kirjautuneena sisään</strong>
        </p>
      ) : null}
      <h2>Rekisteröidy</h2>
      {forms}
    </div>
  );
};

export default RegisterCompanyUser;
