import {
  faPlus,
  faUndoAlt,
  faFilter,
  faSearch,
  faCheckCircle,
  faTimesCircle,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCopy,
  faListOl,
  faLock,
  faEye,
  faEyeSlash,
  faCalendarDay,
  faRedo,
  faUndo,
  faSignal,
  faBars,
  faHistory,
  faSync,
  faHome,
  faMicrophone,
  faStar as faStar1,
  faEllipsisV,
  faLightbulb,
  faCaretSquareRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  faClock,
  faClipboard,
  faComment,
  faEdit,
  faQuestionCircle,
  faTrashAlt,
  faCompass,
  faUserCircle,
  faFileAlt,
  faBell,
  faBellSlash,
  faListAlt,
  faImages,
  faStar as faStar2,
  faFolderOpen,
  faFolder,
  faSave,
  faLightbulb as faLightbulb2,
} from "@fortawesome/free-regular-svg-icons";

export {
  faClock as iconClock,
  faPlus as iconPlus,
  faTrashAlt as iconTrash,
  faUndoAlt as iconUndo,
  faClipboard as iconItems,
  faComment as iconMsg,
  faEdit as iconEdit,
  faQuestionCircle as iconQuestion,
  faFilter as iconFilter,
  faSearch as iconSearch,
  faCompass as iconLocation,
  faCheckCircle as iconSuccess,
  faTimesCircle as iconFail,
  faChevronDown as iconDown,
  faChevronUp as iconUp,
  faCopy as iconCopy,
  faUserCircle as iconUser,
  faListOl as iconList,
  faFileAlt as iconFile,
  faLock as iconLocked,
  faEye as iconEye,
  faEyeSlash as iconEye2,
  faBell as iconBell,
  faBellSlash as iconBell2,
  faCalendarDay as iconCalendar,
  faUndo as iconRotate,
  faRedo as iconRotate2,
  faSignal as iconSignal,
  faChevronLeft as iconLeft,
  faChevronRight as iconRight,
  faBars as iconMenu,
  faListAlt as iconItem,
  faImages as iconImages,
  faHistory as iconHistory,
  faSync as iconRefresh,
  faHome as iconHome,
  faMicrophone as iconMic,
  faStar1 as iconStar1,
  faStar2 as iconStar2,
  faFolderOpen as iconOpenFolder,
  faFolder as iconFolder,
  faEllipsisV as iconThreeDots,
  faSave as iconSave,
  faLightbulb as lightBulb,
  faLightbulb2 as lightBulb2,
  faCaretSquareRight as iconOpenLink,
};
