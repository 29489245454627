import axios from "axios";
import proxy from "../../utils/proxy";
import {
  ADD_DB_ITEMS,
  DELETE_DISPLAY,
  DISPLAYS_ONLINE,
  GET_DISPLAYS,
  NEW_DISPLAY,
  REMOVE_DB_ITEM,
  SORT_DB_ITEMS,
  UPDATE_DB_ITEMS,
  UPDATE_DISPLAY,
  UPDATE_SELECTED_DISPLAY,
} from "../types/display.types";
import { SOCKET_DISPLAY } from "../types/socket.types";
import { actionAlert } from "./action.alert";
import { actionClearModals2 } from "./action.modal";
import handleErrors from "./handleErrors";

export const actionNewDisplay = ({ name, desc, userName, pw }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ name, desc, userName, pw });

  try {
    const res = await axios.post(
      `${proxy}/api/company/display/new-display`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: NEW_DISPLAY,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};

export const actionGetCompanyDisplays = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/display/get-displays`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_DISPLAYS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};

export const actionGetCompanyBasicDisplays = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/display/get-basic-displays`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_DISPLAYS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};

export const actionDBReceivedData = (dbIds) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ dbIds });

  try {
    const res = await axios.put(
      `${proxy}/api/display/received-data`,
      body,
      config
    );

    if (res.status === 200) {
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};

export const actionUpdateDBItems = (newFields) => async (dispatch) => {
  dispatch({ type: UPDATE_DB_ITEMS, payload: newFields });
};

export const actionOnlineDisplays = (displays) => async (dispatch) => {
  dispatch({ type: DISPLAYS_ONLINE, payload: displays });
};

export const actionSortDBItems = () => async (dispatch) => {
  dispatch({ type: SORT_DB_ITEMS });
};
export const actionDbAlerts = ({ dbId, alerts }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ dbId, alerts });

  try {
    const res = await axios.put(
      `${proxy}/api/company/display/alerts`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_DISPLAY,
        payload: res.data.data,
      });

      dispatch({ type: SOCKET_DISPLAY, payload: res.data.socket });

      dispatch(actionClearModals2());
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};

export const actionUpdateDBInfo = (newFields) => async (dispatch) => {
  dispatch({ type: UPDATE_SELECTED_DISPLAY, payload: newFields });
};

export const actionAddDBItems = (data) => async (dispatch) => {
  dispatch({ type: ADD_DB_ITEMS, payload: data });
};

export const actionRemoveDBItem = (data) => async (dispatch) => {
  dispatch({ type: REMOVE_DB_ITEM, payload: data });
};

export const actionDeleteDisplay = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/display/delete-display/${id}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: DELETE_DISPLAY,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};

export const actionUpdateDisplay = ({ dbId, name, desc, userName }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ dbId, name, desc, userName });

  try {
    const res = await axios.put(
      `${proxy}/api/company/display/update-db`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_DISPLAY,
        payload: res.data.data,
      });

      dispatch({ type: SOCKET_DISPLAY, payload: res.data.socket });
      dispatch(actionClearModals2());
      dispatch(actionAlert("Onnistui", "success"));
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};

export const actionForseReloadDb = (id) => async (dispatch) => {
  const payload = {
    event: "DB_UI",
    subEvent: "force_reload",
    displayIds: [id],
  };
  dispatch({ type: SOCKET_DISPLAY, payload: payload });
};
