import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import CustomSpinner from '../CustomSpinner/CustomSpinner'

const PrivateRoute = ({component: Component, socket, isAuth, userGroup, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page

        //isAuth === null palauttaa spinnerin (tulee app.jsx reduxista isAuthenticated)
        //voidaanko luottaa, että isAuth muuttuu aina null arvosta joko arvoon true tai false?? 
        <Route {...rest} render={props => (
            isAuth === null ?
            <CustomSpinner color={'blue'} />
             : isAuth ? <Component {...props} socket={socket}/>
             : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;