import Flatpickr from "react-flatpickr";
import PropTypes from "prop-types";

import "flatpickr/dist/themes/material_green.css";
import "./MyFlatPicker.scss";

import { Finnish } from "flatpickr/dist/l10n/fi.js";
import { Button } from "react-bootstrap";

const MyFlatPicker = ({
  title,
  handleDate,
  date,
  minDate,
  maxDate,
  disabled,
  hideTime,
  hideClear,
  position,
  isStatic,
}) => {
  const clearDate = () => {
    handleDate(null);
  };

  return (
    <div id="picker-div">
      <p>{title || "AIKA"}</p>
      <Flatpickr
        data-enable-time
        value={date}
        onChange={(date) => handleDate(date[0])}
        options={{
          locale: Finnish,
          static: isStatic || false,
          minDate: minDate,
          maxDate: maxDate,
          mode: "single",
          dateFormat: hideTime ? "D d.m.Y" : "D d.m.Y H:i",
          enableTime: !hideTime,
          weekNumbers: true,
          position: position || "auto",
        }}
        disabled={disabled}
      />
      {!hideClear ? (
        <>
          <br />
          <Button variant="link" onClick={clearDate}>
            Tyhjennä
          </Button>
        </>
      ) : null}
    </div>
  );
};

MyFlatPicker.propTypes = {
  title: PropTypes.string.isRequired,
  handleDate: PropTypes.func.isRequired,
  //  date: PropTypes.instanceOf(Date),
  hideTime: PropTypes.bool,
};

MyFlatPicker.defaultProps = {
  date: new Date(),
  minDate: undefined,
  maxDate: undefined,
  disabled: false,
  hideTime: false,
};

export default MyFlatPicker;
