import Axios from "axios";
import React from "react";
import { Button, Container, Form } from "react-bootstrap";
import { withRouter } from "react-router";
import proxy from "../../utils/proxy";

import "./ErrorBoundary.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, desc: "", send: false };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  onChange = (e) =>
    this.setState({ ...this.state, [e.target.name]: e.target.value });

  sendErrorData(error, info) {
    if (error && info) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const info2 = info.toString();
      const error2 = error.toString();
      const desc = this.state.desc;

      const body = JSON.stringify({ error: error2, info: info2, desc });

      Axios.post(`${proxy}/api/send-errors`, body, config)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ ...this.state, send: true });
          }
        })
        .catch((e) => {
          console.error("error", e.response.data.msg);
        });
    }
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Container className="errorBoundary">
          <h2>Jotain meni pieleen.</h2>
          {/* <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>*/}
          <div className="content">
            <p>
              Voit lähettää virhetiedot, mikä auttaa sovelluksen kehityksessä.
            </p>
            <p>
              Emme yksilöi lähetettyjä tietoja millään tavalla, joten lähetys on
              täysin anonyymi
            </p>
          </div>

          {!this.state.send ? (
            <Form>
              <Form.Group>
                <Form.Label>Lisätietoa ongelmasta</Form.Label>
                <Form.Control
                  name="desc"
                  as="textarea"
                  rows="4"
                  onChange={this.onChange}
                  placeholder="esim. mitä olit tekemässä, millä sivulla, millä tunnuksilla"
                />
              </Form.Group>

              <Button
                block
                className="primaryBtn"
                onClick={() =>
                  this.sendErrorData(this.state.error, this.state.errorInfo)
                }
              >
                Lähetä
              </Button>

              <Button
                variant="link"
                onClick={() => {
                  this.props.history.push("/");
                  window.location.reload();
                }}
              >
                Päävalikkoon
              </Button>

              <Button
                variant="link"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Päivitä sivu
              </Button>
            </Form>
          ) : (
            <div className="thanks">
              <p>Kiitämme lämpimästi yhteistyöstäsi.</p>
              <p>
                Kiireellisessä tapauksessa voit ottaa yhteyttä kotisivujemme
                kautta <a href="https://jaretech.fi">Kotisivuille</a>.
              </p>
              <p>Kotisivuilta löydät myös muut yhteystietomme!</p>
              <Button
                className="secondaryBtn"
                onClick={() => {
                  this.props.history.push("/");
                  window.location.reload();
                }}
              >
                Päävalikkoon
              </Button>
            </div>
          )}
        </Container>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
