import { useState } from "react";
import { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AutoSizeTextArea from "../../../components/AutoSizeTextArea/AutoSizeTextArea";
import LinkUserMsgCard from "../../../components/LinkUser/Cards/LinkUserMsgCard";
import {
  actionGetTokenChatAndMessages,
  actionLinkClearSelected,
  actionSendTokenMsg,
} from "../../../redux/actions/action.links";
import { actionAlert } from "../../../redux/actions/action.alert";
import useQueryParam from "../../../utils/useQueryParam";

import CustomSpinner2 from "../../../components/CustomSpinner/CustomSpinner2";

import "../LinkUser.scss";

const LinkUserChat = () => {
  const [token] = useQueryParam("token");
  const { chatId } = useParams();
  const dispatch = useDispatch();

  const { selected, loadingSelected } = useSelector((state) => state.linkUser);

  const [states, setStates] = useState({
    message: "",
    sending: false,
  });
  const { message, sending } = states;

  useEffect(() => {
    dispatch(actionGetTokenChatAndMessages({ token: token, chatId: chatId }));

    return () => {
      // thjennetään chatin tiedot ja viestit
      dispatch(actionLinkClearSelected());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const sendMsg = () => {
    setStates((state) => ({ ...state, sending: true }));

    const content = {
      token: token,
      chatId: chatId,
      message,
    };

    dispatch(actionSendTokenMsg(content)).then((success) => {
      if (success) {
        setStates((state) => ({ ...state, message: "", sending: false }));
        dispatch(actionAlert("Onnistui", "success"));
      } else {
        dispatch(actionAlert("Epännistui", "danger"));
        setStates((state) => ({ ...state, sending: false }));
      }
    });
  };

  const newMsgForm = (
    <div className="sendForm">
      <AutoSizeTextArea
        label="VIESTI"
        name="message"
        onChange={onChange}
        value={message}
      />

      <Button
        variant="link"
        onClick={sendMsg}
        disabled={!message.length || loadingSelected}
      >
        {sending ? <Spinner animation="grow" /> : "Lähetä"}
      </Button>
    </div>
  );

  if (!loadingSelected && !selected?.chatRoom) {
    return (
      <div className="basicPage">
        <p>Viestiryhmää ei löytynyt</p>
      </div>
    );
  }

  return (
    <div className="basicPage LinkUserChat">
      <h2>{selected?.chatRoom?.title || "CHAT"}</h2>
      <p>Chat ei toistaiseksi päivity livenä!</p>

      {loadingSelected ? <CustomSpinner2 /> : null}

      {newMsgForm}

      <div className="mappedMessages">
        {selected?.messages?.map((el, i) => (
          <LinkUserMsgCard data={el} key={i} />
        ))}
      </div>
    </div>
  );
};

export default LinkUserChat;
