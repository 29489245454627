export const SET_SOCKET = "SET_SOCKET";
export const SOCKET_ITEM = "SOCKET_ITEM";
export const SOCKET_CHAT = "SOCKET_CHAT";
export const SOCKET_BASIC = "SOCKET_BASIC";

export const SOCKET_NEED_LOG_OUT = "SOCKET_NEED_LOG_OUT";

export const SOCKET_INITIALSTATES = "SOCKET_INITIALSTATES";

export const SOCKET_DISPLAY = "SOCKET_DISPLAY";
