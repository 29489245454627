import { SET_MODALS_2, CLEAR_MODALS_2 } from "./types";

export const actionSetModals2 = ({ modalType, title, text, data }) => (
  dispatch
) => {
  dispatch({
    type: SET_MODALS_2,
    payload: {
      modalType,
      title,
      text,
      data,
    },
  });
};

// Tyhjennä modaali sovelluksen muistista
export const actionClearModals2 = () => (dispatch) => {
  dispatch({
    type: CLEAR_MODALS_2,
  });
};
