import { v4 as uuidv4 } from 'uuid'
import { SET_ALERT, REMOVE_ALERT, CLEAR_ALERTS } from './types'

// Käynnistä alert (huomio)
export const actionAlert = (msg, alertType) => dispatch => {
    const id = uuidv4()
    dispatch({
        type: SET_ALERT,
        payload: { msg, alertType, id }
    })

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 8000)

}

// Poista alert (huomio)
export const actionRemoveAlert = (id) => dispatch => {

    dispatch({
        type: REMOVE_ALERT,
        payload: id
    })

}

// Tyhjennä alert muisti
export const actionClearAlerts = () => dispatch => {

    dispatch({
        type: CLEAR_ALERTS
    })

}