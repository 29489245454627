import { CUSTOMER_CLEAR, CUSTOMER_LOAD_COMPANY } from "../actions/types";
import {
  GET_COMPANIES_LIST,
  UPDATE_MY_CUSTOMER_COMPANY,
} from "../types/customer.types";

const initialState = {
  myCustomerCompany: {},
  loadingMy: true,

  companiesList: null,
  loadingCompanies: true,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CUSTOMER_LOAD_COMPANY:
      return {
        ...state,
        myCustomerCompany: payload,
        loadingMy: false,
      };
    case GET_COMPANIES_LIST:
      return {
        ...state,
        loadingCompanies: false,
        companiesList: payload,
      };
    case UPDATE_MY_CUSTOMER_COMPANY:
      return {
        ...state,
        myCustomerCompany: {
          ...state.myCustomerCompany,
          ...payload,
        },
      };
    case CUSTOMER_CLEAR:
      return initialState;

    default:
      return state;
  }
}
