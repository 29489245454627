import { useEffect, useRef, useState } from "react";

const initialState = {
  min: 0,
  sec: 0,
  initRecording: false,
  stream: null,
  recorder: null,
  audioUrl: null,
  audioFile: null,
};
const useRecorder = () => {
  const [recordStates, setRecordStates] = useState(initialState);
  const _isMounted = useRef(true);

  useEffect(() => {
    // minutes
    const maxLength = 5;
    let interval = null;

    if (recordStates.initRecording) {
      interval = setInterval(() => {
        setRecordStates((prevState) => {
          if (prevState.min === maxLength && prevState.sec === 0) {
            clearInterval(interval);
            return prevState;
          }

          if (prevState.sec >= 0 && prevState.sec < 59) {
            return {
              ...prevState,
              sec: prevState.sec + 1,
            };
          }

          if (prevState.sec === 59) {
            return {
              ...prevState,
              min: prevState.min + 1,
              sec: 0,
            };
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (recordStates.stream) {
      setRecordStates((prevState) => {
        return {
          ...prevState,
          recorder: new MediaRecorder(prevState.stream),
        };
      });
    }
  }, [recordStates.stream]);

  useEffect(() => {
    return () => (_isMounted.current = false);
  }, []);

  useEffect(() => {
    const recorder = recordStates.recorder;
    let chunks = [];

    if (recorder && recorder.state === "inactive") {
      recorder.start();

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm; codecs=opus" });
        chunks = [];

        if (_isMounted.current) {
          setRecordStates((prevStates) => {
            if (prevStates.recorder) {
              return {
                ...initialState,
                audioUrl: window.URL.createObjectURL(blob),
                audioFile: blob,
              };
            } else return initialState;
          });
        }
      };
    }

    return () => {
      if (recorder)
        recorder.stream.getAudioTracks().forEach((track) => track.stop());
    };
  }, [recordStates.recorder]);

  const startRecording = async () => {
    if (recordStates.audioFile) {
      setRecordStates(initialState);
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      setRecordStates((prevState) => {
        return {
          ...prevState,
          initRecording: true,
          stream: stream,
        };
      });
    } catch (e) {
      console.error(e.message);
    }
  };

  const stopRecording = () => {
    const recorder = recordStates.recorder;
    if (recorder.state !== "inactive") {
      recorder.stop();
    }
  };

  return {
    recordStates,
    startRecording: () => startRecording(),
    cancelRecording: () => setRecordStates(initialState),
    stopRecording: () => stopRecording(),
  };
};
export default useRecorder;
