import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetMsgBoardById,
  actionNewBoardItem,
  actionToggleParticipate,
  actionToggleShowOnDb,
  actionUpdateBoardItem,
} from "../../redux/actions/action.messageboard";
import { useParams, useHistory } from "react-router";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";

import { useFormik } from "formik";
import * as Yup from "yup";
import AutoSizeTextArea from "../../components/AutoSizeTextArea/AutoSizeTextArea";
import { Button, Form, Spinner } from "react-bootstrap";
import { dateWithDayNameAndTime } from "../../utils/toDates";

import MyFlatPicker from "../../components/MyDatePicker/MyFlatPicker";
import { actionSetModals2 } from "../../redux/actions/action.modal";
import { Link } from "react-router-dom";

import "./MessageBoard.scss";
import SelectCustomerCompany from "../../components/Select/Company/SelectCustomerCompany";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconFail, iconSuccess } from "../../components/Icons/Icons";
import { actionSetSession } from "../../redux/actions/action.session";

const OneMessageBoard = () => {
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const history = useHistory();
  const { selectedBoard, loadingSelected } = useSelector(
    (state) => state.messageBoard
  );
  const { onlineDisplays } = useSelector((state) => state.display);

  const [states, setStates] = useState({
    disableEdit: boardId !== "new",
    submitting: false,
  });
  const { disableEdit, submitting } = states;

  const statusOptions = [
    { label: "-", value: 0 },
    { label: "Vihreä", value: 1 },
    { label: "Keltainen", value: 2 },
    { label: "Punainen", value: 3 },
  ];

  useEffect(() => {
    dispatch(actionSetSession("", "/message-boards", true));

    if (boardId && boardId !== "new") {
      dispatch(actionGetMsgBoardById(boardId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId]);

  const formik = useFormik({
    initialValues: {
      title: selectedBoard?.title || "",
      description: selectedBoard?.description || "",
      start: selectedBoard?.start || null,
      stop: selectedBoard?.stop || null,
      allCanEdit: selectedBoard?.allCanEdit || false,
      askToJoin: selectedBoard?.askToJoin || false,
      status: selectedBoard?.status || 0,
      customerCompany: selectedBoard?.customer,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Pakollinen tieto"),
    }),
    onSubmit: (values) => {
      setStates((state) => ({ ...state, submitting: true }));

      let customer;
      if (values.customerCompany) {
        customer = {
          _id: values.customerCompany._id,
          name: values.customerCompany.name,
        };
      }

      const content = {
        ...values,
        customer,
      };

      if (boardId !== "new") {
        content.boardId = boardId;

        dispatch(actionUpdateBoardItem(content)).then((success) => {
          if (success) {
            setStates((state) => ({ ...state, disableEdit: true }));
          }
          setStates((state) => ({ ...state, submitting: false }));
        });
      } else {
        dispatch(actionNewBoardItem(content)).then((data) => {
          if (data) {
            setStates((state) => ({ ...state, disableEdit: true }));
            history.replace(`/company/one-board/${data._id}`);
          }

          setStates((state) => ({ ...state, submitting: false }));
        });
      }
    },
  });
  const linkDisplay = () => {
    dispatch(
      actionSetModals2({
        modalType: 401,
        data: {
          boardId: boardId,
          displays: selectedBoard.displays,
        },
        title: "Linkitä näyttö",
      })
    );
  };

  const toggleParticipate = () => {
    dispatch(actionToggleParticipate({ boardId: selectedBoard._id }));
  };

  const toggleEdit = () => {
    if (!disableEdit) {
      formik.resetForm();
    }
    setStates((state) => ({ ...state, disableEdit: !state.disableEdit }));
  };

  const handleDate = (name, date) => {
    formik.setFieldValue(name, date);
  };

  const handleCheck = (e) => {
    formik.setFieldValue(e.target.name, e.target.checked);
  };
  const handleSelectFormik = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const toggleShowOnDb = () => {
    dispatch(actionToggleShowOnDb({ boardId: boardId }));
  };

  const selectCustomer = (e) => {
    const res = e ? e : undefined;

    formik.setFieldValue("customerCompany", res);
  };

  if (loadingSelected && boardId !== "new") {
    return <CustomSpinner />;
  }

  const readForm = selectedBoard && (
    <div>
      <Form.Group className="switches">
        <Form.Check
          type="switch"
          id="allCanEdit"
          onChange={handleCheck}
          name="allCanEdit"
          label="Kaikki voi muokata"
          checked={formik.values.allCanEdit}
          disabled
        />
        <Form.Check
          type="switch"
          id="askToJoin"
          onChange={handleCheck}
          name="askToJoin"
          label="Ilmoittautuminen"
          checked={formik.values.askToJoin}
          disabled
        />
      </Form.Group>
      <div className="dates">
        <p>
          {selectedBoard.start
            ? dateWithDayNameAndTime(selectedBoard.start)
            : "-:-"}
        </p>
        -
        <p>
          {selectedBoard.stop
            ? dateWithDayNameAndTime(selectedBoard.stop)
            : "-:-"}
        </p>
      </div>

      <div className="subTitleDiv">
        <h5>OTSIKKO JA TILA</h5>
        <div className="divider" />
      </div>

      <h2>{selectedBoard.title}</h2>

      <p>
        <strong>Tila: {selectedBoard.status}</strong>
      </p>

      <div className="subTitleDiv">
        <h5>KUVAUS</h5>
        <div className="divider" />
      </div>

      <p className="p-desc">{selectedBoard.description}</p>

      <div className="subTitleDiv">
        <h5>ASIAKAS</h5>
        <div className="divider" />
      </div>

      <p>{selectedBoard.customer ? selectedBoard.customer.name : "-"}</p>

      <Form.Group>
        <div className="subTitleDiv">
          <h5>OSALLISTUJAT</h5>
          <div className="divider" />
        </div>
        <div>
          {selectedBoard.participants?.map((el, i) => (
            <p key={i}>- {el.name}</p>
          ))}
        </div>
      </Form.Group>

      <hr />

      <div className="subTitleDiv">
        <h5>Näytöt: {selectedBoard.displays?.length || 0}</h5>
        <div className="divider" />
      </div>

      {selectedBoard.displays?.map((el, i) => (
        <p key={i}>
          <strong>
            {el.name}{" "}
            {onlineDisplays[el._id] ? (
              <>
                <FontAwesomeIcon icon={iconSuccess} className="iconSuccess" /> (
                {onlineDisplays[el._id].length})
              </>
            ) : (
              <FontAwesomeIcon icon={iconFail} className="iconFail" />
            )}
          </strong>
        </p>
      ))}
      <Link to="/displays">Siirry näyttöihin</Link>
    </div>
  );

  const editForm = (
    <div>
      <Form.Group className="switches">
        <Form.Check
          type="switch"
          id="allCanEdit"
          onChange={handleCheck}
          name="allCanEdit"
          label="Kaikki voi muokata"
          checked={formik.values.allCanEdit}
        />
        <Form.Check
          type="switch"
          id="askToJoin"
          onChange={handleCheck}
          name="askToJoin"
          label="Ilmoittautuminen"
          checked={formik.values.askToJoin}
        />
      </Form.Group>
      <Form.Group className="datePickers">
        <MyFlatPicker
          date={formik.values.start}
          handleDate={(date) => handleDate("start", date)}
          title="Alkaa"
          // maxDate={new Date()}
        />
        <MyFlatPicker
          date={formik.values.stop}
          handleDate={(date) => handleDate("stop", date)}
          title="Loppuu"
          //    maxDate={new Date()}
        />
      </Form.Group>

      <div className="subTitleDiv">
        <h5>OTSIKKO JA TILA</h5>
        <div className="divider" />
      </div>

      <Form.Group>
        <Form.Label>Otsikko</Form.Label>
        <Form.Control
          name="title"
          onChange={formik.handleChange}
          isInvalid={formik.touched.title && formik.errors.title}
          onBlur={formik.handleBlur}
          value={formik.values.title || ""}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.title}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Tila</Form.Label>
        <Form.Control
          name="status"
          as="select"
          onChange={handleSelectFormik}
          value={formik.values.status}
        >
          {statusOptions.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <div className="subTitleDiv">
        <h5>KUVAUS</h5>
        <div className="divider" />
      </div>

      <AutoSizeTextArea
        label="Kuvaus"
        name="description"
        onChange={formik.handleChange}
        isInvalid={formik.touched.description && formik.errors.description}
        onBlur={formik.handleBlur}
        value={formik.values.description || ""}
        formikError={formik.errors.description}
        disabled={disableEdit}
      />

      <div className="subTitleDiv">
        <h5>ASIAKAS</h5>
        <div className="divider" />
      </div>
      <Form.Group>
        <SelectCustomerCompany
          selectCustomer={selectCustomer}
          selected={formik.values.customerCompany}
        />
      </Form.Group>

      {submitting ? (
        <Spinner animation="grow" />
      ) : (
        <Button
          variant="link"
          className="outLine-pink"
          onClick={formik.handleSubmit}
        >
          Tallenna
        </Button>
      )}
    </div>
  );

  return (
    <div className="OneMessageBoard basicPage">
      {selectedBoard && (
        <div className="buttons">
          <Button variant="link" className="outLine-pink" onClick={toggleEdit}>
            {disableEdit ? "Muokkaa" : "Peruuta"}
          </Button>
          {selectedBoard.askToJoin && (
            <Button
              variant="link"
              className="outLine-pink"
              onClick={toggleParticipate}
            >
              {selectedBoard.participated
                ? "Peruuta osallistuminen"
                : "Osallistu"}
            </Button>
          )}

          <Button variant="link" className="outLine-pink" onClick={linkDisplay}>
            Linkitä näyttö
          </Button>
          {selectedBoard.displays?.length ? (
            <Button
              variant="link"
              className="outLine-pink"
              onClick={toggleShowOnDb}
            >
              {selectedBoard.showOnDisplay
                ? "Piilota näytöltä"
                : "Näytä näytöllä"}
            </Button>
          ) : null}
        </div>
      )}

      <br />

      <div className="forms">{disableEdit ? readForm : editForm}</div>
    </div>
  );
};

export default OneMessageBoard;
