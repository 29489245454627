import React, { memo } from "react";
import { Route, Redirect } from "react-router-dom";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import NoAccess from "../NoAccess/NoAccess";

const PrivateCompanyRoute = ({
  component: Component,
  isAuth,
  userGroup,
  usePermissions,
  pagePermissions,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth === null ? (
          <CustomSpinner color="blue" />
        ) : isAuth && userGroup < 11 ? (
          // tarkistetaanko oikeudet
          usePermissions ? (
            // tietyn sivun oikeudet (app.jsx props)
            pagePermissions && pagePermissions.includes("READ") ? (
              <Component {...props} pagePermissions={pagePermissions} />
            ) : (
              <NoAccess userGroup={userGroup} />
            )
          ) : (
            <Component {...props} pagePermissions={"notInUse"} />
          )
        ) : isAuth && userGroup > 10 ? (
          <Redirect to="/" />
        ) : isAuth ? (
          <NoAccess userGroup={userGroup} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default memo(PrivateCompanyRoute);
