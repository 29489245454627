import React from "react";
import './NoResults.scss'

const NoResults = ({ text }) => {
  return (
    <div className="noItems boxShadow">
      <p>{text}</p>
    </div>
  );
};

export default NoResults;
