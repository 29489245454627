import { CLEAR_MODALS_2, SET_MODALS_2 } from "../actions/types";

const initialState = {
  modalType: null,
  title: "",
  text: "",
  data: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MODALS_2:
      return {
        modalType: payload.modalType,
        title: payload.title,
        text: payload.text,
        data: payload.data,
      };
    case CLEAR_MODALS_2:
      return initialState;

    default:
      return state;
  }
}
