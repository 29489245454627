import axios from 'axios'

const setAuthToken = token => {
  if (token) {
    // token http kutsujen headereihin ja selaimen paikalliseen muistiin
    axios.defaults.headers.common['x-auth-token'] = token
    localStorage.setItem('token', token)
  } else {
    // poistetaan token headereista ja paikallisesta muistista
    delete axios.defaults.headers.common['x-auth-token']
    localStorage.removeItem('token')
  }
}

export default setAuthToken