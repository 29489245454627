import {
  ITEM_USER_ITEMS,
  ITEM_GET_BY_ID,
  ITEM_SELECT,
  ITEM_CLEAR_SELECTED_ITEM,
  ITEM_INITIAL_STATES,
  ITEM_UPDATE,
  ITEM_NEW,
  ITEM_UPDATE_TAGS,
  ITEM_SET_VALID_FILES,
  ITEM_UPDATE_FAVORITES,
  ITEM_ADD_DATA,
  ITEM_SET_ITEMS_LOADING,
  ITEM_SET_SUBMITTING,
  ITEM_COUNTS,
  ITEM_DELETE,
  NEED_TO_LOAD_ITEMS,
  ITEMS_UPDATE,
  UPDATE_ITEM_TODOS,
  UPDATE_ITEMS_TODOS,
  GET_MY_ITEM_SUMMARY,
  SET_MY_ITEM_SUMMARY,
  UPDATE_MY_ITEMS_TODOS,
  UPDATE_COMBINED_ITEMS_TODO,
  ITEM_COPY,
  SET_ITEM_REDIRECT_URL,
} from "../types/item.types";

const initialState = {
  selectedItem: null,
  validFiles: null,
  userItems: [],

  myItems: null,
  totalCount: null,
  // myItems sivun valittu filteri
  myItemsPage: 1,

  loadingItem: true,
  loadingItems: true,
  loadingContacts: true,

  itemCounts: {},

  favoriteItems: {},
  submitting: {},

  needToLoadItems: true,
  loadingMyItems: true,

  itemRedirectUrl: undefined,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ITEM_GET_BY_ID:
      return {
        ...state,
        selectedItem: payload,
        loadingItem: false,
      };

    case ITEM_SELECT:
      return {
        ...state,
        selectedItem: payload,
        loadingItem: false,
      };

    case ITEM_USER_ITEMS:
      return {
        ...state,
        loadingItems: false,
        userItems: payload.userItems,
        totalCount: payload.totalCount,
        favoriteItems: payload.favoriteItems,
        myItemsPage: payload.myItemsPage,
        needToLoadItems: false,
      };

    case ITEM_CLEAR_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: null,
        validFiles: null,
        loadingItem: true,
      };

    case ITEM_NEW:
      return {
        ...state,
        userItems: [payload.data, ...state.userItems],
      };

    case ITEM_UPDATE:
      return {
        ...state,
        selectedItem: { ...state.selectedItem, ...payload.data },
        submitting: {
          [payload.event]: false,
        },
      };

    case ITEM_ADD_DATA:
      return {
        ...state,
        loadingContacts: false,
        selectedItem: { ...state.selectedItem, ...payload.data },
      };

    case ITEM_UPDATE_TAGS:
      return {
        ...state,
        userItems: state.userItems.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };
    case ITEM_SET_VALID_FILES:
      return {
        ...state,
        validFiles: payload,
      };
    case ITEM_UPDATE_FAVORITES:
      return {
        ...state,
        userItems: state.userItems.map((el) =>
          el._id === payload.id ? { ...el, ...payload.data } : el
        ),

        favoriteItems: {
          ...state.favoriteItems,
          [payload.itemId]: payload.value,
        },
      };
    case ITEM_INITIAL_STATES:
      return initialState;

    case ITEM_SET_ITEMS_LOADING:
      return {
        ...state,
        loadingItems: payload.loading,
        userItems: payload.items,
      };
    case ITEM_SET_SUBMITTING:
      return {
        ...state,
        submitting: {
          [payload.event]: payload.status,
        },
      };

    case ITEM_COUNTS:
      return {
        ...state,
        itemCounts: payload,
      };

    case ITEM_DELETE:
      return {
        ...state,
        userItems: state.userItems?.filter((el) => el._id !== payload.id),
      };

    case NEED_TO_LOAD_ITEMS:
      return {
        ...state,
        needToLoadItems: payload,
      };

    case ITEMS_UPDATE:
      return {
        ...state,
        userItems: state.userItems.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };

    case UPDATE_ITEM_TODOS:
      return {
        ...state,
        selectedItem: { ...state.selectedItem, todos: payload },
      };

    case UPDATE_ITEMS_TODOS:
      return {
        ...state,
        userItems: state.userItems.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };

    case GET_MY_ITEM_SUMMARY:
      return {
        ...state,
        loadingMyItems: false,
        myItems: payload,
      };
    case SET_MY_ITEM_SUMMARY:
      return {
        ...state,
        ...payload,
      };

    case UPDATE_MY_ITEMS_TODOS:
      return {
        ...state,
        myItems: state.myItems.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };

    case UPDATE_COMBINED_ITEMS_TODO:
      return {
        ...state,
        myItems: [
          {
            ...state.myItems[0],
            todos: [
              ...state.myItems[0].todos.map((el) =>
                el._id === payload.todoId ? { ...el, ...payload } : el
              ),
            ],
          },
        ],
      };

    case ITEM_COPY:
      return {
        ...state,
        userItems: [payload, ...state.userItems],
      };

    case SET_ITEM_REDIRECT_URL:
      return {
        ...state,
        itemRedirectUrl: payload,
      };

    default:
      return state;
  }
}
