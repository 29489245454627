import { useEffect, useState } from "react";
import { Button, Collapse, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AutoSizeTextArea from "../../components/AutoSizeTextArea/AutoSizeTextArea";
import { actionAlert } from "../../redux/actions/action.alert";
import {
  actionChatRoomInitialStates,
  actionGetCompanyChatMessages,
  actionSendMessageToCOmpanyChat,
} from "../../redux/actions/action.chatRoom";
import { actionSetSession } from "../../redux/actions/action.session";
import { dateWithDayNameAndTime } from "../../utils/toDates";

import { Link, Redirect } from "react-router-dom";

import "./ChatRooms.scss";

const CompanyChat = () => {
  const dispatch = useDispatch();

  const { roomMessages, loadingMessages } = useSelector(
    (state) => state.chatRoom
  );

  const { _id: myId, userGroup } = useSelector((state) => state.auth.user);

  const [states, setStates] = useState({
    message: "",
    page: 0,
    loadMore: undefined,
    isMore: true,
    sending: false,
    msgId: undefined,
  });
  const { message, page, isMore, sending, msgId } = states;

  useEffect(() => {
    dispatch(actionSetSession(``, "/", true));

    dispatch(actionGetCompanyChatMessages());

    return () => {
      dispatch(actionChatRoomInitialStates());
    };
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const selectMsg = (msgId) => {
    setStates((state) => ({ ...state, msgId: msgId }));
  };

  const sendMsg = () => {
    const content = {
      message: message,
    };

    setStates((state) => ({ ...state, sending: true }));

    dispatch(actionSendMessageToCOmpanyChat(content)).then((success) => {
      if (success) {
        setStates((state) => ({ ...state, message: "", sending: false }));
      }
    });
  };

  const loadMore = () => {
    const pageNow = page + 1;

    setStates((state) => ({
      ...state,
      loadMore: true,
      page: pageNow,
    }));

    dispatch(actionGetCompanyChatMessages(pageNow)).then((data) => {
      if (data.loaded < data.limit) {
        dispatch(actionAlert("Ei lisää", "warning"));
        setStates((state) => ({
          ...state,
          isMore: false,
        }));
      }

      setStates((state) => ({
        ...state,
        loadMore: false,
      }));
    });
  };

  const msgBox = (msg, i) => {
    const myMsgStyle = msg.author.id === myId ? "myMsgStyle" : "";

    const readByMe = msg.readBy.some((el) => el.readByUserId === myId);
    const newForMeStyle = !readByMe ? "newForMeStyle" : "";

    return (
      <div
        className={`msgBox ${myMsgStyle} ${newForMeStyle}`}
        key={i}
        onClick={() => selectMsg(msg._id)}
      >
        <div className="header">
          <p className="p-author">{msg.author?.name}</p>
          <p className="p-author">{dateWithDayNameAndTime(msg.createdAt)}</p>
        </div>

        <div className="content">
          <p>{msg.message}</p>
        </div>

        <Collapse in={msgId === msg._id}>
          <div>
            <div className="readByDiv">
              <p>LUETTU:</p>
              {msg.readBy.map((el, i) => (
                <div className="readByBox" key={i}>
                  <p className="p-date">{dateWithDayNameAndTime(el.readAt)}</p>
                  <p className="p-name">{el.readByUserName}</p>
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  const newMessage = (
    <div className="newMsgDiv">
      <AutoSizeTextArea
        readOnly={false}
        label="Viesti"
        onChange={onChange}
        onBlur={null}
        isInvalid={false}
        value={message}
        formikError={""}
        name="message"
      />
      {sending ? (
        <Spinner animation="grow" />
      ) : (
        <Button variant="link" className="outLine-pink" onClick={sendMsg} disabled={!message.length}>
          Lähetä
        </Button>
      )}
    </div>
  );

  if (userGroup > 10) {
    return <Redirect to="/" />;
  }
  return (
    <div className="basicPage OneRoom">
      <h2>Yrityksen chat</h2>
      <Link to="/chat/rooms">Chat ryhmät </Link>

      <hr />
      {loadingMessages ? (
        <Spinner animation="border" />
      ) : (
        <>
          {newMessage}
          <hr />

          <div className="messages">
            {roomMessages.map((el, i) => msgBox(el, i))}
          </div>

          <hr />

          <h4>Uusin viesti on ylimpänä!</h4>

          {roomMessages.length > 5 && (
            <Button
              variant="link"
              className="outLine-grey"
              onClick={loadMore}
              disabled={!isMore}
            >
              Lisää viestejä
            </Button>
          )}
          {!isMore && <Form.Text>Ei enempää viestejä</Form.Text>}
        </>
      )}
    </div>
  );
};

export default CompanyChat;
