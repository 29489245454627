import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import {
  SimpleCostsTable,
  SimplePartsTable,
} from "../../../components/Costs/CostsTables";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { actionConsumerGetItemReportById } from "../../../redux/actions/action.consumer";
import { actionSetSession } from "../../../redux/actions/action.session";

import "../OneReport.scss";

export default function OneConsumerReport() {
  const { id, reportId } = useParams();
  const dispatch = useDispatch();
  const { selectedReport, loadingReport } = useSelector(
    (state) => state.consumer
  );

  const [finalReport, setFinalReport] = useState(null);

  useEffect(() => {
    dispatch(actionSetSession("Raportti", `/consumer/item/${id}/reports`, false, 5));

    if (!selectedReport) {
      dispatch(actionConsumerGetItemReportById(reportId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedReport) {
      setFinalReport(selectedReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  if (!loadingReport && !selectedReport) {
    return <Redirect to={`/item/${id}`} />;
  }

  if (loadingReport || !finalReport) {
    return <CustomSpinner color="blue" />;
  }

  return (
    <div className="BasicReport OneCustomerReport ">
      <h1>{finalReport.title || "Raportin tiedot"}</h1>

      <div className="item">
        <div className="header">
          <p className="p-title">TIEDOT</p>
          <div className="divider" />
        </div>
        <div className="content">
          <p className="p-name">Otsikko</p>
          <Form.Control
            value={finalReport.title}
            readOnly
            className="shadow-none"
          />

          <p className="p-name">Kuvaus yritykseltä</p>

          <Form.Control
            as="textarea"
            rows="5"
            value={finalReport.workDesc}
            readOnly
            className="shadow-none"
          />

          <p className="p-name">Alkuperäinen kuvaus</p>
          <Form.Control
            as="textarea"
            rows="5"
            value={finalReport.custWorkDesc}
            readOnly
            className="shadow-none"
          />
        </div>
      </div>

      <div className="item">
        <div className="header">
          <p className="p-title">KULUT</p>
          <div className="divider" />
        </div>
        <SimpleCostsTable
          costs={finalReport.costs}
          customer={true}
          showPrices={finalReport.showPrices}
        />
      </div>

      <div className="item">
        <div className="header">
          <p className="p-title">TUOTTEET</p>
          <div className="divider" />
        </div>
        <SimplePartsTable
          parts={finalReport.parts}
          showPrices={finalReport.showPrices}
        />
      </div>

      <div className="item">
        <div className="header">
          <p className="p-title">MUUTA</p>
          <div className="divider" />
        </div>
        <p className="p-name">Takuu (kk)</p>

        <Form.Control
          value={finalReport.warranty}
          readOnly
          className="shadow-none"
        />
        <p className="p-name">Alennus (e)</p>
        <Form.Control
          value={finalReport.discount}
          readOnly
          className="shadow-none"
        />
      </div>
      {finalReport.showPrices && (
        <div className="item">
          <div className="header">
            <p className="p-title">HINNAT</p>
            <div className="divider" />
          </div>

          <div className="prices">
            <p>
              TUOTTEET: {finalReport.prices && finalReport.prices.partsPrice}
            </p>
            <p>
              KULUT:{" "}
              {finalReport.prices &&
                finalReport.prices.costs &&
                finalReport.prices.costs.total}
            </p>
            <p>
              YHTEENSÄ: {finalReport.prices && finalReport.prices.totalPrice}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
