import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionSetSession } from "../../redux/actions/action.session";
import MessageBoardCompany from "./MessageBoardsCompany";
import MessageBoardsCustomer from "./MessageBoardsCustomer";

const MessageBoards = () => {
  const { userGroup } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionSetSession("", "/", true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (userGroup < 11) {
    return <MessageBoardCompany />;
  } else if (userGroup > 10) {
    return <MessageBoardsCustomer />;
  } else {
    return null;
  }
};

export default MessageBoards;
