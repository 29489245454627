export const VALIDATE_LINK_USER = "VALIDATE_LINK_USER";
export const VALIDATE_LINK_USER_FAIL = "VALIDATE_LINK_USER_FAIL";

export const GET_TOKEN_ITEMS = "GET_TOKEN_ITEMS";
export const GET_TOKEN_CHATS = "GET_TOKEN_CHATS";

export const SELECT_TOKEN_CHATS = "SELECT_TOKEN_CHATS";

export const SEND_TOKEN_MSG = "SEND_TOKEN_MSG";

export const CLEAR_LINK_SELECTED = "CLEAR_LINK_SELECTED";

export const GET_ITEM_IMAGES = "GET_ITEM_IMAGES";
