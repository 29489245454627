import { Button, Form } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import "./Register.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import scrollToFirstError from "../../utils/scrollToFirstError";
import { actionCompanyFirstRegitration } from "../../redux/actions/action.auth";

const RegisterCompanyAndAdmin = () => {
  const dispatch = useDispatch();
  const { registerSuccess } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",

      address: "",
      city: "",
      zip: "",

      accounts: 5,

      firstName: "",
      lastName: "",
      email2: "",
      phone2: "",

      address2: "",
      city2: "",
      zip2: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Pakollinen tieto"),
      email: Yup.string().required("Pakollinen tieto").email("Ei kelpaa"),
      city: Yup.string().required("Pakollinen tieto"),
      phone: Yup.string()
        .required("Pakollinen tieto")
        .matches(/^[0-9]+$/, "Pelkästään numerot sallittu")
        .min(5, "Pitää olla 5 numeroa")
        .max(12, "Pitää olla 5 numeroa"),

      zip: Yup.string()
        .matches(/^[0-9]+$/, "Pelkästään numerot sallittu")
        .min(5, "Pitää olla 5 numeroa")
        .max(5, "Pitää olla 5 numeroa"),

      // accounts: Yup.string().required("Pakollinen tieto"),

      firstName: Yup.string().required("Pakollinen tieto"),
      lastName: Yup.string().required("Pakollinen tieto"),

      email2: Yup.string().required("Pakollinen tieto").email("Ei kelpaa"),

      zip2: Yup.string()
        .matches(/^[0-9]+$/, "Pelkästään numerot sallittu")
        .min(5, "Pitää olla 5 numeroa")
        .max(5, "Pitää olla 5 numeroa"),
    }),
    onSubmit: (values) => {
      const company = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        address: values.address,
        city: values.city,
        zip: values.zip,
        accounts: 5,
      };

      const user = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email2,
        phone: values.phone2,
        address: values.address2,
        city: values.city2,
        zip: values.zip2,
      };

      const content = {
        company,
        user,
      };

      dispatch(actionCompanyFirstRegitration(content));
    },
  });

  useEffect(() => {
    scrollToFirstError(formik);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting, formik.errors]);

  if (registerSuccess) {
    return (
      <div className="basicPage RegisterCompanyAndAdmin">
        <div className="successDivMain ">
          <div className="successDiv boxShadow">
            <div className="header">
              <h2>Tunnuksen luonti onnistui</h2>
            </div>

            <div className="content2">
              <p>
                Oman tunnuksesi sähköpostiin ({formik.values.email2}) on
                lähetetty vahvistusviesti!
              </p>
              <p>
                Viestin kautta pääset asettamaan tunnuksellesi salasanan ja
                varmistamaan tietosi
              </p>

              <p>
                <strong>
                  HUOM! Sähköpostin linkki on voimassa 7 päivää, joten
                  vahvistathan tunnuksesi ennen sitä!
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="basicPage RegisterCompanyAndAdmin">
      <Form className="forms formCompany boxShadow">
        <h2>YRITYKSEN TIEDOT</h2>
        <Form.Group>
          <Form.Label>Yrityksen nimi *</Form.Label>
          <Form.Control
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            isInvalid={formik.touched.name && formik.errors.name}
            onBlur={formik.handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="flex">
          <Form.Group className="flex-1">
            <Form.Label>Sähköposti *</Form.Label>
            <Form.Control
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              isInvalid={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="flex-1">
            <Form.Label>Puhelin</Form.Label>
            <Form.Control
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              isInvalid={formik.touched.phone && formik.errors.phone}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <div className="flex">
          <Form.Group className="flex-3">
            <Form.Label>Osoite</Form.Label>
            <Form.Control
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              isInvalid={formik.touched.address && formik.errors.address}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.address}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="flex-2">
            <Form.Label>Paikkakunta *</Form.Label>
            <Form.Control
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              isInvalid={formik.touched.city && formik.errors.city}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.city}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="flex-1">
            <Form.Label>Postinumero</Form.Label>
            <Form.Control
              name="zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
              isInvalid={formik.touched.zip && formik.errors.zip}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.zip}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </Form>

      <Form className="forms formUser boxShadow">
        <h2>KÄYTTÄJÄN TIEDOT</h2>
        <div className="flex">
          <Form.Group className="flex-1">
            <Form.Label>Etunimi</Form.Label>
            <Form.Control
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              isInvalid={formik.touched.firstName && formik.errors.firstName}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="flex-1">
            <Form.Label>Sukunimi</Form.Label>
            <Form.Control
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              isInvalid={formik.touched.lastName && formik.errors.lastName}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <Form.Group className="flex-1">
          <Form.Label>Sähköposti*</Form.Label>

          <Form.Control
            name="email2"
            value={formik.values.email2}
            onChange={formik.handleChange}
            isInvalid={formik.touched.email2 && formik.errors.email2}
            onBlur={formik.handleBlur}
            placeholder="Sähköposti"
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email2}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Puhelin</Form.Label>

          <Form.Control
            name="phone2"
            value={formik.values.phone2}
            onChange={formik.handleChange}
            isInvalid={formik.touched.phone2 && formik.errors.phone2}
            onBlur={formik.handleBlur}
            placeholder="Puhelin"
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.phophone2ne}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button block className="secondaryBtn" onClick={formik.handleSubmit}>
            Rekisteröidy
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default RegisterCompanyAndAdmin;

/* <div className="flex">
          <Form.Group className="flex-3">
            <Form.Label>Osoite</Form.Label>
            <Form.Control
              name="address2"
              value={formik.values.address2}
              onChange={formik.handleChange}
              isInvalid={formik.touched.address2 && formik.errors.address2}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.address2}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="flex-2">
            <Form.Label>Paikkakunta</Form.Label>
            <Form.Control
              name="city2"
              value={formik.values.city2}
              onChange={formik.handleChange}
              isInvalid={formik.touched.city2 && formik.errors.city2}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.city2}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="flex-1">
            <Form.Label>Postinumero</Form.Label>
            <Form.Control
              name="zip2"
              value={formik.values.zip2}
              onChange={formik.handleChange}
              isInvalid={formik.touched.zip2 && formik.errors.zip2}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.zip2}
            </Form.Control.Feedback>
          </Form.Group>
        </div>


        <Form.Group>
          <Form.Label>Käyttäjämäärä</Form.Label>
          <Form.Text>
            Käyttäjien maksimi määrä, jota voidaan muuttaa myöhemmin
          </Form.Text>
          <Form.Control
            name="accounts"
            value={formik.values.accounts}
            onChange={formik.handleChange}
            isInvalid={formik.touched.accounts && formik.errors.accounts}
            onBlur={formik.handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.accounts}
          </Form.Control.Feedback>
        </Form.Group>
*/
