import {
  SET_SOCKET,
  SOCKET_BASIC,
  SOCKET_CHAT,
  SOCKET_DISPLAY,
  SOCKET_INITIALSTATES,
  SOCKET_ITEM,
  SOCKET_NEED_LOG_OUT,
} from "../types/socket.types";

const initialState = {
  socket: undefined,
  event: null,
  subEvent: null,
  author: null,

  data: {
    /* itemTitle: "",
    link: null,
    tab: null,
    toCompany: undefined,
    toCustomer: undefined,
    toUsers: undefined,*/
  },

  response: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SOCKET:
      return {
        socket: payload,
      };
    case SOCKET_ITEM:
      return {
        ...state,
        event: payload.event,
        subEvent: payload.subEvent,
        author: payload.author,

        data: {
          itemTitle: payload.itemTitle,
          link: payload.link,
          tab: payload.tab,
          toCompany: payload.toCompany,
          toCustomer: payload.toCustomer,
          toUsers: payload.toUsers,
          itemData: payload.itemData,
          responsibles: payload.responsibles,
        },
        response: payload.response,
      };
    case SOCKET_CHAT:
      return {
        event: payload.event,
        subEvent: payload.subEvent,
        author: payload.author,

        data: {
          chatName: payload.chatName,
          link: payload.link,
          toCompany: payload.toCompany,
          toCustomer: payload.toCustomer,
          toUsers: payload.toUsers,
          chatMessage: payload.chatMessage,
          itemChat: payload.itemChat,
        },
      };

    case SOCKET_BASIC:
      return {
        ...state,
        event: payload.event,
        subEvent: payload.subEvent,
        author: payload.author,

        data: {
          header: payload.header,

          title: payload.title,
          toCompany: payload.toCompany,
          toCustomer: payload.toCustomer,
          toUsers: payload.toUsers,
          link: payload.link,
          companyLink: payload.companyLink,
          customerLink: payload.customerLink,
          logo: payload.logo,
        },

        response: payload.response,
      };
    case SOCKET_DISPLAY:
      return {
        ...state,
        event: payload.event,
        subEvent: payload.subEvent,
        response: payload.data,
        data: {
          displayIds: payload.displayIds,
        },
      };
    case SOCKET_NEED_LOG_OUT:
      return {
        event: "need_log_out",
        data: {
          toUsers: payload.toUsers,
        },
      };
    case SOCKET_INITIALSTATES:
      return {
        ...state,
        event: null,
        subEvent: null,
        author: null,

        data: {},

        response: null,
      };

    default:
      return state;
  }
}
