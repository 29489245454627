import { useDispatch, useSelector } from "react-redux";
import "./Item.scss";
import { useEffect, useState } from "react";
import {
  actionGetMyItemsSummary,
  actionSetMySummary,
  actionToggleTodoItem,
} from "../../redux/actions/action.item";
import { Badge, Form, Spinner } from "react-bootstrap";
import { formatDate, isFuture, isInRange } from "../../utils/toDates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconDown, iconUp } from "../../components/Icons/Icons";
import { useHistory } from "react-router-dom";
const MyItemSummary = () => {
  const dispatch = useDispatch();

  const { loadingMyItems, myItems } = useSelector((state) => state.item);

  const [states, setStates] = useState({
    dateType: "today",
    onlyUnDone: false,
    combineItems: true,
    onlyImportant: false,
    archived: false,
  });
  const {
    dateType,
    onlyUnDone,
    combineItems,
    onlyImportant,
    archived,
  } = states;

  useEffect(() => {
    dispatch(actionSetMySummary([], true));
    dispatch(
      actionGetMyItemsSummary({
        dateType,
        onlyUnDone,
        combineItems,
        onlyImportant,
        archived,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType, onlyUnDone, combineItems, onlyImportant, archived]);

  const handleSelect = (e) => {
    const { name, value } = e.target;
    setStates((state) => ({ ...state, [name]: value }));
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setStates((state) => ({ ...state, [name]: checked }));
  };

  return (
    <div className="basicPage MyItemSummary">
      <div className="colorsDiv">
        <div className="colorRow">
          <div className="circleToday" />
          <p>Tänään</p>
        </div>
        <div className="colorRow">
          <div className="circleTomorrow" />
          <p>Huomenna</p>
        </div>
        <div className="colorRow">
          <div className="circleWeek" />
          <p>Tällä viikolla</p>
        </div>
        <div className="colorRow">
          <div className="circleGone" />
          <p>Mennyt (tekemättä)</p>
        </div>
      </div>

      <div className="header">
        <h1>MINUN TODO</h1>
      </div>

      <div className="mainContent">
        <div className="filtersDiv">
          <div className="div1">
            <Form.Control
              name="dateType"
              as="select"
              onChange={handleSelect}
              value={dateType}
            >
              <option value="last_week">Viime viikko</option>
              <option value="today">Tänään</option>
              <option value="tomorrow">Huomenna</option>
              <option value="week">Tämä viikko</option>
              <option value="next_week">Seuraava viikko</option>
              <option value="all">Kaikki (ei arkistossa)</option>
            </Form.Control>
          </div>

          <div className="div2">
            <Form.Check
              name="onlyUnDone"
              onChange={handleCheck}
              checked={onlyUnDone}
              label="Vain tekemättömät"
            />

            <Form.Check
              name="combineItems"
              onChange={handleCheck}
              checked={combineItems}
              label="Yhdistä yhdelle kortille"
            />

            <Form.Check
              name="onlyImportant"
              onChange={handleCheck}
              checked={onlyImportant}
              label="Vain tärkeät"
            />

            <Form.Check
              name="archived"
              onChange={handleCheck}
              checked={archived}
              label="Vain arkisto"
            />
          </div>
        </div>

        <hr />

        <div className="myItemsMainDiv">
          {loadingMyItems ? (
            <Spinner animation="border" />
          ) : myItems ? (
            <div className={`myItemsDiv ${combineItems ? "compineItems" : ""}`}>
              {myItems.map((el, i) => (
                <MyItemCard
                  key={i}
                  item={el}
                  dateType={dateType}
                  combineItems={combineItems}
                />
              ))}
            </div>
          ) : (
            <p>EI TULOKSIA</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyItemSummary;

const MyItemCard = ({ item, dateType, combineItems }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = useSelector((state) => state.auth.user);
  const [expandedRowIndex, setExpandedRowIndex] = useState("");

  const toggleDone = (e, itemId, todoId, label) => {
    const doneBy = {
      email: email,
      date: Date.now(),
    };

    const content = {
      itemId,
      todoId,
      label,
      done: e.target.checked,
      fromMyItems: true,
      compined: combineItems,
      doneBy,
    };

    dispatch(actionToggleTodoItem(content));
  };

  const selectExpandIndex = (i) => {
    let index = i;

    if (index === expandedRowIndex) {
      index = "";
    }

    setExpandedRowIndex(index);
  };

  const navigateTo = (url) => {
    history.push(url);
  };
  return (
    <div className="myItemCard">
      <div className="headerCard">
        <p onClick={() => navigateTo(`/item/${item._id}`)}>{item.title} </p>

        {item.todosCount && (
          <p className="p-count">
            {item.todosCount.done} / {item.todosCount.max}{" "}
          </p>
        )}
      </div>

      <div className="content">
        {item.todos?.length ? (
          <div className="todosDiv">
            {item.todos.map((el, i) => (
              <div key={i} className={`todoRow`}>
                <div className="div1">
                  <Form.Check
                    checked={el.done}
                    onChange={(e) =>
                      combineItems
                        ? toggleDone(e, el.itemId, el._id, el.label)
                        : toggleDone(e, item._id, el._id, el.label)
                    }
                  />

                  <div className="labelDiv">
                    {el.important ? (
                      <Badge pill variant="warning">
                        TÄRKEÄ
                      </Badge>
                    ) : null}
                    <p className={`p-label ${el.done ? "TodoDone" : ""} `}>
                      {el.label}
                    </p>
                  </div>
                  <p
                    className={`p-date ${
                      !el.done && isInRange(el.date, "today")
                        ? "isToday"
                        : !el.done && isInRange(el.date, "tomorrow")
                        ? "isTomorrow"
                        : !el.done &&
                          isFuture(el.date) &&
                          isInRange(el.date, "week")
                        ? "isWeek"
                        : !el.done && el.date && !isFuture(el.date)
                        ? "isGone"
                        : el.done
                        ? "TodoDone"
                        : ""
                    }`}
                  >
                    {el.date ? formatDate(el.date, "ddd DD.MM - HH:mm") : `-:-`}
                  </p>
                  <div>
                    {expandedRowIndex === i ? (
                      <FontAwesomeIcon
                        icon={iconUp}
                        size="lg"
                        onClick={() => selectExpandIndex(i)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={iconDown}
                        size="lg"
                        onClick={() => selectExpandIndex(i)}
                      />
                    )}
                  </div>
                </div>

                {expandedRowIndex === i ? (
                  <div className="div2">
                    {combineItems && (
                      <h5 onClick={() => navigateTo(`/item/${el.itemId}`)}>
                        TEHTÄVÄ: {el.itemTitle}{" "}
                      </h5>
                    )}
                    <ul>
                      <li>
                        <strong>MERKKAAJA: </strong>
                        {el.doneBy
                          ? `${el.doneBy.email} / ${formatDate(
                              el.doneBy?.date,
                              "ddd DD.MM - HH:mm"
                            )}`
                          : "-"}
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <div className="noResults">
            <p>EI TODO LISTAA</p>
          </div>
        )}
      </div>

      <div className="footer">
        <div className="responsibesDiv">
          {item.responsibles?.map((el, i) => (
            <div
              key={i}
              className="responsibleSpan"
            >{`${el.firstName} ${el.lastName}`}</div>
          ))}
        </div>
      </div>
    </div>
  );
};
