import axios from "axios";
import proxy from "../../utils/proxy";

import handleErrors from "./handleErrors";

import {
  COMMON_DELETE_TAG,
  COMMON_GET_TAGS,
  COMMON_NEW_TAG,
  COMMON_UPDATE_TAG,
  COMMON_UPDATE_TAG_LIST,
} from "../types/common.types";
import { actionAlert } from "./action.alert";
import { actionClearModals2 } from "./action.modal";

export const actionGetCompanyTags = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${proxy}/api/company/tags/get-tags`, config);

    if (res.status === 200) {
      dispatch({
        type: COMMON_GET_TAGS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionUpdateTagList = (newTags) => async (dispatch) => {
  dispatch({
    type: COMMON_UPDATE_TAG_LIST,
    payload: newTags,
  });
};

export const actionDeleteCompanyTag = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/tags/delete/${id}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMMON_DELETE_TAG,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionUpdateCompanyTag = ({ tagId, name }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      tagId,
      name,
    });

    const res = await axios.post(
      `${proxy}/api/company/tags/update-tag`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMMON_UPDATE_TAG,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionNewCompanyTag = ({ name }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      name,
    });

    const res = await axios.post(
      `${proxy}/api/company/tags/new-tag`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMMON_NEW_TAG,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};
