export const GET_CHAT_ROOMS = "GET_CHAT_ROOMS";

export const GET_ROOM_MESSAGES = "GET_ROOM_MESSAGES";

export const NEW_ROOM = "NEW_ROOM";
export const MESSAGE_SEND_ROOM = "MESSAGE_SEND_ROOM";

export const CHATROOM_INITIAL_STATES = "CHATROOM_INITIAL_STATES";

export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";

export const DELETE_CHAT_ROOM = "DELETE_CHAT_ROOM";

export const GET_ROOM = "GET_ROOM";

export const UPDATE_CHAT_ROOM = "UPDATE_CHAT_ROOM";

export const SET_SELECTED_ROOM = "SET_SELECTED_ROOM";

export const TOGGLE_CHAT_NOTIF = "TOGGLE_CHAT_NOTIF";

export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const SET_ROOM_MESSAGES = "SET_ROOM_MESSAGES";

export const UPDATE_ROOM_MSG = "UPDATE_ROOM_MSG";

export const SORT_ROOMS = "SORT_ROOMS";

export const SET_ROOMS = "SET_ROOMS";
