import axios from "axios";

import proxy from "../../utils/proxy";
import { ITEM_UPDATE } from "../types/item.types";
import {
  CLEAR_LINK_SELECTED,
  GET_ITEM_IMAGES,
  GET_TOKEN_CHATS,
  GET_TOKEN_ITEMS,
  SELECT_TOKEN_CHATS,
  SEND_TOKEN_MSG,
  VALIDATE_LINK_USER,
  VALIDATE_LINK_USER_FAIL,
} from "../types/links.types";
import { actionAlert } from "./action.alert";
import { actionSocketBasic2 } from "./action.socket";

import handleErrors from "./handleErrors";

export const actionCreateLink = ({ type, id, link }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    type,
    id,
    link,
  });

  try {
    const res = await axios.post(`${proxy}/api/links/create`, body, config);

    // res.data sisältää vain muokatut kentät, payload sisällytetään selectedItemiin reducerissa
    if (res.status === 200) {
      dispatch(actionAlert("Onnistui", "success"));

      if (res.data.type === "item") {
        dispatch({
          type: ITEM_UPDATE,
          payload: {
            data: res.data,
          },
        });
      }

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionValidateLinkUser = ({ token }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/links/validate-link-user/${token}`,
      config
    );

    dispatch({
      type: VALIDATE_LINK_USER,
      payload: res.data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: VALIDATE_LINK_USER_FAIL,
      payload: {
        status: err.response?.status,
        message: err.response?.data?.message,
      },
    });

    return null;
  }
};

export const actionGetTokenItems = ({ token }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/links/get-token-items/${token}`,
      config
    );

    dispatch({
      type: GET_TOKEN_ITEMS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionGetTokenChats = ({ token }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/links/get-token-chats/${token}`,
      config
    );

    dispatch({
      type: GET_TOKEN_CHATS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionGetTokenChatAndMessages = ({ token, chatId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/links/get-token-chat/${token}/${chatId}`,
      config
    );
    dispatch({
      type: SELECT_TOKEN_CHATS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionSendTokenMsg = ({ token, chatId, message }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    message,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/links/send-token-msg/${token}/${chatId}`,
      body,
      config
    );
    dispatch({
      type: SEND_TOKEN_MSG,
      payload: res.data.data,
    });

    dispatch(actionSocketBasic2(res.data.socket));

    return true;
  } catch (err) {
    dispatch(handleErrors(err));

    return false;
  }
};

export const actionSetTokenSelected = (selected) => async (dispatch) => {
  dispatch({
    type: SELECT_TOKEN_CHATS,
    payload: selected,
  });
};

export const actionDeleteLinks = ({ linkSecrets, itemId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    linkSecrets,
    itemId,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/links/delete-links`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch(actionAlert("Onnistui", "success"));

      if (res.data.type === "item") {
        dispatch({
          type: ITEM_UPDATE,
          payload: {
            data: res.data,
          },
        });
      }

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionLinkClearSelected = () => async (dispatch) => {
  dispatch({ type: CLEAR_LINK_SELECTED });
};

export const actionGetTokenItemImages = ({ itemId, files, token }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ itemId, files });

  try {
    const res = await axios.post(
      `${proxy}/api/links/get-files/${token}`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_ITEM_IMAGES,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    handleErrors(err);
    return null;
  }
};
