import { v4 as uuidv4 } from "uuid";

import {
  ANSWER_QUESTION,
  ASK_ANSWER,
  ASK_EMPTY_ANSWERS,
  ASK_INITIAL,
  SET_ASK,
} from "../types/ask.types";

export const actionAsk = ({ text, text2, fromId, fromName, date }) => (
  dispatch
) => {
  const id = uuidv4();

  dispatch({
    type: SET_ASK,
    payload: { id, text, text2, fromId, fromName, date },
  });
};

export const actionAnswerAsk = ({ answer, question, fromName, date }) => (dispatch) => {
  const id = uuidv4();

  dispatch({
    type: ASK_ANSWER,
    payload: { id, answer, question, fromName, date },
  });
};

export const actionAskInitial = () => (dispatch) => {
  dispatch({
    type: ASK_INITIAL,
  });
};

export const actionAnswerQuestion = (id) => (dispatch) => {
  dispatch({
    type: ANSWER_QUESTION,
    payload: { id },
  });
};

export const actionAskEmptyAnswers = () => (dispatch) => {
  dispatch({
    type: ASK_EMPTY_ANSWERS,
  });
};
