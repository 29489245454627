export const GET_CONTACTS = "GET_CONTACTS";

export const NEW_CONTACT = "NEW_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

export const DELETE_CONTACT = "DELETE_CONTACT";

export const EMPTY_CONTACTS = "EMPTY_CONTACTS";

export const UPDATE_MULTIPLE_CONTACTS = "UPDATE_MULTIPLE_CONTACTS";

export const MULTIPLE_CONTACTS_SUCCESS = "MULTIPLE_CONTACTS_SUCCESS";
