// React & Routing
import React from "react";

// Redux
import PropTypes from "prop-types";

// Styling
import "./Alert.scss";

import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

const ConfirmAlert = ({
  show,
  variant,
  title,
  btnText,
  onSuccess,
  onClose,
  children,
  submitting,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className={`ConfirmDiv ${variant} animated fadeInUp`}>
      <p className="p-title">{title}</p>
      {children}
      <hr />
      {submitting ? (
        <div className="buttons">
          <Spinner animation="grow" />
        </div>
      ) : (
        <div className="buttons">
          <Button className="secondaryBtn" onClick={onClose}>
            Peruuta
          </Button>
          <Button className="primaryBtn" onClick={onSuccess}>
            {btnText || "Vahvista"}
          </Button>
        </div>
      )}
    </div>
  );
};

ConfirmAlert.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string,
  btnText: PropTypes.string,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.element,
};

export default ConfirmAlert;
