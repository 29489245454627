import React, { Fragment, useState } from "react";
import { Badge, Button, Form, Spinner, Table } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import "./Items.scss";

import {
  iconCalendar,
  iconStar1,
  iconStar2,
  iconTrash,
  lightBulb,
  lightBulb2,
  iconBell,
  iconEye2,
  iconEye,
  iconDown,
  iconUp,
  iconCopy,
} from "../Icons/Icons";
import {
  dateWithDayNameAndTime,
  formatTimeZeros,
  getDateWithDayName,
  getDuration,
  onlyTime,
} from "../../utils/toDates";
import useSingleAndDoubleClick from "../../hooks/useClickAndDoubleClick";
import { Link } from "react-router-dom";

import { useLocation } from "react-router";
import {
  actionBlurItem,
  actionCopyItem,
  actionItemToggleFavorite,
  actionToggleTodoItem,
} from "../../redux/actions/action.item";
import { actionSetModals2 } from "../../redux/actions/action.modal";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";

const CompanyItem = React.memo(
  ({ item, selectItemAndRedirect, timeNow, myId, fitScreen }) => {
    const jaretechIds = process.env.REACT_APP_JARETECH_ID;

    const { companyId, email } = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();
    const [states, setStates] = useState({
      submittingId: null,
    });
    const { submittingId } = states;
    const location = useLocation();

    const [expandIndex, setExpandIndex] = useState("");

    const deleteIcon = <FontAwesomeIcon icon={iconTrash} size="lg" />;

    const clockIcon = (
      <FontAwesomeIcon icon={faClock} color="#464543" size="lg" />
    );
    const calendarIcon = (
      <FontAwesomeIcon icon={iconCalendar} color="#464543" size="lg" />
    );

    const downIcon = <FontAwesomeIcon icon={iconDown} size="sm" />;
    const upIcon = <FontAwesomeIcon icon={iconUp} size="sm" />;
    const copyIcon = <FontAwesomeIcon icon={iconCopy} size="lg" />;

    //const userIn = containValue(item.usersIn || [], "id", myId);

    /*
  const locationIcon = (
    <FontAwesomeIcon icon={faCompass} color="#464543" size="2x" />
  );*/

    const starIcon1 = (
      <FontAwesomeIcon icon={iconStar1} size="lg" className="star1" />
    );
    const starIcon2 = (
      <FontAwesomeIcon icon={iconStar2} size="lg" className="star2" />
    );

    const bulbIcon1 = (
      <FontAwesomeIcon icon={lightBulb} size="lg" className="star1" />
    );
    const bulbIcon2 = (
      <FontAwesomeIcon icon={lightBulb2} size="lg" className="star2" />
    );
    const bellIcon1 = (
      <FontAwesomeIcon icon={iconBell} size="lg" className="star2" />
    );

    const private1Icon = (
      <FontAwesomeIcon icon={iconEye} size="lg" className="star1" />
    );
    const private2Icon = (
      <FontAwesomeIcon icon={iconEye2} size="lg" className="star1" />
    );
    //fadeInOut
    const statusText =
      item.status === 0
        ? "Vapaana kaikille"
        : item.status === 1
        ? "Ei aloitettu"
        : item.status === 2
        ? "Työnalla"
        : item.status === 3
        ? "Odottaa"
        : item.status === 4
        ? "Valmis"
        : item.status === 9
        ? "Arkistossa"
        : "Ei määritetty";

    const deadlineAlert = item.deadline
      ? timeNow > new Date(item.deadline)
        ? "alert-deadline"
        : "success-deadline"
      : "";

    const setCardTab = (cardId, tab) => {
      setStates((state) => ({
        ...state,
        [cardId]: {
          tab: tab,
        },
      }));
    };

    let cardTimes;

    let timeLeftStyle = `p-timeLeft`;

    if (item.date && timeNow) {
      cardTimes = getDuration(timeNow, item.date, "seconds", true);

      if (cardTimes.days < 0 || cardTimes.hours < 0 || cardTimes.minutes < 0) {
        cardTimes = null;
      }
    }

    let cardTimes2;

    if (item.deadline && timeNow) {
      cardTimes2 = getDuration(timeNow, item.deadline, "seconds", true);

      if (
        cardTimes2.days < 0 ||
        cardTimes2.hours < 0 ||
        cardTimes2.minutes < 0
      ) {
        cardTimes2 = null;
      }
    }

    const doubleClick = (cardId) => {
      let tab = states[cardId]?.tab || 1;

      if (tab < 3) {
        tab += 1;
      } else {
        tab = 1;
      }

      setStates((state) => ({
        ...state,
        [cardId]: {
          tab: tab,
        },
      }));
    };

    const click = useSingleAndDoubleClick(
      () => selectItemAndRedirect(item),
      () => doubleClick(item._id)
    );
    // pienennetään esim fonttia -> mahtuu tabletilla todennäkösemmin paremmin
    const fitStyle = fitScreen ? "Fit" : "";

    const toggleFav = (e, itemId) => {
      e.stopPropagation();
      setStates((state) => ({ ...state, submittingId: itemId }));

      dispatch(actionItemToggleFavorite(itemId)).then(() => {
        setStates((state) => ({ ...state, submittingId: null }));
      });
    };

    const toggleBlur = (e, itemId) => {
      e.stopPropagation();
      setStates((state) => ({ ...state, submittingId: itemId }));

      dispatch(actionBlurItem(itemId)).then(() => {
        setStates((state) => ({ ...state, submittingId: null }));
      });
    };

    const deleteItem = (id, title) => {
      dispatch(
        actionSetModals2({
          modalType: 4,
          title: `POISTA TEHTÄVÄ - ${title}`,

          data: {
            itemId: id,
          },
        })
      );
    };

    const addNotification = (e, item) => {
      e.stopPropagation();

      const title = item.title;
      const itemId = item._id;
      const itemDate = item.date;

      const data = {
        title,
        itemId,
        itemDate,
      };

      dispatch(
        actionSetModals2({
          modalType: 600,
          title: "Aseta muistutus (BETA)",
          data: data,
        })
      );

      //dispatch(actionNewNotification(data));
    };

    const blurStyle = item.myBlur ? "blurItem" : "";

    const basicCard = (
      <>
        {" "}
        <div>
          <p className="p-title">{item.title}</p>

          {!states[item._id] || states[item._id]?.tab === 1 ? (
            <div className="content">
              <div className="boxes">
                <div className="date">
                  {!item.companyMade && !item.date && (
                    <Badge pill variant="danger" className="fadeInOut">
                      !
                    </Badge>
                  )}
                  <p className="p-subtitle1">Ajankohta</p>
                  <p className="p3">
                    {calendarIcon}{" "}
                    {item.date ? getDateWithDayName(item.date) : "--:--"}
                  </p>
                  <p className="p3">
                    {clockIcon} {item.date ? onlyTime(item.date) : "--:--"}
                  </p>

                  {cardTimes && (
                    <p className={`${timeLeftStyle}`}>
                      {cardTimes.years > 0 ? (
                        <>
                          <span>{cardTimes.years}Vuotta</span>{" "}
                          <span>{cardTimes.months}kk</span>{" "}
                        </>
                      ) : cardTimes.months > 0 ? (
                        <>
                          <span>{cardTimes.months}kk</span>{" "}
                          <span>{cardTimes.days}vrk</span>{" "}
                        </>
                      ) : cardTimes.days >= 0 && cardTimes.days < 2 ? (
                        <>
                          <span>{cardTimes.days}vrk</span>{" "}
                          <span>{formatTimeZeros(cardTimes.hours)}h </span>{" "}
                          <span>{formatTimeZeros(cardTimes.minutes)}min</span>{" "}
                        </>
                      ) : (
                        <>
                          <span>{cardTimes.days}vrk</span>{" "}
                          <span>{formatTimeZeros(cardTimes.hours)}h </span>{" "}
                        </>
                      )}
                    </p>
                  )}
                </div>
                {item.tags && item.tags.length ? (
                  <div className="tags">
                    {item.tags?.map((el, i) => (
                      <div key={i} className="tag">
                        {el.name}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="date">
                    <p className="p-subtitle1">Deadline</p>
                    <p className={`p3 ${deadlineAlert}`}>
                      {calendarIcon}{" "}
                      {item.deadline
                        ? getDateWithDayName(item.deadline)
                        : "--:--"}
                    </p>
                    <p className={`p3 ${deadlineAlert}`}>
                      {clockIcon}{" "}
                      {item.deadline ? onlyTime(item.deadline) : "--:--"}
                    </p>

                    {cardTimes2 && (
                      <p className={`${timeLeftStyle}`}>
                        {cardTimes2.years > 0 ? (
                          <>
                            <span>{cardTimes2.years}Vuotta</span>{" "}
                            <span>{cardTimes2.months}kk</span>{" "}
                          </>
                        ) : cardTimes2.months > 0 ? (
                          <>
                            <span>{cardTimes2.months}kk</span>{" "}
                            <span>{cardTimes2.days}vrk</span>{" "}
                          </>
                        ) : cardTimes2.days >= 0 && cardTimes2.days < 2 ? (
                          <>
                            <span>{cardTimes2.days}vrk</span>{" "}
                            <span>{formatTimeZeros(cardTimes2.hours)}h </span>{" "}
                            <span>
                              {formatTimeZeros(cardTimes2.minutes)}min
                            </span>{" "}
                          </>
                        ) : (
                          <>
                            <span>{cardTimes2.days}vrk</span>{" "}
                            <span>{formatTimeZeros(cardTimes2.hours)}h </span>{" "}
                          </>
                        )}
                      </p>
                    )}
                  </div>
                )}
              </div>

              <p className="p-cardInfo">{item.cardInfo}</p>
            </div>
          ) : states[item._id]?.tab === 2 ? (
            <div className="content">
              <div className="box location">
                <p className="p-subtitle1">Sijainti</p>
                {item.location ? (
                  <>
                    <p className="p-break">
                      {item.location.address}, {item.location.zip}{" "}
                    </p>
                    <p className="p-break">{item.location.city}</p>
                  </>
                ) : (
                  <p>Ei ilmoitettu</p>
                )}
              </div>
              <div className="box desc">
                <p className="p-subtitle1">Kuvaus</p>
                {item.location ? (
                  <textarea value={item.desc} readOnly />
                ) : (
                  <p>Ei ilmoitettu</p>
                )}
              </div>
            </div>
          ) : states[item._id]?.tab === 3 ? (
            <div className="content">
              <div className="boxes">
                <div className="box users">
                  <p className="p-subtitle1">Paikalla</p>
                  <div className="grid">
                    {item.usersIn?.map((el, i) => (
                      <span key={i}>{el.name}</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Ei</p>
          )}
        </div>
        <div className="footer" onClick={(e) => e.stopPropagation()}>
          {item.usersIn?.length > 0 && (
            <div className="usersIn">
              <div className="dotLime" />
              <span>Paikalla ({item.usersIn.length})</span>
            </div>
          )}

          <div className="dots">
            <div
              className={`dot ${
                !states[item._id] || states[item._id]?.tab === 1
                  ? "dotActive"
                  : ""
              }`}
              onClick={() => setCardTab(item._id, 1)}
            />
            <div
              className={`dot ${
                states[item._id]?.tab === 2 ? "dotActive" : ""
              }`}
              onClick={() => setCardTab(item._id, 2)}
            />

            <div
              className={`dot ${
                states[item._id]?.tab === 3 ? "dotActive" : ""
              }`}
              onClick={() => setCardTab(item._id, 3)}
            />
          </div>

          <div className="alerts">
            {item.privateAlerts?.map((el, i) => (
              <Badge variant={el.variant} pill key={i}>
                {el.title}
              </Badge>
            ))}
            {item.alerts?.map((el, i) => (
              <Badge variant={el.variant} pill key={i}>
                {el.title}
              </Badge>
            ))}
          </div>

          <div className="numbers">
            <Form.Text>Liitteet: {item.images?.length || 0}</Form.Text>
            {item.lastMoved ? (
              <p>Tilaa muutettu: {dateWithDayNameAndTime(item.lastMoved)}</p>
            ) : (
              <p>Tilaa muutettu: -</p>
            )}
          </div>
          <div className="buttons" onClick={(e) => e.stopPropagation()}>
            {jaretechIds.includes(companyId) && (
              <Link
                className="outLine-blue"
                to={{
                  pathname: `/chat/rooms/${item._id}`,
                  state: { ...location.state },
                }}
              >
                Viestit ({item.messages})
                {item.unread > 0 && (
                  <Badge pill variant="danger" className="fadeInOut">
                    {item.unread}
                  </Badge>
                )}
              </Link>
            )}
            <Link
              className="outLine-blue"
              to={{
                pathname: `/item/${item._id}`,
                state: { ...location.state, tab: 5 },
              }}
            >
              Raportit ({`${item.reportsCount} / ${item.finalReportCount}`})
            </Link>
            {item.location && (
              <Button
                variant="link"
                className="outLine-blue"
                disabled={!item.location}
                href={`https://www.google.com/maps/dir/?api=1&destination=${
                  item.location.address +
                  "+" +
                  item.location.zip +
                  "+" +
                  item.location.city
                }&travelmode=car`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Avaa kartta
              </Button>
            )}
          </div>
        </div>
      </>
    );

    const toggleDone = (e, label, todoId) => {
      const { checked } = e.target;
      e.stopPropagation();

      const doneBy = {
        email: email,
        date: Date.now(),
      };

      const content = {
        itemId: item._id,
        todoId: todoId,
        done: checked,
        label: label,
        doneBy,
        fromItems: true,
      };

      dispatch(actionToggleTodoItem(content));
    };

    const selectExpandIndex = (i) => {
      let index = i;

      if (expandIndex === index) {
        index = "";
      }

      setExpandIndex(index);
    };

    const todoCard = item.todos?.length ? (
      <div className="todoCard">
        <div className="todoTitleDiv">
          <p>{item.title}</p>
        </div>
        <div className="todoContent">
          <Table className="todoTable">
            <tbody>
              {item.todos?.map((el, i) => (
                <Fragment key={i}>
                  <tr key={i} className={expandIndex === i ? "active" : ""}>
                    <td onClick={(e) => e.stopPropagation()}>
                      <Form.Check
                        name="done"
                        readOnly
                        checked={el.done}
                        onChange={(e) => toggleDone(e, el.label, el._id)}
                      />
                    </td>
                    <td className="td-label">{el.label}</td>
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                        selectExpandIndex(i);
                      }}
                    >
                      {expandIndex === i ? upIcon : downIcon}
                    </td>
                  </tr>

                  {expandIndex === i ? (
                    <tr>
                      <td colSpan={3} className="expandedTd">
                        <div className="expandedDiv">
                          <p className="p-author">
                            <strong>{el.doneBy?.email}</strong>{" "}
                            {dateWithDayNameAndTime(el.doneBy?.date)}
                          </p>

                          <AutoSizeTextArea
                            name="info"
                            isInvalid={undefined}
                            onBlur={undefined}
                            formikError={undefined}
                            value={el.info}
                            disabled
                          />
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    ) : (
      <div className="todoCard">
        <div className="todoTitleDiv">
          <p>{item.title}</p>
        </div>
        <div className="todoContent">
          <div className="noResults">
            <p>EI todo listaa</p>
          </div>
        </div>
      </div>
    );

    const copyItem = (e, id) => {
      e.stopPropagation();
      console.log("* COPY *", id);
      dispatch(actionCopyItem({ itemId: id }));
    };

    return (
      <div
        className={`ItemCard boxShadow ${fitStyle} ${blurStyle}`}
        onClick={click}
        key={item._id}
      >
        <div className="header">
          <div className={`div1 badge-status-${item.status}`}>
            <div className="deleteDiv">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  deleteItem(item._id, item.title);
                }}
                variant="none"
              >
                {deleteIcon}
              </Button>
              <span>
                <strong>{statusText}</strong>
                <p>{item.statusText}</p>
              </span>
              
            </div>

            <div className="iconButtons">
              {!submittingId ? (
                <>
                  <Button variant="none" onClick={(e) => copyItem(e, item._id)}>
                    {copyIcon}
                  </Button>

                  <Button variant="none" disabled={true}>
                    {item.visibility === "onlyList"
                      ? private2Icon
                      : private1Icon}
                  </Button>

                  <Button
                    variant="none"
                    onClick={(e) => toggleFav(e, item._id)}
                  >
                    {item.myFavorite ? starIcon1 : starIcon2}
                  </Button>
                  <Button
                    variant="none"
                    onClick={(e) => toggleBlur(e, item._id)}
                  >
                    {item.myBlur ? bulbIcon1 : bulbIcon2}
                  </Button>

                  <Button
                    variant="none"
                    onClick={(e) => addNotification(e, item)}
                  >
                    {bellIcon1}
                  </Button>
                </>
              ) : (
                submittingId === item._id && <Spinner animation="grow" />
              )}
            </div>
          </div>

          <div className="responsiblesDiv">
            {item.responsibles?.length > 0 ? (
              <div className="rowDiw">
                {item.responsibles.slice(0, 5).map((el, i) => (
                  <span key={i}>{`${el.firstName} ${el.lastName}`}</span>
                ))}
              </div>
            ) : (
              <span>Ei vastuutettu</span>
            )}
            {item.responsibles?.length > 5 && <span>...</span>}
          </div>

          <div className="div2">
            <div className="left">
              <span>Luotu: {dateWithDayNameAndTime(item.createdAt)}</span>
              <span>Tekijä: {item.doneBy || "-"}</span>
              <span>{item.author?.name || item.author?.email}</span>
            </div>

            <div className="right">
              <span>Päivitetty: {dateWithDayNameAndTime(item.updatedAt)}</span>
              <span className="badge-grey">
                {item.companyMade ? "Yritys tehnyt" : "Asiakkaalta"}
              </span>
              <span>
                <strong>{item.customer?.name}</strong>
              </span>
            </div>
          </div>
        </div>

        {item.itemType === "todo" ? todoCard : basicCard}
      </div>
    );
  }
);

export default CompanyItem;

/*
301
 <p className="p-subtitle1">Deadline</p>
                  <p className={`p3 ${deadlineAlert}`}>
                    {calendarIcon}{" "}
                    {item.deadline
                      ? getDateWithDayName(item.deadline)
                      : "--:--"}
                  </p>
                  <p className={`p3 ${deadlineAlert}`}>
                    {clockIcon}{" "}
                    {item.deadline ? onlyTime(item.deadline) : "--:--"}
                  </p>

                  {cardTimes2 && (
                    <p className={`${timeLeftStyle}`}>
                      {cardTimes2.years > 0 ? (
                        <>
                          <span>{cardTimes2.years}Vuotta</span>{" "}
                          <span>{cardTimes2.months}kk</span>{" "}
                        </>
                      ) : cardTimes2.months > 0 ? (
                        <>
                          <span>{cardTimes2.months}kk</span>{" "}
                          <span>{cardTimes2.days}vrk</span>{" "}
                        </>
                      ) : cardTimes2.days >= 0 && cardTimes2.days < 2 ? (
                        <>
                          <span>{cardTimes2.days}vrk</span>{" "}
                          <span>{formatTimeZeros(cardTimes2.hours)}h </span>{" "}
                          <span>{formatTimeZeros(cardTimes2.minutes)}min</span>{" "}
                        </>
                      ) : (
                        <>
                          <span>{cardTimes2.days}vrk</span>{" "}
                          <span>{formatTimeZeros(cardTimes2.hours)}h </span>{" "}
                        </>
                      )}
                    </p>
                  )}*/
