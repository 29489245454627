import axios from "axios";
import proxy from "../../utils/proxy";
import { GET_DB_HOME } from "../types/display.types";
import handleErrors from "./handleErrors";

export const actionHomeDB = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const url = id ? `${proxy}/api/display/get-home/${id}` : `${proxy}/api/display/get-home`
    const res = await axios.get(url, config);

    if (res.status === 200) {
      dispatch({
        type: GET_DB_HOME,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};
