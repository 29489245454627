import axios from "axios";
import proxy from "../../utils/proxy";
import {
  ADD_NEW_MESSAGE,
  CHATROOM_INITIAL_STATES,
  DELETE_CHAT_ROOM,
  GET_CHAT_ROOMS,
  GET_ROOM,
  GET_ROOM_MESSAGES,
  MESSAGE_SEND_ROOM,
  NEW_ROOM,
  SET_ROOMS,
  SET_ROOM_MESSAGES,
  SET_SELECTED_ROOM,
  SORT_ROOMS,
  UPDATE_CHAT_ROOM,
  UPDATE_MESSAGE,
  UPDATE_ROOM_MSG,
} from "../types/chatRoom.types";
import { actionAlert } from "./action.alert";
import { actionClearModals2 } from "./action.modal";
import { actionSocketBasic2 } from "./action.socket";
import handleErrors from "./handleErrors";

export const actionNewChatRoom = ({
  title,
  desc,
  users,
  toAllCustomer,
  customerCompany,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    title,
    desc,
    users,
    toAllCustomer,
    customerCompany,
  });

  try {
    const res = await axios.post(`${proxy}/api/chat/new-room`, body, config);

    if (res.status === 200) {
      dispatch({
        type: NEW_ROOM,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionUpdateChatRoom = ({
  title,
  desc,
  users,
  roomId,
  closed,
  customerCompany,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    title,
    desc,
    users,
    roomId,
    closed,
    customerCompany,
  });

  try {
    const res = await axios.put(`${proxy}/api/chat/update-room`, body, config);

    if (res.status === 200) {
      dispatch({
        type: UPDATE_CHAT_ROOM,
        payload: res.data.data,
      });

      dispatch(actionSocketBasic2(res.data.socket));

      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionDeleteChatRoom = (roomId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/chat/delete-room/${roomId}`,
      config
    );

    dispatch({
      type: DELETE_CHAT_ROOM,
      payload: res.data,
    });

    return true;
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionDeleteMessage = (messageId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/chat/delete-message/${messageId}`,
      config
    );

    return res.data;
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetChatRooms = ({ closed }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/chat/get-rooms?closed=${closed}`,
      config
    );

    dispatch({
      type: GET_CHAT_ROOMS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionSendMessageToRoom = ({ message, roomId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    message,
    roomId,
  });

  try {
    const res = await axios.post(`${proxy}/api/chat/new-message`, body, config);

    if (res.status === 200) {
      dispatch({
        type: MESSAGE_SEND_ROOM,
        payload: res.data.data,
      });

      dispatch(actionAlert("Lähetetty", "success"));
      dispatch(actionSocketBasic2(res.data.socket));
    }

    return true;
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetRoomMessages = (roomId, page, onlyFav) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    roomId,
    page,
    onlyFav,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/chat/get-messages`,
      body,
      config
    );

    dispatch({
      type: GET_ROOM_MESSAGES,
      payload: res.data,
    });

    return {
      limit: res.data.limit,
      loaded: res.data.loaded || 0,
    };
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionChatRoomInitialStates = () => (dispatch) => {
  dispatch({
    type: CHATROOM_INITIAL_STATES,
  });
};

//////////////////////////// ITEM ///////////////////////////

// voidaan hakea myös yksi "normaali" huome eli ei tarvitse olla tilauksen huone
export const actionGetItemChatRoom = (itemId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${proxy}/api/chat/item/${itemId}`, config);

    dispatch({
      type: GET_ROOM,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionCreateItemChatRoom = (itemId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `${proxy}/api/chat/new-item-chat/${itemId}`,
      config
    );

    if (res.status === 200) {
      window.location.reload();
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

/////////////////////////// COMPANY CHAT //////////////////////////////////////////////

export const actionGetCompanyChatMessages = (page) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const body = JSON.stringify({
      page,
    });

    const res = await axios.post(
      `${proxy}/api/chat/get-company-chat-messages`,
      body,
      config
    );

    dispatch({
      type: GET_ROOM_MESSAGES,
      payload: res.data || [],
    });

    return {
      limit: res.data.limit,
      loaded: res.data.loaded || 0,
    };
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionSendMessageToCOmpanyChat = ({ message }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    message,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/chat/send-company-chat`,
      body,
      config
    );

    dispatch({
      type: MESSAGE_SEND_ROOM,
      payload: res.data.data,
    });

    dispatch(actionAlert("Lähetetty", "success"));
    dispatch(actionSocketBasic2(res.data.socket));

    return true;
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionAddNewMessageToRedux = (message) => async (dispatch) => {
  dispatch({ type: ADD_NEW_MESSAGE, payload: message });
};

export const actionClearSelectedRoom = (room, loading) => async (dispatch) => {
  dispatch({ type: SET_SELECTED_ROOM, payload: { room, loading } });
};

export const actionReadRoomMessage = ({ msgId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    msgId,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/chat/read-message`,
      body,
      config
    );

    return res.data;
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionToggleChatNotif = ({ roomId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    roomId,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/chat/toggle-room-notif`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_CHAT_ROOM,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionToggleMsgFav = ({ msgId, roomId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    msgId,
    roomId,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/chat/toggle-msg-fav`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_MESSAGE,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionSetRoomMessages = (messages, loading) => async (
  dispatch
) => {
  dispatch({ type: SET_ROOM_MESSAGES, payload: { messages, loading } });
};

export const actionUpdateRoomMsg = (message) => async (dispatch) => {
  dispatch({ type: UPDATE_ROOM_MSG, payload: message });
};

export const actionSortRooms = () => async (dispatch) => {
  dispatch({ type: SORT_ROOMS });
};

export const actionSetRooms = (rooms, loading) => async (dispatch) => {
  dispatch({ type: SET_ROOMS, payload: { rooms, loading } });
};
