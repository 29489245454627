import { SET_ERRORS } from "../types/errors.types";

const handleErrors = (err, variant) => (dispatch) => {
  let message = "";

  if (err.response && err.response.data && err.response.data.message) {
    // simple one message error response
    message = err.response.data.message;
    
    dispatch({
      type: SET_ERRORS,
      payload: {
        errors: message,
        type: "one",
        status: err.response ? err.response.status : "-",
        redirectUrl: err.response.data.redirectUrl,
        reloadPage: err.response.data.reloadPage,
      },
    });
  } else if (err.response && err.response.data && err.response.data.errors) {
    // express-validator errors array
    message = err.response.data.errors;
    dispatch({
      type: SET_ERRORS,
      payload: {
        errors: message,
        type: "array",
        status: err.response ? err.response.status : "-",
      },
    });
  } else if (err.message) {
    message = err.toString();
    console.error(message);
    /* dispatch({
      type: SET_ERRORS,
      payload: {
        errors: message,
        type: "one",
        status: err.response ? err.response.status : "-",
      },
    });*/
  }

  // message = alert.msg
  // is array if express validator errror
  // front handles in App.jsx useEffect
  //  dispatch(actionSetError(message, type, variant||"danger"));
};

export default handleErrors;
