import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./NoAccess.scss";

export default function NoAccess({ userGroup }) {
  const { email } = useSelector((state) => state.auth.user);
  return (
    <div className="NoAccess">
      <div className="item">
        <h2>Ei oikeutta</h2>
        <p>Käyttäjä: "{email}"</p>

        <p>Tarvittaessa ole yhteydessä pääkäyttäjään</p>

        <hr />

        <Link to="/">Päävalikkoon</Link>
      </div>
    </div>
  );
}
