import {
  ADMIN_USERS_LOAD,
  ADMIN_COMPANY_LOAD,
  ADMIN_COMPANY_EDIT,
  ADMIN_ACCOUNT_ERROR,
  ADMIN_CUSTOMER_UPDATE,
  ADMIN_IN_PROGRESS,
  ADMIN_RESET_STATES,
  ADMIN_LOAD_USER_PERMISSIONS,
  ADMIN_RESET_GET_PERMISSIONS,
} from "../actions/types";

const initialState = {
  companyProfile: {},
  companyUsers: [],
  logoUploadSuccess: false,
  customerNewDone: false,
  customerUpdateSuccess: false,
  inProgress: false,
  newData: null,
  needLogout: false,
  userId: null,

  loadinUsers: true,
  userFormatedPerms: null,
  loadingUserPerms: true,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_COMPANY_EDIT:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          ...payload,
        },
      };

    case ADMIN_COMPANY_LOAD:
      return {
        ...state,
        companyProfile: payload,
        companyEditDone: false,
      };

    case ADMIN_USERS_LOAD:
      return {
        ...state,
        companyUsers: payload,
        accountEditDone: false,
        customerNewDone: false,
        customerUpdateSuccess: false,
        loadinUsers: false,
      };

    case ADMIN_CUSTOMER_UPDATE:
      return {
        ...state,
        customerUpdateSuccess: true,
        newData: payload,
      };

    case ADMIN_ACCOUNT_ERROR:
      return {
        ...state,
        inProgress: false,
      };

    case ADMIN_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      };

    case ADMIN_RESET_STATES:
      return {
        ...state,
        companyEditDone: false,
        accountEditDone: false,
        logoUploadSuccess: false,
        customerNewDone: false,
        customerUpdateSuccess: false,
        inProgress: false,
        newData: null,
        needLogout: false,
        userId: null,
      };
    case ADMIN_LOAD_USER_PERMISSIONS:
      return {
        ...state,
        userFormatedPerms: payload,
        loadingUserPerms: false,
      };
    case ADMIN_RESET_GET_PERMISSIONS:
      return {
        ...state,
        userFormatedPerms: null,
        loadingUserPerms: true,
      };
    default:
      return state;
  }
}
