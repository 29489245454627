import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// FontAwesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./MessageBoard.scss";
import { iconPlus } from "../../components/Icons/Icons";
import IconButton from "../../components/IconButton/IconButton";
import { actionSetSession } from "../../redux/actions/action.session";

import {
  actionDeleteBoardItem,
  actionGetMsgBoards,
  actionToggleShowOnDb,
} from "../../redux/actions/action.messageboard";

import CustomSpinner2 from "../../components/CustomSpinner/CustomSpinner2";
import { dateWithDayNameAndTime } from "../../utils/toDates";
import AutoSizeTextArea from "../../components/AutoSizeTextArea/AutoSizeTextArea";
import { useHistory } from "react-router";
import ConfirmPopOver from "../../components/Popovers/ConfirmPopOver";
import { Badge, Button, Form } from "react-bootstrap";
import { useState } from "react";
import Sorting from "../../components/Filters/Sorting";

const MessageBoardCompany = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { messageBoards, loadingBoards } = useSelector(
    (state) => state.messageBoard
  );

  const filterOptions = [
    { label: "Kaikki", value: "all" },
    { label: "Näyttö linkitetty", value: "displays" },
  ];

  const [states, setStates] = useState({
    sortBy: "-createdAt",
    option: "all",
  });
  const { sortBy, option } = states;

  const addIcon = (
    <FontAwesomeIcon icon={iconPlus} className="addIcon" size="lg" />
  );

  useEffect(() => {
    dispatch(actionSetSession("", "/", true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filter = {
      sortBy,
      option,
    };
    dispatch(actionGetMsgBoards(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, option]);

  const newMsgItem = () => {
    history.push(`/company/one-board/new`);
  };

  const selectBoard = (board) => {
    history.push(`/company/one-board/${board._id}`);
  };

  const deleteBoard = (id) => {
    dispatch(actionDeleteBoardItem({ boardId: id }));
  };

  const toggleShowOnDb = (id) => {
    dispatch(actionToggleShowOnDb({ boardId: id }));
  };

  const setSort = (sort) => {
    setStates((state) => ({ ...state, sortBy: sort }));
  };

  const setOption = (e) => {
    setStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const msgCard = (card, i) => (
    <div className="msgCard" key={i} onClick={() => selectBoard(card)}>
      <div className="header">
        <div>
          <small>{dateWithDayNameAndTime(card.createdAt)}</small>
          <small>{dateWithDayNameAndTime(card.updatedat)}</small>
        </div>
        <div>
          <div />
          <p className="p-title">{card.title}</p>

          <div onClick={(e) => e.stopPropagation()}>
            {card.displays?.length ? (
              <Button variant="link" onClick={() => toggleShowOnDb(card._id)}>
                {card.showOnDisplay ? "Piilota" : "Näytä"}
              </Button>
            ) : null}
          </div>
        </div>
      </div>

      <div onClick={(e) => e.stopPropagation()}>
        <ConfirmPopOver
          title="Vahvista poisto"
          text="Ilmoitus poistetaan"
          btnText="Poista"
          action={() => deleteBoard(card._id)}
        />
      </div>

      <div className="content">
        <AutoSizeTextArea
          readOnly={true}
          value={card.description || "-"}
          rows="5"
        />
      </div>

      <div className="footer">
        <div>
          <>
            <p>
              <strong>
                {card.start ? dateWithDayNameAndTime(card.start) : "--:--"}
              </strong>
            </p>
            <p>
              <strong>
                {card.stop ? dateWithDayNameAndTime(card.stop) : "--:--"}
              </strong>
            </p>
          </>
        </div>
        <div>
          {card.customer ? (
            <Badge variant="success" pill>
              {card.customer.name}
            </Badge>
          ) : (
            <Badge variant="success" pill>
              -
            </Badge>
          )}
          {card.displays.length ? (
            <div>
              {card.displays.map((el, i) => (
                <Badge key={i} variant="success" pill>
                  {el.name}
                </Badge>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <div className="MessageBoard basicPage">
      <div className="filterDiv">
        <Sorting setSort={setSort} value={sortBy} />

        <Form.Group>
          <Form.Control
            as="select"
            name="option"
            onChange={setOption}
            value={option}
          >
            {filterOptions.map((el, i) => (
              <option value={el.value} key={i}>
                {el.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
      <div className="mainHeader">
        <h2>Ilmoitustaulu</h2>

        <IconButton
          icon={addIcon}
          text={null}
          onClick={newMsgItem}
          type="add"
        />
      </div>

      {loadingBoards ? (
        <CustomSpinner2 />
      ) : (
        <div className="items">
          {messageBoards.length ? (
            messageBoards.map((el, i) => msgCard(el, i))
          ) : (
            <p>Ei ilmoituksia</p>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBoardCompany;
