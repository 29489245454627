import { memo } from "react";
import { useSelector } from "react-redux";
import { intersection } from "lodash";

function HideCheckComponent(props) {
  const { required, field, children, atLeastOne } = props;

  const { permissions2 } = useSelector((state) => state.auth.user);

  if (!permissions2) {
    return null;
  }

  if (permissions2 && !permissions2[field]) {
    return null;
  }

  // pitää sisältää kaikki listassa olevat oikeudet
  if (!atLeastOne) {
    // palauttaa arrayn samoista arvoista
    // esim [1, 2, 3] [1,3] => [1,3]
    const matched = intersection(required, permissions2[field]);

    // tarkistetaan, että kaikki vaaditut (required) oikeudet löytyi
    if (matched.length !== required.length) {
      return null;
    }

    return children;
  } else {
    //riittää että on edes yksi oikus listatsta
    // palauttaa arrayn samoista arvoista
    // esim [1, 2, 3] [1,3] => [1,3]
    const matched = intersection(required, permissions2[field]);

    // tarkistetaan, että kaikki vaaditut (required) oikeudet löytyi
    if (matched.length === 0) {
      return null;
    }

    return children;
  }
}

export default memo(HideCheckComponent);
