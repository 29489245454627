import {
  DELETE_CONTACT,
  EMPTY_CONTACTS,
  GET_CONTACTS,
  MULTIPLE_CONTACTS_SUCCESS,
  NEW_CONTACT,
  UPDATE_CONTACT,
  UPDATE_MULTIPLE_CONTACTS,
} from "../types/contacts.types";

const initialState = {
  contacts: [],
  loading: true,

  multipleUpdated: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONTACTS:
      return {
        ...state,
        contacts: payload,
        loading: false,
      };
    case NEW_CONTACT:
      return {
        ...state,
        contacts: [payload, ...state.contacts],
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };
    case DELETE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter((el) => el._id !== payload._id),
      };

    case EMPTY_CONTACTS:
      return {
        ...state,
        contacts: [],
        loading: true,
      };

    case UPDATE_MULTIPLE_CONTACTS:
      return {
        ...state,
        contacts: state.contacts.map((el) =>
          payload.ids.some((el2) => el2 === el._id)
            ? {
                ...el,
                groups: [...payload.groups, ...el.groups],
              }
            : el
        ),
      };

    case MULTIPLE_CONTACTS_SUCCESS:
      return {
        ...state,
        multipleUpdated: payload,
      };

    default:
      return state;
  }
}
