// React & Routing
import React, { Fragment, useEffect, Suspense, lazy, useState } from "react";
import { getTranslate, Translate } from "react-localize-redux";

import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

// Redux
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import translations from "./translations.json";
import { withLocalize, setActiveLanguage } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import {
  actionLoadUser,
  actionSetOnlineStatus,
  actionGetAppData,
} from "./redux/actions/action.auth";

import { actionValidateLinkUser } from "./redux/actions/action.links";
// Pages
import Login from "./pages/Login/Login";
import ErrorBoundary from "./pages/ErrorBoundary/ErrorBoundary";

// COMPONENTS
import CustomSpinner from "./components/CustomSpinner/CustomSpinner";
import CustomNavBar from "./components/NavBar/CustomNavBar";
import Alert from "./components/Alert/Alert";
import Toast from "./components/Toast/Toast";
import CheckNewVersion from "./components/NewVersion/CheckNewVersion";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import UserChanged from "./components/UserChanged/UserChanged";

//import OfflineAlert from "./components/OfflineAlert/OfflineAlert";

// Utils
import setAuthToken from "./utils/setAuthToken";
import CustomSocket from "./utils/CustomSocket";

// Styling
import "./App.scss";
import { actionAlert } from "./redux/actions/action.alert";
import OneCustomerReport from "./pages/Report/customer/OneCustomerReport";
import CompanyChat from "./pages/ChatRooms/CompanyChat";
import MakeError from "./pages/MakeError/MakeError";
import ConsumerMenu from "./pages/MainMenu/ConsumerMenu";
import OneConsumerReport from "./pages/Report/consumer/OneConsumerReport";
import PrivateCompanyRoute from "./components/PrivateRoute/PrivateCompanyRoute";
import { Button } from "react-bootstrap";
import OneCompanyreport from "./pages/Report/Company/OneCompanyreport";
import OneCompanyFinalReport from "./pages/Report/Company/OneCompanyFinalReport";
import Folders from "./pages/Folders/Folders";
import Displays from "./pages/Display/Displays";
import OneMessageBoard from "./pages/MessageBoard/OneMessageBoard";
import DisplayHome from "./pages/DisplayUI/DisplayHome";
import MessageBoards from "./pages/MessageBoard/MessageBoards";
import Calendar3 from "./pages/Calendar/Calendar3";
import RegisterCompanyAndAdmin from "./pages/Register/RegisterCompanyAndAdmin";
import RegisterCompanyUser from "./pages/Register/RegisterCompanyUser";

import LinkUserChat from "./pages/LinkUser/Chat/LinkUserChat";
import useQueryParam from "./utils/useQueryParam";
import LinkUserSocket from "./utils/LinkUserSocket";
import Ask from "./components/Ask/Ask";
import Answers from "./components/Ask/Answers";
import LinkUserItems from "./pages/LinkUser/Item/LinkUserItems";
import MyItemSummary from "./pages/Item/MyItemSummary";

// VERIFY
const ForgotPw = lazy(() => import("./pages/ForgotPw/ForgotPw"));
const ResetPw = lazy(() => import("./pages/ForgotPw/ResetPw"));
const VerifyFirstUser = lazy(() =>
  import("./pages/Verify/company/VerifyFirstUser")
);
const VerifyCustomerUser = lazy(() =>
  import("./pages/Verify/customer/VerifyCustomerUser")
);
const VerifyCompanyUser = lazy(() =>
  import("./pages/Verify/company/VerifyCompanyUser")
);
const VerifyConsumer = lazy(() =>
  import("./pages/Verify/consumer/VerifyConsumer")
);

const ForgotPwComp = lazy(() => import("./pages/ForgotPw/ForgotPwComp"));
const ResetPwComp = lazy(() => import("./pages/ForgotPw/ResetPwComp"));

// MENUS
const MainMenu = lazy(() => import("./pages/MainMenu/MainMenu"));

// ITEM
const Item = lazy(() => import("./pages/Item/Item"));
const MyItems = lazy(() => import("./pages/MyItems/MyItems"));
const NewItem = lazy(() => import("./pages/NewItem/NewItem"));

// PROFILE
const MyProfile = lazy(() => import("./pages/MyProfile/MyProfile"));
const CompanyProfile = lazy(() =>
  import("./pages/CompanyProfile/CompanyProfile")
);

// CONTROL
const UserControl = lazy(() =>
  import("./pages/UserControlv2/company/UserControl")
);
const AddUser = lazy(() =>
  import("./pages/UserControlv2/company/AddUser/AddUser")
);
const AddCustomerUser = lazy(() =>
  import("./pages/CustomerControl/AddCustomer/AddCustomerUser")
);
const CompanyCustomers = lazy(() =>
  import("./pages/CustomerControl/CompanyCustomers/CompanyCustomers")
);
const CustomerUserControl = lazy(() =>
  import("./pages/UserControlv2/customer/CustomerUserControl")
);

// V2
const CustomersControl = lazy(() =>
  import("./pages/CustomerControl/CustomersControl")
);
const OneCustomer = lazy(() => import("./pages/CustomerControl/OneCustomer"));
const OneCustomerGroup = lazy(() =>
  import("./pages/CustomerControl/OneCustomerGroup")
);

// PDF
const PdfReportsPage = lazy(() => import("./pages/Pdf/PdfReportsPage"));

// OTHER
const Changelog = lazy(() => import("./pages/Changelog/Changelog"));
const PartsPage = lazy(() => import("./pages/CompanyData/PartsPage"));
const Help = lazy(() => import("./pages/Help/Help"));
const Feedback = lazy(() => import("./pages/Feedback/Feedback"));

const MessageBoard = lazy(() => import("./pages/MessageBoard/MessageBoard"));

const Modals2 = lazy(() => import("./components/Modals/Modals2"));

const Tags = lazy(() => import("./pages/Tags/Tags"));

// CALENDAR
//const EventCalendar = lazy(() => import("./pages/Calendar/Calendar"));
const EventCalendar2 = lazy(() => import("./pages/Calendar/Calendar2"));

// CHAT ROOMS
const ChatRooms = lazy(() => import("./pages/ChatRooms/ChatRooms"));
const OneChatRoom = lazy(() => import("./pages/ChatRooms/OneChatRoom"));

// CONSUMER

const ConsumerItems = lazy(() => import("./pages/Item/ConsumerItems"));
const ConsumerItem = lazy(() => import("./pages/Item/ConsumerItem"));
const ConsumerReports = lazy(() =>
  import("./pages/Report/consumer/ConsumerReports")
);

//const LinkUserMain = lazy(() => import("./pages/LinkUser/LinkUserMain"));

//const x = lazy(() => import('./pages'))

const App = ({
  initialize,
  language,
  isAuthenticated,
  companyId,

  actionLoadUser,
  actionGetAppData,
  setActiveLanguage,
  userStatusChanged,
  actionSetOnlineStatus,
  actionAlert,
  translate,
  userGroup,
  permissions2,
  toasts,
  userId,
  actionValidateLinkUser,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [token] = useQueryParam("token");

  const errors = useSelector((state) => state.axiosErrors);
  const { itemRedirectUrl } = useSelector((state) => state.item);

  const {
    linkValidated,
    decodedLink,
    loadingValidate,
    error: linkUserError,
  } = useSelector((state) => state.linkUser);

  const [states, setStates] = useState({
    showInstallBtn: false,
  });
  const { showInstallBtn } = states;
  // componentDidMount
  useEffect(() => {
    setAuthToken(localStorage.token);

    const isOnline = navigator.onLine;
    actionSetOnlineStatus(isOnline);

    if (!isAuthenticated && !token) {
      // id on token jota esim link-user käyttää tai sitten esim salasanan palautus
      // ei tarvitse ladata silloin

      actionLoadUser();
    }

    // window.addEventListener("online", handleNetworkChange);
    //window.addEventListener("offline", handleNetworkChange);

    // Kieli kirjaston alustaminen
    initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Suomi", code: "fi" },
      ],
      translation: translations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: "fi",
      },
    });

    window.addEventListener("DOMContentLoaded", () => {
      let displayMode = "browser tab";
      if (navigator.standalone) {
        displayMode = "standalone-ios";
      }
      if (window.matchMedia("(display-mode: standalone)").matches) {
        displayMode = "standalone";
      }
      // Logitetaan sovelluksen käyttötapa
      console.log("DISPLAY_MODE_LAUNCH:", displayMode);
    });

    window.addEventListener("DOMContentLoaded", () => {
      window.matchMedia("(display-mode: standalone)").addListener((evt) => {
        let displayMode = "browser tab";
        if (evt.matches) {
          displayMode = "standalone";
        }
        // Logitetaan sovelluksen käyttötavan muutos
        console.log("DISPLAY_MODE_CHANGED", displayMode);
      });
    });

    let deferredPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setStates((state) => ({ ...state, showInstallBtn: true }));
      deferredPrompt = e;
    });

    const installApp = document.getElementById("installButton");

    if (installApp) {
      installApp.addEventListener("click", async () => {
        if (deferredPrompt !== null) {
          deferredPrompt.prompt();
          const { outcome } = await deferredPrompt.userChoice;
          if (outcome === "accepted") {
            deferredPrompt = null;
            setStates((state) => ({ ...state, showInstallBtn: false }));
          } else {
            setStates((state) => ({ ...state, showInstallBtn: false }));
          }
        }
      });
    }

    /* return () => {
      window.removeEventListener('online', handleNetworkChange)
      window.removeEventListener('offline', handleNetworkChange)

    }*/

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      console.log("validate link app");

      actionValidateLinkUser({ token: token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (language !== undefined) {
      setActiveLanguage(language);
    }
  }, [language, setActiveLanguage]);

  useEffect(() => {
    if (userGroup) {
      if (userGroup > 10) {
        // voidaan ladata myös yrityksen
        // ei vielä käytössä
        actionGetAppData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup]);

  useEffect(() => {
    if (itemRedirectUrl) {
    //  history.push(itemRedirectUrl);
    }
  }, [itemRedirectUrl]);

  const versionNumber = process.env.REACT_APP_VERSION;

  window.addEventListener("appinstalled", (evt) => {
    // Logitetaan onnistunut asennus
    console.log("INSTALL: Success");
  });
  useEffect(() => {
    if (errors && errors.showType === "alert") {
      // errors on "pää kenttä" errors.data.item sisältää mikä "collection" ja "status" on käänöstiedoston virhe viesti
      if (!errors.data.errors) {
        // isValidation tarkoittaa expressValidation pakettia
        actionAlert(
          translate(`errors.${[errors.data.item]}.${[errors.status]}`),
          "danger"
        );
      } else {
        // express 'check' virheet
        const data = errors.data.errors;
        // msg itse määritetty location expressiltä (body) ja param kentän nimi esim. title
        data.forEach((error) =>
          actionAlert(
            translate(
              `errors.${[error.msg]}.${[error.location]}.${[error.param]}`
            ),
            "danger"
          )
        );
      }
    }

    // uusi KATSO handleErrors (action folder)
    if (errors && errors.errors && errors.type) {
      if (errors.type === "one") {
        actionAlert(<Translate id={`${errors.errors}`} />, "danger");
      } else if (errors.type === "array") {
        // express validator errors
        errors.errors.forEach((error) => {
          actionAlert(<Translate id={`${error.msg}`} />, "danger");
        });
      }

      if (errors.redirectUrl) {
        history.replace(errors.redirectUrl);
      } else if (errors.reloadPage) {
        window.location.reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (userStatusChanged) {
    return <UserChanged />;
  }

  /*if (!isOnline) {
    return <OfflineAlert />;
  }*/

  const companyRoutes = (
    <>
      <PrivateCompanyRoute
        exact
        path="/company/user-control"
        component={UserControl}
        isAuth={isAuthenticated}
        usePermissions={true}
        userGroup={userGroup}
        pagePermissions={permissions2 && permissions2.userControl}
      />

      <PrivateCompanyRoute
        exact
        path="/company/board"
        component={MessageBoard}
        isAuth={isAuthenticated}
        usePermissions={true}
        userGroup={userGroup}
        pagePermissions={permissions2 && permissions2.msgBoard}
      />

      <PrivateCompanyRoute
        exact
        path="/company/one-board/:boardId"
        component={OneMessageBoard}
        isAuth={isAuthenticated}
        usePermissions={true}
        userGroup={userGroup}
        pagePermissions={permissions2 && permissions2.msgBoard}
      />

      <PrivateCompanyRoute
        exact
        path="/parts"
        component={PartsPage}
        isAuth={isAuthenticated}
        usePermissions={true}
        userGroup={userGroup}
        pagePermissions={permissions2 && permissions2.partList}
      />

      <PrivateCompanyRoute
        exact
        path="/tags"
        component={Tags}
        isAuth={isAuthenticated}
        usePermissions={false}
        userGroup={userGroup}
        // pagePermissions={permissions2 && permissions2.partList}
      />

      <PrivateCompanyRoute
        exact
        path="/item/:itemId/company/report/:reportId"
        component={OneCompanyreport}
        isAuth={isAuthenticated}
        usePermissions={true}
        userGroup={userGroup}
        pagePermissions={permissions2 && permissions2.reports}
      />

      <PrivateCompanyRoute
        exact
        path="/item/:itemId/company/final/:reportId"
        component={OneCompanyFinalReport}
        isAuth={isAuthenticated}
        usePermissions={true}
        userGroup={userGroup}
        pagePermissions={permissions2 && permissions2.reports}
      />

      <PrivateCompanyRoute
        exact
        path="/folders/:folderId?"
        component={Folders}
        isAuth={isAuthenticated}
        usePermissions={false}
        userGroup={userGroup}
      />

      <PrivateCompanyRoute
        exact
        path="/displays"
        component={Displays}
        isAuth={isAuthenticated}
        usePermissions={false}
        userGroup={userGroup}
      />

      <PrivateCompanyRoute
        exact
        path="/customers-control/:type?"
        component={CustomersControl}
        isAuth={isAuthenticated}
        usePermissions={false}
        userGroup={userGroup}
      />

      <PrivateCompanyRoute
        exact
        path="/customers-control/customer/:customerId"
        component={OneCustomer}
        isAuth={isAuthenticated}
        usePermissions={false}
        userGroup={userGroup}
      />
      <PrivateCompanyRoute
        exact
        path="/customers-control/group/:groupId"
        component={OneCustomerGroup}
        isAuth={isAuthenticated}
        usePermissions={false}
        userGroup={userGroup}
      />
    </>
  );

  if (isAuthenticated && !userGroup && userId) {
    return (
      <>
        <CustomSocket isAuthenticated={isAuthenticated} companyId={companyId} />
        <CustomNavBar>
          <Suspense fallback={<CustomSpinner color="blue" />}>
            <Route exact path="/display/home" component={DisplayHome} />
          </Suspense>
        </CustomNavBar>
        <p className="versionNumber">{`${versionNumber} ${
          window.location.hostname === "localhost"
            ? "LH"
            : window.location.hostname.includes("jaretesti")
            ? "JT"
            : ""
        }`}</p>
      </>
    );
  } else if (!loadingValidate && linkValidated && decodedLink) {
    return (
      <Suspense fallback={<CustomSpinner color="blue" />}>
        <LinkUserSocket
          linkValidated={linkValidated}
          decodedLink={decodedLink}
        />
        <Modals2 />

        <CustomNavBar>
          <p className="link-user-name">
            <strong>NIMI:</strong>
            {decodedLink.email}
          </p>

          <Route exact path="/link-user/items" component={LinkUserItems} />
          <Route
            exact
            path="/link-user/chats/:chatId"
            component={LinkUserChat}
          />
        </CustomNavBar>
        <p className="versionNumber">{`${versionNumber} ${
          window.location.hostname === "localhost"
            ? "LH"
            : window.location.hostname.includes("jaretesti")
            ? "JT"
            : ""
        }`}</p>

        <div className="alertContainer">
          <Alert />
        </div>
      </Suspense>
    );
  }

  if (linkUserError?.status) {
    const errorText =
      linkUserError?.message === "jwt expired"
        ? "Linkki vanhentunut"
        : "Virhe linkissä";

    return (
      <div className="basicPage">
        <h2>VIRHE: {errorText}</h2>
      </div>
    );
  }

  return (
    <Fragment>
      <ErrorBoundary>
        {isAuthenticated && userId ? (
          <CustomSocket
            isAuthenticated={isAuthenticated}
            companyId={companyId}
          />
        ) : null}

        <CheckNewVersion />

        {showInstallBtn && location.pathname === "/" ? (
          <Button id="installButton" className="primaryBtn">
            Asenna
          </Button>
        ) : (
          <Button
            id="installButton"
            variant="success"
            style={{ display: "none" }}
          >
            Asenna
          </Button>
        )}

        <div className="toastContainer">
          {toasts !== null && toasts !== undefined && toasts.length > 0 ? (
            <Toast />
          ) : null}
        </div>

        <div className="alertContainer">
          <Alert />
        </div>

        <Ask />
        <Answers />

        <CustomNavBar>
          <Suspense fallback={<CustomSpinner color="blue" />}>
            <Modals2 />
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={MainMenu}
                isAuth={isAuthenticated}
                userGroup={userGroup}
              />

              <PrivateRoute
                exact
                path="/consumer"
                component={ConsumerMenu}
                isAuth={isAuthenticated}
                userGroup={userGroup}
              />

              <PrivateRoute
                exact
                path="/consumer/items"
                component={ConsumerItems}
                isAuth={isAuthenticated}
                userGroup={userGroup}
              />
              <PrivateRoute
                exact
                path="/consumer/item/:itemId"
                component={ConsumerItem}
                isAuth={isAuthenticated}
                userGroup={userGroup}
              />
              <PrivateRoute
                exact
                path="/items/my-summary"
                component={MyItemSummary}
                isAuth={isAuthenticated}
                userGroup={userGroup}
              />

              <PrivateRoute
                exact
                path="/consumer/item/:itemId/reports"
                component={ConsumerReports}
                isAuth={isAuthenticated}
                userGroup={userGroup}
              />

              <PrivateRoute
                exact
                path="/item/:id/consumer/report/:reportId"
                component={OneConsumerReport}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/help"
                component={Help}
                isAuth={isAuthenticated}
              />
              <PrivateRoute
                exact
                path="/changelog"
                component={Changelog}
                isAuth={isAuthenticated}
              />
              <PrivateRoute
                exact
                path="/item/:id"
                component={Item}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/item/:id/customer/report/:reportId"
                component={OneCustomerReport}
                isAuth={isAuthenticated}
              />
              <PrivateRoute
                exact
                path="/myitems"
                component={MyItems}
                isAuth={isAuthenticated}
              />
              <PrivateRoute
                exact
                path="/new-item"
                component={NewItem}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/myprofile"
                component={MyProfile}
                isAuth={isAuthenticated}
              />
              <PrivateRoute
                exact
                path="/companyprofile"
                component={CompanyProfile}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/company/user-control/add"
                component={AddUser}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/pdf/:type/:selectedId"
                component={PdfReportsPage}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/company/calendar"
                component={Calendar3}
                isAuth={isAuthenticated}
              />
              <PrivateRoute
                exact
                path="/company/calendar2"
                component={EventCalendar2}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/chat/rooms"
                component={ChatRooms}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/chat/rooms/:roomId"
                component={OneChatRoom}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/chat/company-chat/:companyId"
                component={CompanyChat}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/company/customers-control"
                component={CompanyCustomers}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/customer/user-control"
                component={CustomerUserControl}
                isAuth={isAuthenticated}
              />
              <PrivateRoute
                exact
                path="/customer/add"
                component={AddCustomerUser}
                isAuth={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/message-boards"
                component={MessageBoards}
                isAuth={isAuthenticated}
                userGroup={userGroup}
              />

              <Route
                exact
                path="/company/first-registration"
                component={RegisterCompanyAndAdmin}
              />

              <Route
                exact
                path="/company/verify"
                component={VerifyCompanyUser}
              />
              <Route
                exact
                path="/company/user-registration"
                component={RegisterCompanyUser}
              />
              <Route exact path="/consumer/verify" component={VerifyConsumer} />

              <Route
                exact
                path="/customer/verify"
                component={VerifyCustomerUser}
              />
              <Route exact path="/admin/verify" component={VerifyFirstUser} />

              {/*<Route exact path="/register" component={Register} />*/}
              <Route exact path="/customer/forgotpw" component={ForgotPw} />
              <Route exact path="/company/forgot-pw" component={ForgotPwComp} />
              <Route exact path="/reset/:token" component={ResetPw} />

              <Route
                exact
                path="/company/reset/:token"
                component={ResetPwComp}
              />

              <Route exact path="/login" component={Login} />
              <Route exact path="/feedback" component={Feedback} />

              <Route exact path="/error123" component={MakeError} />

              <Route
                exact
                path="/display/home/:dbId?"
                component={DisplayHome}
              />

              {companyRoutes}

              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Suspense>
        </CustomNavBar>

        <p className="versionNumber">{`${versionNumber} ${
          window.location.hostname === "localhost"
            ? "LH"
            : window.location.hostname.includes("jaretesti")
            ? "JT"
            : ""
        }`}</p>
      </ErrorBoundary>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userId: state.auth.user._id,
  userStatusChanged: state.auth.user.userStatusChanged,
  companyId: state.auth.user.companyId,
  customerCompany: state.auth.user.customerCompany,
  language: state.auth.user.language,
  userGroup: state.auth.user.userGroup,
  permissions2: state.auth.user.permissions2,
  translate: getTranslate(state.localize),
  toasts: state.toast,
});

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  userId: PropTypes.string,
  companyId: PropTypes.string,
  language: PropTypes.string,
};

export default connect(mapStateToProps, {
  actionLoadUser,
  actionValidateLinkUser,
  actionGetAppData,
  setActiveLanguage,
  actionSetOnlineStatus,
  actionAlert,
})(withLocalize(App));
