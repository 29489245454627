import { intersection } from "lodash";

export function havePermissions(permissions2, field, required) {
  if (!permissions2) {
    return false;
  }

  if (permissions2 && !permissions2[field]) {
    return false;
  }

  // palauttaa arrayn samoista arvoista
  // esim [1, 2, 3] [1,3] => [1,3]
  const matched = intersection(required, permissions2[field]);

  // tarkistetaan, että kaikki vaaditut (required) oikeudet löytyi
  if (matched.length === required.length) {
    return true;
  }

  return false;
}

// return "access"
// palauttaa objectin, jolla kentän nimi ja arvo true/false
// esim. { item: true }
// nähin on pääsy menussa eli ei ole lukon iconia
export function menuPermissions(permissions2) {
  let access = {};
  for (const field in permissions2) {
    let temp = permissions2[field];

    // item on poikkeus, koska "uusi tilaus nappi" menussa
    if (field === "item") {
      if (temp.includes("READ")) {
        access[field] = true;
      }
      if (temp.includes("NEW")) {
        // tälle on oma kohtansa menussa
        access["newItem"] = true;
      }
    } else {
      if (temp.includes("READ")) {
        access[field] = true;
      }
    }
  }

  return access;
}
