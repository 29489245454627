import axios from "axios";

import proxy from "../../utils/proxy";
import {
  COMPANYDATA_DELETE_PART,
  COMPANYDATA_GET_PARTS,
  COMPANYDATA_NEW_PART,
  COMPANYDATA_RESET_STATES,
  COMPANYDATA_Update_PART,
} from "../types/companyData.types";

import handleErrors from "./handleErrors";

export const actionGetParts = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${proxy}/api/company/data/get-parts`, config);

    if (res.status === 200) {
      dispatch({
        type: COMPANYDATA_GET_PARTS,
        payload: res.data,
      });

      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionNewPart = ({
  title,
  author,
  code,
  purchasePrice,
  salesPrice,
  coveringPercent,
  manuf,
  supplier,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    title,
    author,
    code,
    purchasePrice,
    salesPrice,
    coveringPercent,
    manuf,
    supplier,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/data/new-part`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANYDATA_NEW_PART,
        payload: res.data,
      });

      return false;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return true;
  }
};

export const actionDeletePart = ({ partId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/data/delete-part/${partId}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANYDATA_DELETE_PART,
        payload: res.data,
      });
      return false;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return true;
  }
};

export const actionUpdatePart = ({
  partId,
  title,
  author,
  code,
  purchasePrice,
  salesPrice,
  coveringPercent,
  manuf,
  supplier,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    partId,
    title,
    author,
    code,
    purchasePrice,
    salesPrice,
    coveringPercent,
    manuf,
    supplier,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/data/update-part`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANYDATA_Update_PART,
        payload: res.data,
      });
      return false;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return true;
  }
};

export const actionResetCompanyDataStates = () => async (dispatch) => {
  dispatch({ type: COMPANYDATA_RESET_STATES });
};
