import axios from "axios";
//import store from "../../store";
import proxy from "../../utils/proxy";
import {
  GET_COMPANIES_LIST,
  UPDATE_MY_CUSTOMER_COMPANY,
} from "../types/customer.types";
import { ITEM_UPDATE } from "../types/item.types";
import { actionSocketBasic2 } from "./action.socket";
import handleErrors from "./handleErrors";
import { CUSTOMER_CLEAR, CUSTOMER_LOAD_COMPANY } from "./types";

// Hae oman asiakas yrityksen käyttäjien tiedot (Admin)
export const actionGetMyCustomerCompany = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/customer/customer-company/get-my-customer-company`,

      config
    );

    if (res.status === 200) {
      dispatch({
        type: CUSTOMER_LOAD_COMPANY,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error(err.message);
    dispatch(handleErrors(err));
  }
};

export const actionGetCompanies = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/customer/company/get-all`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_COMPANIES_LIST,
        payload: res.data,
      });

      return res.data;
    }
  } catch (err) {
    console.error(err.message);
    dispatch(handleErrors(err));
  }
};

export const actionUpdateMyCustomerCompany = ({
  name,
  phone,
  email,
  address,
  city,
  zip,
  desc,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    phone,
    email,
    address,
    city,
    zip,
    name,
    desc,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/customer/customer-company/update-my-customer-company`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_MY_CUSTOMER_COMPANY,
        payload: res.data,
      });
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Tyhjennä kaikki company tiedot
export const actionClearCustomer = () => (dispatch) => {
  dispatch({
    type: CUSTOMER_CLEAR,
  });
};

export const actionCustomerUploadFile = ({
  myFile,
  fileExtension,
  legend,
  folder,
  id,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  let formData = new FormData();
  formData.append("myFile", myFile);
  formData.set("extension", fileExtension);
  formData.set("legend", legend);

  try {
    const res = await axios.post(
      `${proxy}/api/customer/customer-company/upload-file/${folder}/${id}`,
      formData,
      config
    );

    if (res.status === 200) {
      if (folder === "item") {
        dispatch({
          type: ITEM_UPDATE,
          payload: {
            data: {
              history: res.data.data.history,
            },
          },
        });
        dispatch(actionSocketBasic2(res.data.socket));

        return res.data.data;
      }
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionDeleteFileCustomer = ({ folder, id, name, uuid }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/customer/customer-company/delete-file/${folder}/${id}/${name}/${uuid}`,
      config
    );

    if (res.status === 200) {
      if (folder === "item") {
        dispatch({
          type: ITEM_UPDATE,
          payload: {
            event: "delete_file",
            data: {},
          },
        });
      }

      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};
