import axios from "axios";
import proxy from "../../utils/proxy";
import {
  CALENDAR_GET_EVENTS,
  SET_CALENDAR_EVENTS,
} from "../types/calendar.types";
import handleErrors from "./handleErrors";

export const actionGetCalendarEvents = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/calendar/get-events`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: CALENDAR_GET_EVENTS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetCalendarPersonalItems = (userId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/calendar/get-personal-items/${userId}`,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetMyCalendar = ({ filter }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      filter,
    });

    const res = await axios.post(
      `${proxy}/api/company/calendar/get-my-calendar`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: CALENDAR_GET_EVENTS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionSetLoadingAndEvents = (events, loading) => async (
  dispatch
) => {
  dispatch({
    type: SET_CALENDAR_EVENTS,
    payload: { events, loading },
  });
};
