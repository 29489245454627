import React, { useEffect, useState } from 'react';
import * as serviceWorker from '../../serviceWorkerRegistration';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CheckNewVersion= () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const modal = {
    title: "Uusi versio saatavilla!",
    text: "Ole hyvä ja päivitä sovellus."
  }

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
   if (waitingWorker){
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
   } else {
     console.error('VIRHE localhost?')
   }
  };

  return (
      <UpdateModal show={showReload} reloadPage={reloadPage} modal={modal}/>
  );
}

export default CheckNewVersion

function UpdateModal(props){
  const {show, reloadPage, modal} = props;

  return(
    <Modal
    show={show}
    modal={modal}
    backdrop="static"
    size="sm"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header>
        <Modal.Title>
          {modal.title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{modal.text}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={reloadPage} variant="primary">
          Päivitä
        </Button>
      </Modal.Footer>

    </Modal>
  )
}

/*
<Alert show={showReload} variant="warning">
        <Alert.Heading>Uusi versio saatavilla</Alert.Heading>
        <p>
          Päivitä sovellus painamalla "Päivitä nyt"
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button variant="danger" onClick={reloadPage}>
            Päivitä nyt
          </Button>
        </div>
      </Alert>*/