import axios from "axios";
import proxy from "../../utils/proxy";
import {
  CONSUMER_CLEAR_ITEM,
  CONSUMER_GET_ITEMS,
  CONSUMER_GET_ITEM_REPORTS,
  CONSUMER_SELECT_ITEM,
  CONSUMER_SELECT_REPORT,
} from "../types/consumer.types";
import handleErrors from "./handleErrors";
import { UPDATE_USER } from "./types";

export const actionConsumerGetItems = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${proxy}/api/consumer/get-items`, config);

    if (res.status === 200) {
      dispatch({
        type: CONSUMER_GET_ITEMS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionConsumerSelectItem = (item) => (dispatch) => {
  dispatch({
    type: CONSUMER_SELECT_ITEM,
    payload: item,
  });
};

export const actionConsumerClearItem = (item) => (dispatch) => {
  dispatch({
    type: CONSUMER_CLEAR_ITEM,
    payload: item,
  });
};

export const actionConsumerGetItemReports = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.get(
    `${proxy}/api/consumer/get-item-reports/${id}`,
    config
  );

  dispatch({
    type: CONSUMER_GET_ITEM_REPORTS,
    payload: res.data,
  });
};

export const actionConsumerSelectReport = (report) => (dispatch) => {
  dispatch({
    type: CONSUMER_SELECT_REPORT,
    payload: report,
  });
};

export const actionConsumerGetItemReportById = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.get(
    `${proxy}/api/consumer/get-item-report/${id}`,
    config
  );

  dispatch({
    type: CONSUMER_SELECT_REPORT,
    payload: res.data,
  });
};

export const actionConsumerGetItemById = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${proxy}/api/consumer/get-item-by-id/${id}`,
      config
    );

    dispatch({
      type: CONSUMER_SELECT_ITEM,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionConsumerUpdateProfile = ({
  email,
  firstName,
  lastName,
  companyName,
  address,
  city,
  zip,
  phone,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email,
    firstName,
    lastName,
    companyName,
    address,
    city,
    zip,
    phone,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/consumer/update-profile`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};
