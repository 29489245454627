import { useDispatch, useSelector } from "react-redux";
import { actionAskEmptyAnswers } from "../../redux/actions/action.ask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconFail } from "../Icons/Icons";

import "./Ask.scss";
import { dateWithDayNameAndTime } from "../../utils/toDates";

const Answers = () => {
  const dispatch = useDispatch();
  const { askAnswers } = useSelector((state) => state.ask);

  if (!askAnswers) {
    return null;
  }

  if (askAnswers && !askAnswers.length) {
    return null;
  }

  const clearAsnwers = () => {
    dispatch(actionAskEmptyAnswers());
  };

  return (
    <div className="Answers">
      <FontAwesomeIcon
        onClick={clearAsnwers}
        icon={iconFail}
        className="clearIcon"
      />
      {askAnswers.map((el, i) => (
        <div className="box" key={i}>
          <div className="header">
            <p>{el.answer.fromName}</p>
            <p>{dateWithDayNameAndTime(el.answer.date)}</p>
          </div>

          <p>
            <strong>{el.question?.text}</strong>
          </p>
          <p>{el.question?.text2}</p>
          {el.answer.text === "yes" ? (
            <p className="p-yes">Kyllä</p>
          ) : el.answer.text === "no" ? (
            <p className="p-no">EI</p>
          ) : (
            <p>{el.answer.text}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Answers;
