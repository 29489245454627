export const GET_MSG_BOARDS = "GET_MSG_BOARDS";
export const NEW_BOARD_ITEM = "NEW_BOARD_ITEM";
export const UPDATE_MSG_BOARD = "UPDATE_MSG_BOARD";
export const DELETE_BOARD_ITEM = "DELETE_BOARD_ITEM";

export const UPDATE_PARTICIPATE_MSG_ITEM = "UPDATE_PARTICIPATE_MSG_ITEM";

export const RESET_MSG_BOARD_STATES = "RESET_MSG_BOARD_STATES";
export const INITIAL_MSG_BOARD_STATES = "INITIAL_MSG_BOARD_STATES";

export const SET_SELECTED_BOARD = "SET_SELECTED_BOARD";
