import {
  SET_SOCKET,
  SOCKET_BASIC,
  SOCKET_CHAT,
  SOCKET_DISPLAY,
  SOCKET_INITIALSTATES,
  SOCKET_ITEM,
  SOCKET_NEED_LOG_OUT,
} from "../types/socket.types";

export const actionSetSocket = (socket) => (dispatch) => {
  dispatch({
    type: SET_SOCKET,
    payload: socket,
  });
};

export const actionSocketItem = ({
  event,
  subEvent,
  itemTitle,
  link,
  tab,
  toCompany,
  toCustomer,
  toUsers,
  itemData,
  author,
  responsibles,
}) => (dispatch) => {
  dispatch({
    type: SOCKET_ITEM,
    payload: {
      event,
      subEvent,
      itemTitle,
      link,
      tab,
      toCompany,
      toCustomer,
      toUsers,
      itemData,
      author,
      responsibles,
    },
  });
};

export const actionSocketChat = ({
  event,
  subEvent,
  chatName,
  link,
  toCompany,
  toCustomer,
  toUsers,
  chatMessage,
  companyChat,
  author,
  itemChat,
}) => (dispatch) => {
  dispatch({
    type: SOCKET_CHAT,
    payload: {
      event,
      subEvent,
      chatName,
      link,
      toCompany,
      toCustomer,
      toUsers,
      chatMessage,
      companyChat,
      author,
      itemChat,
    },
  });
};

export const actionSocketBasic = ({
  event,
  subEvent,
  title,
  toCompany,
  toCustomer,
  author,
  data,
}) => (dispatch) => {
  dispatch({
    type: SOCKET_BASIC,
    payload: {
      event,
      subEvent,
      title,
      toCompany,
      toCustomer,
      author,
      data,
    },
  });
};

export const actionSocketBasic2 = ({
  event,
  subEvent,
  header,
  title,
  toCompany,
  toCustomer,
  author,
  toUsers,
  link,
  companyLink,
  customerLink,
  response,
  logo,
}) => (dispatch) => {
  dispatch({
    type: SOCKET_BASIC,
    payload: {
      event,
      subEvent,
      header,
      title,
      toCompany,
      toCustomer,
      author,
      toUsers,
      link,
      companyLink,
      customerLink,
      response,
      logo,
    },
  });
};

export const actionSocketDisplay = ({ event, subEvent, displayIds, data }) => (
  dispatch
) => {
  dispatch({
    type: SOCKET_DISPLAY,
    payload: {
      event,
      subEvent,
      display: true,
      displayIds,
      data,
    },
  });
};

export const actionSocketInitialStates = () => (dispatch) => {
  dispatch({
    type: SOCKET_INITIALSTATES,
  });
};

export const actionSocketNeedLogOut = (userId) => (dispatch) => {
  dispatch({
    type: SOCKET_NEED_LOG_OUT,
    payload: {
      toUsers: [userId],
    },
  });
};
