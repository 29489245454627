// React & Routing
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

// Redux
import { Provider } from 'react-redux'
import { LocalizeProvider } from "react-localize-redux"
import store from './store'

// Components
import App from './App.jsx'

ReactDOM.render(<Provider store={store}><LocalizeProvider store={store}><BrowserRouter><App /></BrowserRouter></LocalizeProvider></Provider>, document.getElementById('root'))
