export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";

export const SET_TOAST = "SET_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const CLEAR_TOASTS = "CLEAR_TOASTS";
export const UPDATE_TOAST = "UPDATE_TOAST";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_OPEN_FORM = "REGISTER_OPEN_FORM";

export const UPDATE_USER = "UPDATE_USER";
export const LOAD_USER = "LOAD_USER";
export const AUTH_ERROR = "AUTH_ERROR";

export const GET_PUSH_SUB_STATUS = "GET_PUSH_SUB_STATUS";
export const PUSH_SUB_ERROR = "PUSH_SUB_ERROR";
export const TOGGLE_EMAIL_SUB = "TOGGLE_EMAIL_SUB";
export const DELETE_PUSH_SUB = "DELETE_PUSH_SUB";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const SET_SESSION = "SET_SESSION";
export const SET_PAGE_BOTTOM = "SET_PAGE_BOTTOM";
export const LOCK_PAGE_BOTTOM = "LOCK_PAGE_BOTTOM";

export const CLEAR_COMPANY = "CLEAR_COMPANY";

export const ITEM_FAIL = "ITEM_FAIL";
export const VALIDATION_ERRORS = "VALIDATION_ERRORS";
export const RESET_VALIDATION_ERRORS = "RESET_VALIDATION_ERRORS";
export const ITEM_CLEAR = "ITEM_CLEAR";

export const CHECK_UPDATES = "CHECK_UPDATES";
export const CHECK_FAIL = "CHECK_FAIL";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const CLEAR_UPLOAD_STATUS = "CLEAR_UPLOAD_STATUS";

export const SELECT_TAB = "SELECT_TAB";
export const SET_SWITCH = "SET_SWITCH";
export const CLEAR_TAB = "CLEAR_TAB";

export const ADMIN_USERS_LOAD = "ADMIN_USERS_LOAD";
export const ADMIN_COMPANY_LOAD = "ADMIN_COMPANY_LOAD";
export const ADMIN_COMPANY_EDIT = "ADMIN_COMPANY_EDIT";
export const ADMIN_ACCOUNT_ERROR = "ADMIN_ACCOUNT_ERROR";
export const COMPANY_CUSTOMERS = "COMPANY_CUSTOMERS";
export const COMPANY_SELECT_CUSTOMER = "COMPANY_SELECT_CUSTOMER";
export const COMPANY_NEW_CUSTOMER = "COMPANY_NEW_CUSTOMER";
export const ADMIN_CUSTOMER_UPDATE = "ADMIN_CUSTOMER_UPDATE";
export const ADMIN_LOAD_USER_PERMISSIONS = "ADMIN_LOAD_USER_PERMISSIONS";

export const CUSTOMER_ADMIN_ACCOUNT_EDIT = "CUSTOMER_ADMIN_ACCOUNT_EDIT";
export const CUSTOMER_ADMIN_LOAD_USERS = "CUSTOMER_ADMIN_LOAD_USERS";

export const CUSTOMER_LOAD_COMPANY = "CUSTOMER_LOAD_COMPANY";
export const CUSTOMER_CLEAR = "CUSTOMER_CLEAR";

export const FINALIZE_USER = "FINALIZE_USER";

export const SET_RESPONSIBLE = "SET_RESPONSIBLE";

export const NEW_CHECKLIST = "NEW_CHECKLIST";
export const UPDATE_CHECKLIST_NOTE = "UPDATE_CHECKLIST_NOTE";

export const GET_COMPANY_CONTACTS = "GET_COMPANY_CONTACTS";
export const NEW_COMPANY_CONTACT = "NEW_COMPANY_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const NOT_VALID_VERIFY = "NOT_VALID_VERIFY";

export const CLEAR_CHECKLIST = "CLEAR_CHECKLIST";

export const GET_LEAVES_USER = "GET_LEAVES_USER";

export const SET_ONLINE_STATUS = "SET_ONLINE_STATUS";

export const GET_APP_DATA = "GET_APP_DATA";

export const NEW_FEEDBACK = "NEW_FEEDBACK";
export const GET_FEEDBACK = "GET_FEEDBACK";
export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";

export const SET_MODALS_2 = "SET_MODALS_2";
export const CLEAR_MODALS_2 = 'CLEAR_MODALS_2"';

export const LOAD_USER_TO_FINALIZE = "LOAD_USER_TO_FINALIZE";
export const FINALIZE_ERROR = "FINALIZE_ERROR";

export const ADMIN_IN_PROGRESS = "ADDMIN_IN_PROGRESS";

export const ADMIN_RESET_STATES = "ADMIN_RESET_STATES";
export const ADMIN_RESET_GET_PERMISSIONS = "ADMIN_RESET_GET_PERMISSIONS";

export const NEW_OFF_DAY = "NEW_OFF_DAY";

export const RESET_WORKDAY_STATES = "RESET_WORKDAY_STATES";

export const DELETE_WORKDAY = "DELETE_WORKDAY";

export const COMPANY_MAIN_MENU = "COMPANY_MAIN_MENU";

export const START_WORK_DAY = "START_WORK_DAY";
export const STOP_WORK_DAY = "STOP_WORK_DAY";

export const ITEM_CHECK_LIST_UPDATE = "ITEM_CHECK_LIST_UPDATE";
