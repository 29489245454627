import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";

import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import {
  actionClearSelectedReport,
  actionGetCompanyReportById,
  actionNewReport,
  actionUpdateReport,
} from "../../../redux/actions/action.report";
import { dateWithDayNameAndTime } from "../../../utils/toDates";

import { useFormik } from "formik";
import * as Yup from "yup";
import AutoSizeTextArea from "../../../components/AutoSizeTextArea/AutoSizeTextArea";
import SelectCosts from "../../../components/Select/Company/SelectCosts";
import SelectPartsV2 from "../../../components/Select/Company/SelectPartsV2";
import { actionSetSession } from "../../../redux/actions/action.session";
import { havePermissions } from "../../../utils/havePermissions";
import { actionAlert } from "../../../redux/actions/action.alert";
import { actionGetItemById } from "../../../redux/actions/action.item";
import MyFlatPicker from "../../../components/MyDatePicker/MyFlatPicker";

const OneCompanyreport = () => {
  const dispatch = useDispatch();
  const { itemId, reportId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { selectedReport, loadingReport } = useSelector(
    (state) => state.report
  );

  const { selectedItem } = useSelector((state) => state.item);

  const { permissions2 } = useSelector((state) => state.auth.user);

  const canWrite = havePermissions(permissions2, "reports", ["READ", "WRITE"]);
  const noPrices = havePermissions(permissions2, "reports", ["noPrices"]);

  const [formData, setFormData] = useState({
    title: "",
    workDesc: "",
    parts: [],
    costs: [],
    prices: {
      costs: {},
      partsPrice: 0,
      totalPrice: 0,
    },
    hours: null,
    date: new Date(),
    custWorkDesc: "",
  });

  const [states, setStates] = useState({
    submitting: false,
    disableEdit: reportId === "new" ? false : true,
  });

  const [cooldown, setCooldown] = useState(2);
  const { submitting, disableEdit } = states;

  useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
    } else {
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cooldown]);

  const formik = useFormik({
    initialValues: {
      ...formData,
      itemId: itemId,
      reportId: reportId,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Pakollinen tieto"),
      workDesc: Yup.string().required("Pakollinen tieto"),
    }),
    onSubmit: (values) => {
      setStates((state) => ({ ...state, submitting: true }));
      if (reportId && reportId !== "new") {
        submitEdit(values);
      } else if (reportId && reportId === "new") {
        submitNew(values);
      }
    },
  });

  const submitNew = (values) => {
    dispatch(actionNewReport(values)).then((success) => {
      if (!success) {
        dispatch(actionAlert("Epännistui", "danger"));
      } else {
        dispatch(actionAlert("Onnistui", "success"));
        setStates((state) => ({ ...state, disableEdit: true }));
      }
      setStates((state) => ({ ...state, submitting: false }));
    });
  };
  const submitEdit = (values) => {
    dispatch(actionUpdateReport(values)).then((data) => {
      if (data) {
        dispatch(actionAlert("Onnistui", "success"));
        setStates((state) => ({ ...state, disableEdit: true }));
      } else {
        dispatch(actionAlert("Epännistui", "danger"));
      }

      setStates((state) => ({ ...state, submitting: false }));
    });
  };
  useEffect(() => {
    dispatch(actionSetSession("RAPORTTI", `/item/${itemId}`, true, 5));

    if (
      !selectedReport &&
      reportId &&
      reportId !== "new" &&
      reportId.length === 24
    ) {
      dispatch(actionGetCompanyReportById(reportId));
    } else if (reportId && reportId !== "new" && reportId.length !== 24) {
      dispatch(actionAlert("Virheellinen raportin id", "danger"));
      history.replace({
        pathname: `/item/${itemId}`,
        state: { ...location.state, tab: 5 },
      });
    }

    if (!selectedItem) {
      dispatch(actionGetItemById(itemId));
    }

    return () => {
      dispatch(actionClearSelectedReport());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedReport) {
      const {
        title,
        workDesc,
        parts,
        costs,
        prices,
        hours,
        date,
      } = selectedReport;

      setFormData((state) => ({
        ...state,
        title: title,
        workDesc: workDesc,
        parts: parts,
        costs: costs,
        prices: prices ? prices : { costs: {} },
        hours: hours,
        date: date,
      }));
    }

    if (selectedItem) {
      setFormData((state) => ({
        ...state,
        custWorkDesc: selectedItem.desc || "-",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport, selectedItem]);

  useEffect(() => {
    if (!noPrices) {
      // hinnat sallittu
      let total = 0;
      if (formik.values.prices) {
        if (formik.values.prices.costs) {
          total += parseFloat(formik.values.prices.costs.total);
        }

        if (formik.values.prices.partsPrice) {
          total += parseFloat(formik.values.prices.partsPrice);
        }
      }

      formik.setFieldValue("prices.totalPrice", total);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.prices.partsPrice, formik.values.prices.costs.total]);

  if (reportId !== "new" && loadingReport) {
    return <CustomSpinner color="blue" />;
  }

  const handleDate = (date) => {
    formik.setFieldValue("date", date);
  };

  const setCosts = (costs) => {
    formik.setFieldValue("costs", costs);
  };

  const setCostsPrices = (prices) => {
    formik.setFieldValue("prices.costs", prices);
  };

  const setCostsHours = (hours) => {
    formik.setFieldValue("hours", hours);
  };

  const setParts = (parts) => {
    formik.setFieldValue("parts", parts);
  };

  const setPartsPrice = (price) => {
    formik.setFieldValue("prices.partsPrice", price);
  };

  if (reportId !== "new" && !loadingReport && !selectedReport) {
    dispatch(actionAlert("Raporttia ei löydy", "danger"));
    return (
      <Redirect
        to={{
          pathname: `/item/${itemId}`,
          state: { ...location.state, tab: 5 },
        }}
      />
    );
  }

  const handleFormCancel = () => {
    if (reportId === "new") {
      history.replace({
        pathname: `/item/${itemId}`,
        state: { ...location.state, tab: 5 },
      });
    } else {
      formik.resetForm();
      setStates((state) => ({
        ...state,
        disableEdit: !state.disableEdit,
      }));
    }
  };

  return (
    <div className="OneCompanyreport">
      {reportId && reportId !== "new" && (
        <div className="header">
          <div className="dates">
            <p>Luotu: {dateWithDayNameAndTime(selectedReport.createdAt)}</p>
            <p>
              Päivitetty: {dateWithDayNameAndTime(selectedReport.updatedAt)}
            </p>
          </div>
        </div>
      )}

      <div className="buttons">
        {canWrite
          ? reportId === "new"
            ? "Uusi raportti"
            : "Raportin muokkaus"
          : "Ei muokkaus oikeutta"}
        <div>
          {submitting ? (
            <Spinner animation="grow" />
          ) : !canWrite ? null : disableEdit ? (
            <Button
              className="primaryBtn"
              onClick={() => {
                setStates((state) => ({
                  ...state,
                  disableEdit: !state.disableEdit,
                }));
                setCooldown(2);
              }}
            >
              Muokkaa
            </Button>
          ) : (
            <>
              <Button className="primaryBtn" onClick={handleFormCancel}>
                Peruuta
              </Button>
              <Button
                className="primaryBtn"
                onClick={formik.handleSubmit}
                disabled={cooldown > 0}
              >
                Tallenna {cooldown > 0 ? `(${cooldown})` : null}
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="content">
        <MyFlatPicker
          date={formik.values.date}
          handleDate={handleDate}
          title="Asetettu Pvm:"
          disabled={!canWrite || disableEdit}
        />

        <Form.Group>
          <Form.Label>Otsikko</Form.Label>
          <Form.Control
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            isInvalid={formik.touched.title && formik.errors.title}
            onBlur={formik.handleBlur}
            disabled={!canWrite || disableEdit}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.title}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Tehtävän kuvaus </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            value={formik.values.custWorkDesc}
            readOnly
          />
          <Form.Text>Tieto tulee suoraan tehtävältä</Form.Text>
        </Form.Group>

        <AutoSizeTextArea
          readOnly={false}
          label="Tehdyt toimenpiteet"
          formikError={formik.errors.workDesc}
          name="workDesc"
          onChange={formik.handleChange}
          isInvalid={formik.touched.workDesc && formik.errors.workDesc}
          onBlur={formik.handleBlur}
          disabled={!canWrite || disableEdit}
          value={formik.values.workDesc}
        />

        <div className="space" />

        <SelectCosts
          setCosts={setCosts}
          costs={formik.values.costs || []}
          setCostsPrices={setCostsPrices}
          setCostsHours={setCostsHours}
          disableInputs={!canWrite || disableEdit}
        />

        <br />

        <SelectPartsV2
          parts={formik.values.parts}
          setParts={setParts}
          setPartsPrice={setPartsPrice}
          disableInputs={!canWrite || disableEdit}
        />
      </div>

      <div className="space" />

      <div className="summary">
        <p className="p-title">YHTEENVETO</p>

        <hr />
        {!noPrices && (
          <div className="prices">
            <p className="p-title">HINNAT</p>

            <div className="div1">
              <p>Työ: {formik.values.prices.costs.work}€</p>

              <p>Osat: {formik.values.prices.partsPrice}€</p>
              <strong>Yht. {formik.values.prices.totalPrice}€</strong>
            </div>
          </div>
        )}

        <hr />

        <div className="hours">
          <p className="p-title">TUNNIT</p>

          <div className="div1">
            {formik.values.hours && formik.values.hours.work ? (
              <div>
                <p>
                  Työtunnit (ei hintaa): {formik.values.hours.work.zeroHours} h
                </p>
                <p>
                  Työtunnit (hinta): {formik.values.hours.work.pricedHours} h
                </p>
                <p>Työtunnit (yht): {formik.values.hours.work.total} h</p>
              </div>
            ) : (
              <div>
                <p>Työtunnit (ei hintaa): -</p>
                <p>Työtunnit (hinta): -</p>
                <p>Työtunnit (yht): -</p>
              </div>
            )}

            {formik.values.hours && formik.values.hours.travel ? (
              <div>
                <p>
                  Matkatunnit (ei hintaa):{" "}
                  {formik.values.hours.travel.zeroHours} h
                </p>
                <p>
                  Matkatunnit (hinta): {formik.values.hours.travel.pricedHours}{" "}
                  h
                </p>
                <p>Matkatunnit (yht): {formik.values.hours.travel.total} h</p>
              </div>
            ) : (
              <div>
                <p>Matkatunnit (ei hintaa): -</p>
                <p>Matkatunnit (hinta): -</p>
                <p>Matkatunnit (yht): -</p>
              </div>
            )}
          </div>
        </div>

        <hr />
      </div>
    </div>
  );
};

export default OneCompanyreport;
