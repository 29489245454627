import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import useRecorder from "./useRecorder";

import "./SelectFile.scss";
import { iconFail, iconMic, iconSuccess } from "../Icons/Icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RecordAudio = ({ folder, id, uploadFile, uploading }) => {
  const { recordStates, ...handlers } = useRecorder();

  const { audioUrl, audioFile, min, sec, initRecording } = recordStates;
  const { startRecording, stopRecording, cancelRecording } = handlers;

  const micIcon = (
    <FontAwesomeIcon
      icon={iconMic}
      size="3x"
      className="iconMic smallShadow"
      onClick={startRecording}
    />
  );
  const stopIcon = <FontAwesomeIcon icon={iconSuccess} size="3x" />;

  const cancelIcon = <FontAwesomeIcon icon={iconFail} size="lg" />;

  const controls = (
    <div className="controls">
      <div className="div1">
        <div className="time">
          <p>
            KESTO:{" "}
            <span>
              {formatMinutes(min)}:{formatSeconds(sec)}
            </span>
          </p>
          {initRecording && (
            <div>
              <div className="iconCancel" onClick={cancelRecording}>
                {cancelIcon}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="div2">
        {initRecording ? (
          <Button
            variant="none"
            disabled={sec === 0 && min === 0}
            onClick={stopRecording}
            className="iconStop smallShadow"
          >
            {stopIcon}
          </Button>
        ) : (
          <Form.Group>
            {micIcon}
            {audioFile && (
              <Form.Text>
                Uusi tallenne korvaa edellisen, jos sitä ei ole tallennettu!
              </Form.Text>
            )}
          </Form.Group>
        )}
      </div>
    </div>
  );
  const saveAudio = (e) => {
    const data = {
      myFile: audioFile,
      fileExtension: "webm",
      legend: "audio",
      folder: folder,
      id: id,
      showToCustomer: false,
    };

    uploadFile(e, data);
  };

  function formatMinutes(minutes) {
    return minutes < 10 ? `0${minutes}` : minutes;
  }

  function formatSeconds(seconds) {
    return seconds < 10 ? `0${seconds}` : seconds;
  }

  return (
    <div className="RecordAudio">
      {controls}
      <div>
        {audioUrl ? (
          <div className="audioDiv">
            <Form.Group>
              <Form.Label>Esikuuntelu</Form.Label>
              <br />
              <audio src={audioUrl} controls />

              <Form.Text>Muista tallentaa nauhoitus !</Form.Text>
            </Form.Group>

            {uploading ? (
              <Spinner animation="grow" />
            ) : (
              <Button className="primaryBtn" onClick={saveAudio} block>
                Tallenna
              </Button>
            )}
          </div>
        ) : (
          <p>Ei tallennetta</p>
        )}
      </div>
    </div>
  );
};

export default RecordAudio;
