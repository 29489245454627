import { Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { actionSetModals2 } from "../../redux/actions/action.modal";

import FileSaver from "file-saver";

import "./FilesList.scss";
import { actionDeleteImageCompany } from "../../redux/actions/action.company";
import ConfirmPopOver from "../Popovers/ConfirmPopOver";
const FilesList = ({ files, itemId, folder, supportDelete }) => {
  const dispatch = useDispatch();
  const { userGroup } = useSelector((state) => state.auth.user);

  const openImage = (image) => {
    dispatch(
      actionSetModals2({
        modalType: 36,
        title: "KUVA",
        data: {
          image: image,
          id: itemId,
        },
      })
    );
  };

  const downloadPdf = (url, name) => {
    FileSaver.saveAs(url, name);
  };

  const deleteFile = supportDelete
    ? (file) => {
        const content = {
          folder: folder,
          id: itemId,
          name: file.name,
          uuid: file.uuid,
        };

        if (userGroup < 11) {
          dispatch(actionDeleteImageCompany(content));
        }
      }
    : undefined;

  return (
    <div className="FilesList">
      <div className="files">
        {files?.map((file, i) => (
          <div className="fileBox" key={i}>
            {supportDelete && userGroup < 11 && (
              <ConfirmPopOver
                title="Poista liite ?"
                text=""
                btnText=""
                action={() => deleteFile(file)}
              />
            )}
            {file.format === "pdf" ? (
              <div className="pdfBox">
                <p>
                  <strong>PDF</strong>
                </p>

                <div>
                  <Button
                    variant="link"
                    onClick={() =>
                      dispatch(
                        actionSetModals2({
                          modalType: 7,
                          data: file.url,
                        })
                      )
                    }
                  >
                    Avaa
                  </Button>

                  <Button
                    variant="link"
                    onClick={() => downloadPdf(file.url, file.legend)}
                  >
                    Lataa
                  </Button>
                </div>
              </div>
            ) : file.format === "mp4" || file.format === "mkv" ? (
              <video
                controls
                muted
                controlsList="nodownload"
                src={file.url}
                alt={file.uuid}
              />
            ) : (
              <Image
                key={i}
                alt={file.uuid}
                src={file.signedUrl || file.url}
                onClick={() => openImage(file)}
                thumbnail
                loading="lazy"
              />
            )}

            <p>{file.legend}</p>
            {file.index && <p>Index: {file.index + 1}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilesList;
