import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconSignal } from "../components/Icons/Icons";
import socketIOClient from "socket.io-client";
import proxy from "./proxy";
import {
  actionSetSocket,
  actionSocketInitialStates,
} from "../redux/actions/action.socket";
import { actionClearToasts, actionToast } from "../redux/actions/action.toast";
import {
  actionAddNewMessageToRedux,
  actionReadRoomMessage,
  actionSortRooms,
  actionUpdateRoomMsg,
} from "../redux/actions/action.chatRoom";

import { actionLogout } from "../redux/actions/action.auth";
import { actionSetItemData } from "../redux/actions/action.item";
import {
  actionAddDBItems,
  actionDBReceivedData,
  actionOnlineDisplays,
  actionRemoveDBItem,
  actionUpdateDBInfo,
  actionUpdateDBItems,
} from "../redux/actions/action.display";

import sound from "../images/notif-sound.mp3";
import { actionSetCompanySocketData } from "../redux/actions/action.company";
import { actionAnswerAsk, actionAsk } from "../redux/actions/action.ask";

export default function CustomSocket() {
  const dispatch = useDispatch();

  const connectedIcon = <FontAwesomeIcon icon={iconSignal} color="#8ce300" />;
  const disconnectedIcon = <FontAwesomeIcon icon={iconSignal} color="red" />;

  const { event, subEvent, author, data, response } = useSelector(
    (state) => state.socket
  );
  const { userGroup, name } = useSelector((state) => state.auth.user);

  const [socketStates, setSockedStates] = useState({
    socket: undefined,
    connected: false,
  });
  const { socket, connected } = socketStates;
  const token = localStorage.getItem("token");

  function playSound() {
    const audio = new Audio(sound);
    audio.play();
  }

  useEffect(() => {
    let counter;

    const socket = socketIOClient.connect(proxy, {
      query: { token },
      closeOnBeforeunload: false, // defaults to true
    });

    dispatch(actionSetSocket(socket));

    socket.on("connect", () => {
      setSockedStates((state) => ({
        ...state,
        connected: true,
        socket: socket,
      }));
    });

    socket.on("disconnect", () => {
      setSockedStates((state) => ({
        ...state,
        connected: false,
        socket: undefined,
      }));
    });

    socket.on("db_online", (data) => {
      // console.log("ONLINE", data);
      dispatch(actionOnlineDisplays(data));
    });

    socket.on("offline", (data) => {
      //  console.log("SOCKER OFFLINE", data);
      dispatch(actionOnlineDisplays(data));
    });

    socket.on("company_socket_data", (data) => {
      const { users } = data;
      //  console.log("ONLINE", data);

      dispatch(actionSetCompanySocketData({ users: users || [] }));
    });

    const sendNotification = (title, body) => {
      if (Notification.permission === "granted") {
        const url = `https://${window.location.hostname}`;

        navigator.serviceWorker.getRegistration().then(function (reg) {
          if (!reg) return;
          var options = {
            body: body || "-",
            icon: "images/example.png",
            vibrate: [100, 50, 100],
            data: {
              dateOfArrival: Date.now(),
              primaryKey: 1,
              link: url + "/myitems",
            },
          };
          reg.showNotification(title || "Otsikko", options);
        });
      }
    };
    socket.on("ask", (data) => {
      const parsedData = JSON.parse(data);

      playSound();

      if (parsedData.type === "question") {
        dispatch(
          actionAsk({
            text: parsedData.text,
            text2: parsedData.text2,
            fromId: parsedData.fromId,
            fromName: parsedData.fromName,
            date: parsedData.date,
          })
        );

        sendNotification(parsedData.text, parsedData.text2);
      } else if (parsedData.type === "answer") {
        dispatch(
          actionAnswerAsk({
            answer: parsedData.answer,
            question: parsedData.question,
          })
        );
        const title = parsedData?.question?.text;
        const body =
          parsedData.answer?.text === "yes"
            ? "Kyllä"
            : parsedData.answer?.text === "no"
            ? "EI"
            : parsedData.answer?.text;

        sendNotification(title, body);
      }
    });

    if (userGroup) {
      socket.on("final_report", (data) => {
        const response = JSON.parse(data);

        let link = response.link;

        if (!link) {
          link =
            userGroup < 11
              ? response.companyLink
              : userGroup > 10
              ? response.customerLink
              : undefined;
        }

        dispatch(
          actionToast({
            event: response.event,
            subEvent: response.subEvent,

            title: response.header,
            text: response.title,
            link: link,
            author: response.author,
            data: response.response,
            logo: response.logo,
          })
        );
      });

      socket.on("item", (data) => {
        const response = JSON.parse(data);

        if (response.subEvent === "lock_item") {
          // lukitaan vain jos on samassa tehtävässä
          if (window.location.pathname === response.link) {
            return dispatch(actionSetItemData(response.response));
          } else return;
        } else if (response.subEvent === "lock_item_force") {
          // jos pakotetaan auki päivitetään "selectedItem" ja annetaan myös "toast" ilmoitus
          dispatch(actionSetItemData(response.response));
        }

        dispatch(
          actionToast({
            event: response.event,
            subEvent: response.subEvent,

            title: response.header,
            text: response.title,
            link: response.link,
            author: response.author,
            data: response.response,
            logo: response.logo,
          })
        );
      });

      socket.on("chat", (data) => {
        const response = JSON.parse(data);

        const pathName = window.location.pathname;

        if (pathName.includes(response.link)) {
          if (response.subEvent === "new_msg") {
            const msgId = response.response?._id;
            dispatch(actionReadRoomMessage({ msgId }));
            return dispatch(actionAddNewMessageToRedux(response.response));
          } else if (response.subEvent === "new_msg_company_chat") {
            return dispatch(actionAddNewMessageToRedux(response.response));
          }
        } else if (pathName === "/chat/rooms") {
          dispatch(actionUpdateRoomMsg(response.response)).then(() => {
            dispatch(actionSortRooms());
          });
        }

        dispatch(
          actionToast({
            event: response.event,
            subEvent: response.subEvent,

            title: response.header,
            text: response.title,
            link: response.link,
            author: response.author,
            data: response.response,
          })
        );
      });

      if (userGroup < 11) {
        socket.on("need_log_out", (data) => {
          counter = setTimeout(() => {
            dispatch(actionLogout());
          }, 5000);

          const response = JSON.parse(data);

          dispatch(
            actionToast({
              event: response.event,
              title: "TASO",
              text: response.title,
            })
          );
        });
      }
    } else if (!userGroup && name) {
      socket.on("DB_UI", (data) => {
        playSound();
        const fields = {
          lastReceived: new Date(),
        };
        dispatch(actionUpdateDBInfo(fields));

        if (data.subEvent !== "force_reload") {
          // kutsu keskeytyy jos sivu pakotetaan päivittymään
          // joten kutsutaan vain muissa eventeissä
          dispatch(actionDBReceivedData(data.dbIds));
        }

        if (data.subEvent === "board") {
          dispatch(actionUpdateDBItems(data.response));
        } else if (data.subEvent === "alerts" || data.subEvent === "info") {
          dispatch(actionUpdateDBInfo(data.response));
        } else if (data.subEvent === "add_board") {
          dispatch(actionAddDBItems(data.response));
        } else if (data.subEvent === "remove_board") {
          dispatch(actionRemoveDBItem(data.response));
        } else if (data.subEvent === "force_reload") {
          socket.disconnect();
          window.location.reload();
        }
      });
    }

    return () => {
      socket.disconnect();
      dispatch(actionClearToasts());

      clearTimeout(counter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle item socket events (from item.jsx)
  const handleItemEmit = () => {
    const content = {
      author,
      event: event,
      subEvent: subEvent,
      response: response,
      ...data,
    };

    if (content) {
      socket.emit("item", JSON.stringify(content));
    }
  };

  const handleChatEmit = () => {
    const content = {
      author,
      event: event,
      subEvent: subEvent,
      response: response,
      ...data,
    };

    if (content) {
      socket.emit("chat", JSON.stringify(content));
    }
  };

  // handle finalReport socket events
  const handleFinalReportEmit = () => {
    const content = {
      author,
      event: event,
      subEvent: subEvent,
      response: response,
      ...data,
    };

    if (content) {
      socket.emit("final_report", JSON.stringify(content));
    }
  };

  const handleDbUiEmit = () => {
    const content = {
      displayIds: data.displayIds,
      response,
      subEvent,
    };
    socket.emit("DB_UI", JSON.stringify(content));
  };
  useEffect(() => {
    if (event === "item") {
      handleItemEmit();
      dispatch(actionSocketInitialStates());
    } else if (event === "chat") {
      handleChatEmit();
      dispatch(actionSocketInitialStates());
    } else if (event === "need_log_out") {
      const emitData = {
        title: "Muutoksia tunnuksessa, kirjataan ulos 5s kuluttua",
        event: event,
        toUsers: data.toUsers,
      };
      socket.emit("need_log_out", JSON.stringify(emitData));

      dispatch(actionSocketInitialStates());
    } else if (event === "final_report") {
      handleFinalReportEmit();
      dispatch(actionSocketInitialStates());
    } else if (event === "DB_UI") {
      handleDbUiEmit();
      dispatch(actionSocketInitialStates());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);
  return (
    <div className="wsConnection">
      {connected ? connectedIcon : disconnectedIcon}
    </div>
  );
}
