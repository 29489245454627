import axios from "axios";
import proxy from "../../utils/proxy";
import {
  CLEAR_SELECTED_FOLDER,
  DELETE_MAIN_FOLDER,
  DELETE_SUB_FOLDER,
  GET_FOLDERS,
  MOVE_FOLDER_TO_PARENT,
  MOVE_FOLDER_TO_SUB,
  NEW_MAIN_FOLDER,
  NEW_SUB_FOLDER,
  SEARCH_FOLDERS,
  SELECT_FOLDER,
  UPDATE_MAIN_FOLDER,
  UPDATE_SUB_FOLDER,
} from "../types/folder.types";
import handleErrors from "./handleErrors";

let source;

// unmount error handle
export const actionCancelFolderRequests = () => async (dispatch) => {
  if (source) {
    source.cancel("Folders got unmounted");
  }
};

export const actionNewFolderCompany = ({
  title,
  isPrivate,
  folderId,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ title, isPrivate, folderId });

  try {
    const res = await axios.post(
      `${proxy}/api/company/folders/new_folder`,
      body,
      config
    );

    if (res.status === 200) {
      if (res.data.parentFolders.length > 0) {
        dispatch({
          type: NEW_SUB_FOLDER,
          payload: res.data,
        });
      } else {
        dispatch({
          type: NEW_MAIN_FOLDER,
          payload: res.data,
        });
      }

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetFoldersCompany = (showHidden) => async (dispatch) => {
  source = axios.CancelToken.source();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: source.token,
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/folders/get-folders?showHidden=${showHidden}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_FOLDERS,
        payload: res.data,
      });
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionLinkToFolderCompany = ({ type, folder, typeId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ type, folder, typeId });

  try {
    const res = await axios.post(
      `${proxy}/api/company/folders/link-to-folder`,
      body,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetFolderByIdCompany = ({ folderId, showHidden }) => async (
  dispatch
) => {
  source = axios.CancelToken.source();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: source.token,
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/folders/get-folder-by-id/${folderId}?showHidden=${showHidden}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: SELECT_FOLDER,
        payload: res.data,
      });
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionClearSelectedFolder = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_FOLDER,
  });
};

export const actionUpdateFolderCompany = ({
  title,
  isPrivate,
  folderId,
  variant,
  hide,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ title, isPrivate, folderId, variant, hide });

  try {
    const res = await axios.put(
      `${proxy}/api/company/folders/update-folder`,
      body,
      config
    );

    if (res.status === 200) {
      if (res.data.parentFolders.length > 0) {
        dispatch({
          type: UPDATE_SUB_FOLDER,
          payload: res.data,
        });
      } else {
        dispatch({
          type: UPDATE_MAIN_FOLDER,
          payload: res.data,
        });
      }

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionUpdateSelectedFolderFolders = (newFolder) => (dispatch) => {
  if (newFolder.parentFolders.length > 0) {
    dispatch({
      type: MOVE_FOLDER_TO_SUB,
      payload: newFolder,
    });
  } else {
    dispatch({
      type: MOVE_FOLDER_TO_PARENT,
      payload: newFolder,
    });
  }
};

export const actionDeleteFolderCompany = ({ folderId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/folders/delete-folder/${folderId}`,
      config
    );

    if (res.status === 200) {
      if (res.data.parentFolders.length > 0) {
        dispatch({
          type: DELETE_SUB_FOLDER,
          payload: res.data,
        });
      } else {
        dispatch({
          type: DELETE_MAIN_FOLDER,
          payload: res.data,
        });
      }

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionCopyFolder = (folderId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `${proxy}/api/company/folders/copy-folder/${folderId}`,
      config
    );

    if (res.status === 200) {
      if (res.data.parentFolders.length > 0) {
        dispatch({
          type: NEW_SUB_FOLDER,
          payload: res.data,
        });
      } else {
        dispatch({
          type: NEW_MAIN_FOLDER,
          payload: res.data,
        });
      }

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetFoldersByItemId = (itemId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${proxy}/api/company/folders/get-folder-by-item-id/${itemId}`,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionSearchFolders = ({ searchText }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ searchText });

  try {
    const res = await axios.post(
      `${proxy}/api/company/folders/search-folders`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: SEARCH_FOLDERS,
      });

      dispatch({
        type: GET_FOLDERS,
        payload: res.data,
      });
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};
