import { GET_FEEDBACK, NEW_FEEDBACK, UPDATE_FEEDBACK } from "../actions/types";

const initialState = {
  success: false,
  loading: true,
  data: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case NEW_FEEDBACK:
      return {
        ...state,
        success: true,
      };
    case GET_FEEDBACK:
      return {
        ...state,
        data: payload,
        success: false,
        loading: false,
      };
    case UPDATE_FEEDBACK:
      return {
        ...state,
        success: true,
      };

    default:
      return state;
  }
}
