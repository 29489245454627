import { useDispatch, useSelector } from "react-redux";
import { actionAnswerQuestion } from "../../redux/actions/action.ask";
import "./Ask.scss";
import { Button } from "react-bootstrap";
import { dateWithDayNameAndTime } from "../../utils/toDates";

const Ask = () => {
  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.ask);
  const { socket } = useSelector((state) => state.socket);

  if (!questions) {
    return null;
  }

  if (questions && !questions.length) {
    return null;
  }

  const answer = (i, answer) => {
    dispatch(actionAnswerQuestion(questions[i].id));

    const content = {
      to: questions[i].fromId,
      type: "answer",
      answer: {
        fromName: "Etunimi Sukunimi",
        date: new Date(),
        text: answer,
      },
      question: questions[i],
    };

    socket.emit("ask", JSON.stringify(content));
  };

  return (
    <div className="Ask scale2">
      {questions.map((el, i) => (
        <div className="box" key={i}>
          <div className="header">
            <p>{el.fromName}</p>
            <p>{dateWithDayNameAndTime(el.date)}</p>
          </div>
          <p>
            <strong>{el.text}</strong>
          </p>
          <p>{el.text2}</p>

          <div className="buttons">
            <Button
              onClick={() => answer(i, "yes")}
              variant="link"
              className="outLine-lime"
            >
              Kyllä
            </Button>
            <Button
              onClick={() => answer(i, "no")}
              variant="link"
              className="outLine-pink"
            >
              Ei
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Ask;
