// React & Routing
import React from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { actionRemoveToast } from "../../redux/actions/action.toast";

// Bootstrap
import Toast from "react-bootstrap/Toast";

// FontAwesome Icons
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styling
import "./Toast.scss";
import { actionSetItemData } from "../../redux/actions/action.item";
import { actionClearModals2 } from "../../redux/actions/action.modal";

import proxy from "../../utils/proxy";
import { actionAlert } from "../../redux/actions/action.alert";

const iconExample = (
  <FontAwesomeIcon
    icon={faUserCircle}
    color="#000000"
    size="1x"
    style={{ marginRight: "5px" }}
  />
);

const ToastComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const toasts = useSelector((state) => state.toast);

  const pathname = window.location.pathname;

  const handleToastClick = (toastId, link, event, data) => {
    // voidaan optimoida, ettei tarvitse päivittää sivua
    // oletuksena päivitetään sivu ja haetaan kaikki tiedot uudelleen

    if (pathname.includes(link)) {
      switch (event) {
        case "item":
          dispatch(actionSetItemData(data));
          dispatch(actionAlert("Päivitetty", "success"));
          break;

        default:
          window.location.reload();
      }
    } else {
      history.replace(link);
      window.location.reload();
    }

    dispatch(actionClearModals2());
    dispatch(actionRemoveToast(toastId));
  };
  const myToasts = toasts
    .sort((a, b) =>
      b.timeNow < a.timeNow ? -1 : b.timeNow > a.timeNow ? 1 : 0
    )
    .map((toast) => (
      <Toast
        key={toast.id}
        className="appToast"
        onClose={() => dispatch(actionRemoveToast(toast.id))}
        // style={{ backgroundImage: `url(${proxy}${toast.logo})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
      >
        <Toast.Header>
          {toast.logo ? (
            <img
              src={`${proxy}${toast.logo}`}
              width="40px"
              height="40px"
              alt="company-logo"
            />
          ) : (
            iconExample
          )}
          <strong className="mr-auto">
            {toast.title} {toast.pcs > 1 ? ` X ${toast.pcs}` : null}
          </strong>
          <small>{toast.timeNow}</small>
        </Toast.Header>
        <Toast.Body
          onClick={() =>
            handleToastClick(toast.id, toast.link, toast.event, toast.data)
          }
        >
          <small>{toast.author}</small>
          <p>{toast.text}</p>
        </Toast.Body>
      </Toast>
    ));
  return myToasts;
};

export default ToastComponent;
