import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Collapse,
  Dropdown,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import "./Folders.scss";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  actionCancelFolderRequests,
  actionClearSelectedFolder,
  actionCopyFolder,
  actionDeleteFolderCompany,
  actionGetFolderByIdCompany,
  actionGetFoldersCompany,
  actionLinkToFolderCompany,
  actionNewFolderCompany,
  actionSearchFolders,
  actionUpdateFolderCompany,
  actionUpdateSelectedFolderFolders,
} from "../../redux/actions/action.folder";
import { actionSetSession } from "../../redux/actions/action.session";

import qs from "query-string";
import { actionAlert } from "../../redux/actions/action.alert";
import CompanyItem from "../../components/ItemCards/CompanyItem";
import {
  iconOpenFolder,
  iconSave,
  iconThreeDots,
  iconFail,
  iconFolder,
  iconSearch,
} from "../../components/Icons/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmAlert from "../../components/Alert/ConfirmAlert";
import { dateWithDayNameAndTime } from "../../utils/toDates";

const folderOpenIcon = (
  <FontAwesomeIcon icon={iconOpenFolder} className="folderIcon" />
);
const folderClosedIcon = (
  <FontAwesomeIcon icon={iconFolder} className="folderIcon" />
);

const threeDots = (
  <FontAwesomeIcon icon={iconThreeDots} className="threeDots" />
);

const searchIcon = <FontAwesomeIcon icon={iconSearch} />;

const Folders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { folderId } = useParams();

  const { folders, loading, selectedFolder } = useSelector(
    (state) => state.folder
  );
  const { favoriteItems } = useSelector((state) => state.item);

  const { _id: myId } = useSelector((state) => state.auth.user);
  const queryParam = qs.parse(history.location.search);

  const [formData, setFormData] = useState({
    title: "",
    isPrivate: true,
    variant: "none",
    hide: false,
  });
  const [states, setStates] = useState({
    showForm: false,
    editingId: null,
    loadingSelected: true,
    id: null,
    type: undefined,
    folderToMove: undefined,
    alert: { show: false },
    folderIcon: folderClosedIcon,
    hoverFolderId: null,
    submittingEdit: false,
    submittingDelete: false,
    searchText: "",
    showHidden: false,
    submitting: false,
  });

  const {
    showForm,
    editingId,
    loadingSelected,
    id,
    type,
    alert,
    folderIcon,
    hoverFolderId,
    submittingEdit,
    searchText,
    showHidden,
    submitting,
    submittingDelete,
  } = states;

  const regex = RegExp(/^[a-zA-Z0-9_-\s]+$/, "i");

  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Pakollinen tieto")
        .matches(regex, "Vain erikoismerkit '_' ja '-' sallittu"),
    }),
    onSubmit: (values) => {
      setStates((state) => ({ ...state, submitting: true }));
      let content = {
        ...values,
      };

      if (!editingId) {
        content = {
          ...content,
          folderId: folderId,
        };

        dispatch(actionNewFolderCompany(content)).then((success) => {
          if (success) {
            dispatch(actionAlert("Onnistui", "success"));
            formik.resetForm();
            setStates((state) => ({
              ...state,
              showForm: !state.showForm,
              submitting: false,
            }));
          } else {
            setStates((state) => ({
              ...state,
              submitting: false,
            }));
          }
        });
      } else {
        setStates((state) => ({ ...state, submittingEdit: true }));
        content = {
          ...content,
          folderId: editingId,
        };

        dispatch(actionUpdateFolderCompany(content)).then((success) => {
          if (success) {
            dispatch(actionAlert("Onnistui", "success"));
            setStates((state) => ({
              ...state,
              editingId: null,
              showForm: false,
            }));
            formik.resetForm();
          }

          setStates((state) => ({ ...state, submittingEdit: false }));
        });
      }
    },
  });

  useEffect(() => {
    dispatch(actionSetSession("KANSIOT", "/", true));

    return () => dispatch(actionCancelFolderRequests());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchText) {
      if (folderId) {
        // haetaan valitun kansion tiedot
        setStates((state) => ({ ...state, loadingSelected: true }));

        dispatch(
          actionGetFolderByIdCompany({
            folderId: folderId,
            showHidden: showHidden,
          })
        ).then(() => {
          setStates((state) => ({ ...state, loadingSelected: false }));
        });
      } else {
        if (selectedFolder) {
          // nollataan valittu kansio
          dispatch(actionClearSelectedFolder());
        }
        // haetaan ns root kansio lista
        dispatch(actionGetFoldersCompany(showHidden)).then(() => {
          setStates((state) => ({ ...state, loadingSelected: false }));
        });
      }
    }

    setStates((state) => ({
      ...state,
      id: queryParam.itemId,
      type: queryParam.type,
      showForm: false,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId, showHidden]);

  const newFolder = () => {
    setStates((state) => ({ ...state, showForm: !state.showForm }));
  };

  const handleSwitch = (e) => {
    formik.setFieldValue(e.target.name, e.target.checked);
  };

  const selectFolder = (folder) => {
    if (id) {
      // pidetään linkitettävän id tallessa
      history.push(`/folders/${folder._id}?itemId=${id}&type=${type}`);
    } else {
      history.push(`/folders/${folder._id}`);
    }
  };

  const linkToFolder = (e, folder) => {
    e.stopPropagation();

    const content = {
      type: type,
      typeId: id,
      folder: folder,
    };

    dispatch(actionLinkToFolderCompany(content)).then((data) => {
      if (data) {
        if (type === "folder") {
          dispatch(actionUpdateSelectedFolderFolders(data));
        } else {
          dispatch(actionGetFolderByIdCompany({ folderId: folderId }));
        }
        dispatch(actionAlert("Onnistui", "success"));
        setStates((state) => ({ ...state, id: null, type: undefined }));
        history.replace(`/folders/${folderId ? folderId : ""}`);
      }
    });
  };

  const selectItemAndRedirect = (item) => {
    history.push(`/item/${item._id}`);
  };

  const toggleEdit = (e, folder) => {
    e.stopPropagation();

    if (folder) {
      const { _id: id, title, variant } = folder;
      // kansion muokkaus
      setStates((state) => ({ ...state, editingId: id }));

      setFormData((state) => ({
        ...state,
        title: title,
        isPrivate: folder.isPrivate,
        variant: variant,
      }));
    } else {
      setStates((state) => ({ ...state, editingId: null }));
      setFormData((state) => ({
        ...state,
        title: "",
        isPrivate: true,
        variant: "",
      }));
    }
  };

  const cancelLink = () => {
    // peruuta tehtävän linkitys
    if (selectedFolder) {
      history.replace(`/folders/${selectedFolder._id}`);
    } else {
      history.replace(`/folders`);
    }
    setStates((state) => ({ ...state, id: null, type: undefined }));
  };

  const redirectToFolder = (folderId) => {
    if (id && type) {
      history.replace(`/folders/${folderId}?itemId=${id}&type=${type}`);
    } else {
      history.replace(`/folders/${folderId}`);
    }
  };

  const moveFolder = (id) => {
    setStates((state) => ({
      ...state,
      id: id ? id : folderId,
      type: "folder",
    }));

    const itemId = `itemId=${id ? id : folderId}`;

    history.replace(
      `/folders/${folderId ? folderId : ""}?${itemId}&type=folder`
    );
  };

  const askForDelete = (id) => {
    const alert = {
      show: true,
      title: "Olet poistamassa kansiota",
      btnText: "Poista",
      variant: "warning-div",
      onSuccess: () => deleteFolder(id),
    };
    setStates((state) => ({ ...state, alert: alert }));
  };

  const askForCopy = (id) => {
    const alert = {
      show: true,
      title: "Kopioidaanko kansio?",
      btnText: "Kopioi",
      variant: "info-div",
      onSuccess: () => copyFolder(id),
    };
    setStates((state) => ({ ...state, alert: alert }));
  };

  const deleteFolder = (id) => {
    setStates((state) => ({ ...state, submittingDelete: true }));

    dispatch(actionDeleteFolderCompany({ folderId: id })).then((success) => {
      if (success) {
        dispatch(actionAlert("Onnistui", "success"));
        setStates((state) => ({
          ...state,
          alert: { show: false },
        }));
      }
      setStates((state) => ({ ...state, submittingDelete: false }));
    });
  };

  const copyFolder = (id) => {
    dispatch(actionCopyFolder(id)).then((success) => {
      if (success) {
        dispatch(actionAlert("Onnistui", "success"));
        setStates((state) => ({
          ...state,
          alert: { show: false },
        }));
      }
    });
  };
  const selectChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const options = [
    { label: "Ensisijainen", value: "primary" },
    { label: "Toissijainen", value: "secondary" },
    { label: "Tavallinen", value: "basic" },
    { label: "Ei mitään", value: "none" },
  ];

  const folderCard = (folder) => {
    return (
      <div
        key={folder._id}
        className={`folderCard smallShadow`}
        onClick={(e) => {
          if (!editingId) {
            // siirrettävää kansiota ei voi avata
            // eli ei voi siirtää kansiota kansion alikansioon
            if (type === "folder" && id === folder._id) {
              return;
            }
            selectFolder(folder);
          }
        }}
      >
        <div
          className={`header ${
            editingId === folder._id ? formik.values.variant : folder.variant
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {!editingId && (
            <div className="dropDiv">
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>{threeDots}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => askForCopy(folder._id)}
                    disabled={editingId}
                  >
                    Kopioi
                  </Dropdown.Item>

                  {folder.userId === myId && (
                    <>
                      <Dropdown.Item
                        disabled={editingId}
                        onClick={(e) => toggleEdit(e, folder)}
                      >
                        Muokkaa
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => moveFolder(folder._id)}
                        disabled={!folderId && folders && folders.length < 2}
                      >
                        Siirrä
                      </Dropdown.Item>

                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => askForDelete(folder._id)}
                        // disabled={folder.folders.length > 0}
                      >
                        Poista
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          {editingId === folder._id &&
            (submittingEdit ? (
              <Spinner animation="border" />
            ) : (
              <>
                <FontAwesomeIcon
                  icon={iconFail}
                  onClick={(e) => {
                    toggleEdit(e, null, "");
                    formik.resetForm();
                  }}
                  className="cancelIcon"
                />
                <FontAwesomeIcon
                  icon={iconSave}
                  onClick={formik.handleSubmit}
                  className="saveIcon"
                />
              </>
            ))}
        </div>
        <div
          className="content"
          onMouseEnter={() => {
            if (!editingId) {
              if (type === "folder" && id === folder._id) {
                // siirrettävää kansiota ei voi avata
                // eli ei voi siirtää kansiota kansion alikansioon
                return;
              }
              setStates((state) => ({
                ...state,
                folderIcon: folderOpenIcon,
                hoverFolderId: folder._id,
              }));
            }
          }}
          onMouseLeave={() => {
            if (!editingId) {
              if (type === "folder" && id === folder._id) {
                // siirrettävää kansiota ei voi avata
                // eli ei voi siirtää kansiota kansion alikansioon
                return;
              }
              setStates((state) => ({
                ...state,
                folderIcon: folderClosedIcon,
                hoverFolderId: null,
              }));
            }
          }}
        >
          <div className="dates">
            <small>Luotu: {dateWithDayNameAndTime(folder.createdAt)}</small>
            <small>
              Päivitetty: {dateWithDayNameAndTime(folder.updatedAt)}
            </small>
          </div>

          {hoverFolderId === folder._id ? folderIcon : folderClosedIcon}

          {editingId === folder._id && (
            <Form.Group>
              <Form.Control
                as="select"
                name="variant"
                onChange={selectChange}
                value={formik.values.variant}
              >
                {options.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          <Form.Group>
            <Form.Control
              name="title"
              value={
                editingId === folder._id ? formik.values.title : folder.title
              }
              onChange={formik.handleChange}
              isInvalid={formik.touched.title && formik.errors.title}
              onBlur={formik.handleBlur}
              disabled={editingId !== folder._id}
            />
            {editingId === folder._id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.title}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Check
              name="isPrivate"
              checked={
                editingId === folder._id
                  ? formik.values.isPrivate
                  : folder.isPrivate
              }
              onChange={handleSwitch}
              label="Yksityinen"
              disabled={editingId === folder._id ? false : true}
            />

            <Form.Check
              name="hide"
              checked={
                editingId === folder._id ? formik.values.hide : folder.hide
              }
              onChange={handleSwitch}
              label="Piilota"
              disabled={editingId === folder._id ? false : true}
            />
          </Form.Group>

          <div className="info">
            <span>Tehtäviä: {folder.items.length}</span>{" "}
            <span>Kansioita: {folder.folders.length}</span>
          </div>
        </div>
      </div>
    );
  };

  const oneFolder = selectedFolder && (
    <div className="folder">
      <div className="box">
        <p className="p1">ALIKANSIOT</p>

        <div className="subFolders">
          {selectedFolder.folders.map((folder, i) => folderCard(folder))}
        </div>
      </div>

      <br />

      <div className="box">
        <p className="p1">TEHTÄVÄT</p>

        <div className="items">
          {selectedFolder.items.map((item, i) => (
            <div key={i} className="itemCard smallShadow">
              <CompanyItem
                item={item}
                key={i}
                selectItemAndRedirect={selectItemAndRedirect}
                isFavorite={favoriteItems ? favoriteItems[item._id] : false}
                //timeNow={timeNow}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const onAlertHide = () => {
    setStates((state) => ({
      ...state,
      alert: { show: false },
    }));
  };

  const handleShange = (e) => {
    setStates({ ...states, [e.target.name]: e.target.value });
  };

  const submitSearch = () => {
    if (selectedFolder) {
      // nollataan valittu kansio
      dispatch(actionClearSelectedFolder());
    }

    history.replace("/folders");

    if (searchText.length >= 3) {
      dispatch(actionSearchFolders({ searchText: searchText }));
    }
  };

  const cancelSearch = () => {
    if (selectedFolder) {
      // nollataan valittu kansio
      dispatch(actionClearSelectedFolder());
    }
    history.replace("/folders");

    // haetaan ns root kansio lista
    dispatch(actionGetFoldersCompany()).then(() => {
      setStates((state) => ({
        ...state,
        loadingSelected: false,
        searchText: "",
      }));
    });
  };

  const statesCheckChange = (e) => {
    setStates({ ...states, [e.target.name]: e.target.checked });
  };
  // siirrettävä kansio on kansio itse || siirrettävä kansio on jo kansiossa || pääkansio on jo pääkansio
  const disableLinkBtn =
    id === folderId ||
    (selectedFolder && selectedFolder.folders.some((el) => el._id === id)) ||
    (folders && folders.some((el) => el._id === id));

  return (
    <div className="Folders basicPage">
      {selectedFolder && (
        <Breadcrumb className="sticky">
          {[{ title: "root", _id: "" }, ...selectedFolder.pathArray].map(
            (el, i) => (
              <Breadcrumb.Item
                active={folderId === el._id}
                key={i}
                onClick={() => redirectToFolder(el._id)}
              >
                {el.title}
              </Breadcrumb.Item>
            )
          )}
        </Breadcrumb>
      )}

      <Form.Group className="search">
        <InputGroup>
          {searchText && (
            <InputGroup.Prepend>
              <Button variant="none" onClick={cancelSearch}>
                <FontAwesomeIcon icon={iconFail} />
              </Button>
            </InputGroup.Prepend>
          )}
          <Form.Control
            name="searchText"
            onChange={handleShange}
            type="text"
            value={searchText}
          />
          <InputGroup.Append>
            <Button
              variant="none"
              onClick={submitSearch}
              disabled={searchText.length < 3}
            >
              {searchIcon}
            </Button>
          </InputGroup.Append>
        </InputGroup>

        {searchText.length !== 0 && searchText.length < 3 && (
          <Form.Text>Haettavan sanan pitää olla vähntään 3 merkkiä</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Check
          name="showHidden"
          label="Piilotetut"
          onChange={statesCheckChange}
          value={showHidden}
          type="switch"
          id="custom-switch"
        />
      </Form.Group>

      <div className="confirmAlert">
        <ConfirmAlert
          {...alert}
          onClose={onAlertHide}
          submitting={submittingDelete}
        />
      </div>
      <div className="titleRow">
        <h1>KANSIOT</h1>

        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>{threeDots}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Header>VALINNAT</Dropdown.Header>
            <Dropdown.Item onClick={newFolder}>Uusi kansio</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Collapse in={showForm}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Check
              name="isPrivate"
              checked={
                selectedFolder
                  ? selectedFolder.isPrivate
                  : formik.values.isPrivate
              }
              onChange={handleSwitch}
              label="Yksityinen"
              disabled={selectedFolder ? true : false}
            />
            {selectedFolder && selectedFolder.isPrivate ? (
              <Form.Text>
                Uusi kansio on yksityinen, koska pääkansio on yksityinen
              </Form.Text>
            ) : selectedFolder ? (
              <Form.Text>
                Uusi kansio on julkinen, koska pääkansio on julkinen
              </Form.Text>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Label>Nimi</Form.Label>
            <Form.Control
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              isInvalid={formik.touched.title && formik.errors.title}
              onBlur={formik.handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.title}
            </Form.Control.Feedback>
          </Form.Group>
          {submitting ? (
            <Spinner animation="grow" />
          ) : (
            <Button type="submit" className="primaryBtn">
              Luo kansio
            </Button>
          )}
        </Form>
      </Collapse>

      {selectedFolder && id && type === "item" ? (
        <>
          <h4>Linkitä tähän kansionn</h4>
          <Button
            variant="link"
            onClick={(e) => linkToFolder(e, selectedFolder)}
            disabled={disableLinkBtn}
          >
            Linkitä
          </Button>
          <Button variant="link" onClick={cancelLink}>
            Peruuta
          </Button>
        </>
      ) : id && type === "folder" ? (
        <>
          <h4>Siirrä tähän kansioon</h4>
          <Button
            variant="link"
            onClick={(e) => linkToFolder(e, selectedFolder)}
            disabled={disableLinkBtn}
          >
            Siirrä
          </Button>
          <Button variant="link" onClick={cancelLink}>
            Peruuta
          </Button>
        </>
      ) : null}

      {loadingSelected ? (
        <Spinner animation="border" />
      ) : (
        <>
          {!folders ? null : (
            <div className="box">
              <p className="p1">PÄÄKANSIOT</p>

              <div className="subFolders">
                {loading ? (
                  <Spinner animation="border" />
                ) : folders.length > 0 ? (
                  folders.map((folder, i) => folderCard(folder))
                ) : (
                  <p>Ei kansioita</p>
                )}
              </div>
            </div>
          )}

          {oneFolder}
        </>
      )}
    </div>
  );
};

export default Folders;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="CustomToggle"
  >
    {/* custom icon */}
    {children}
  </div>
));
