export const SEARCH_REPORTS = "SEARCH_REPORTS";
export const REPORT_RESET_FOUND = "REPORT_RESET_FOUND";

export const REPORTS_GET_COMPANY = "REPORTS_GET_COMPANY";
export const REPORTS_GET_CUSTOMER = "REPORTS_GET_CUSTOMER";

export const REPORT_NEW = "REPORT_NEW";
export const REPORT_UPDATE = "REPORT_UPDATE";

export const REPORT_SELECTED_SUMMARY = "REPORT_SELECTED_SUMMARY";

export const FINALREPORT_NEW = "FINALREPORT_NEW";
export const FINALREPORT_UPDATE = "FINALREPORT_UPDATE";

export const REPORT_CLEAR_STATES = "REPORT_CLEAR_STATES";

export const REPORT_INITIAL = "REPORT_INITIAL";

export const REPORT_DELETE = "REPORT_DELETE";

export const REPORT_SELECT = "REPORT_SELECT";

export const CLEAR_SELECTED_REPORT = "CLEAR_SELECTED_REPORT";

export const DELETE_FINAL = "DELETE_FINAL";
