import {
  ADD_NEW_MESSAGE,
  CHATROOM_INITIAL_STATES,
  DELETE_CHAT_ROOM,
  GET_CHAT_ROOMS,
  GET_ROOM,
  GET_ROOM_MESSAGES,
  MESSAGE_SEND_ROOM,
  NEW_ROOM,
  SET_ROOM_MESSAGES,
  SET_SELECTED_ROOM,
  SORT_ROOMS,
  UPDATE_CHAT_ROOM,
  UPDATE_MESSAGE,
  UPDATE_ROOM_MSG,
  SET_ROOMS,
} from "../types/chatRoom.types";

const initialState = {
  loadingRooms: true,
  rooms: null,
  selectedRoom: null,
  loadingRoom: true,

  roomMessages: [],
  loadingMessages: true,
  loadedLength: 0,

  messagesCount: undefined,
  limit: undefined,
  loaded: 0,
  resLength: undefined,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CHAT_ROOMS:
      return {
        ...state,
        rooms: payload,
        loadingRooms: false,
      };
    case GET_ROOM_MESSAGES:
      return {
        ...state,
        selectedRoom: payload.room,
        loadingRoom: false,
        roomMessages: [...state.roomMessages, ...payload.roomMessages],
        loadedLength: payload.length,
        loadingMessages: false,
        messagesCount: payload.totalCount,
        limit: payload.limit,
        loaded: state.loaded + payload.loaded,
        resLength: payload.loaded,
      };
    case MESSAGE_SEND_ROOM:
      return {
        ...state,
        roomMessages: [payload, ...state.roomMessages],
      };

    case ADD_NEW_MESSAGE:
      return {
        ...state,
        roomMessages: [payload, ...state.roomMessages],
      };
    case DELETE_CHAT_ROOM:
      return {
        ...state,
        rooms: state.rooms.filter((el) => el._id !== payload.roomId),
      };
    case NEW_ROOM:
      return {
        ...state,
        rooms: [payload, ...state.rooms],
      };
    case CHATROOM_INITIAL_STATES:
      return initialState;

    case GET_ROOM:
      return {
        ...state,
        selectedRoom: payload,
        loadingRoom: false,
      };

    case UPDATE_CHAT_ROOM:
      return {
        ...state,
        rooms: state.rooms.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        roomMessages: state.roomMessages.map((el) =>
          el._id === payload._id ? { ...el, ...payload } : el
        ),
      };
    case SET_SELECTED_ROOM:
      return {
        ...state,
        selectedRoom: payload.room,
        loadingRoom: payload.loading,
        loadingMessages: true,
        roomMessages: [],
        loadedLength: 0,

        messagesCount: undefined,
        limit: undefined,
        loaded: 0,
        resLength: undefined,
      };

    case SET_ROOM_MESSAGES:
      return {
        ...state,
        roomMessages: payload.messages,
        loadingMessages: payload.loading,
        messagesCount: undefined,
        limit: undefined,
        loaded: 0,
        resLength: undefined,
      };
    case UPDATE_ROOM_MSG:
      return {
        ...state,
        rooms: state.rooms.map((el) =>
          el._id === payload.roomId
            ? {
                ...el,
                latestMessage: {
                  createdAt: payload.createdAt,
                  message: payload.message,
                  author: payload.author,
                },
                newestMessageDate: payload.createdAt,
                unread: el.unread + 1,
              }
            : el
        ),
      };
    case SORT_ROOMS:
      return {
        ...state,
        rooms: state.rooms.slice().sort(function (a, b) {
          var nameA = b.newestMessageDate,
            nameB = a.newestMessageDate;
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }),
      };

    case SET_ROOMS:
      return {
        ...state,
        rooms: payload.rooms,
        loadingRooms: payload.loading,
      };

    default:
      return state;
  }
}
