import React from "react";
import { Navbar, DropdownButton, Dropdown, Container } from "react-bootstrap";
import {
  iconCalendar,
  iconClock,
  iconFile,
  iconHistory,
  iconHome,
  iconImages,
  iconItem,
  iconLeft,
  iconMenu,
  iconRefresh,
} from "../Icons/Icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CustomNavBar.scss";
import { useDispatch, useSelector } from "react-redux";

import { Translate, withLocalize } from "react-localize-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
  actionLogout,
  actionUpdateLanguage,
} from "../../redux/actions/action.auth";
import { actionClearAlerts } from "../../redux/actions/action.alert";
import { actionClearToasts } from "../../redux/actions/action.toast";
import { actionClearModals2 } from "../../redux/actions/action.modal";
import { actionClearCompany } from "../../redux/actions/action.company";
import { actionSelectTab } from "../../redux/actions/action.session";

import {
  onlyTime,
  onlyDateWithDayName,
  dateWithDayNameAndTime,
  getWeekNumber,
  formatDate,
} from "../../utils/toDates";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";

import HideCheckComponent from "../../components/RestrictComponents/HideCheckComponent";

import logo from "../../images/Info247_logo_500x200.png";

const CustomNavBar = ({ activeLanguage, setActiveLanguage, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const menuIcon = (
    <FontAwesomeIcon
      icon={iconMenu}
      className="iconBtn"
      size="lg"
      color="black"
    />
  );

  const backIcon = (
    <FontAwesomeIcon
      icon={iconLeft}
      className="iconBtn"
      size="lg"
      color="black"
    />
  );

  const clockIcon = (
    <FontAwesomeIcon
      icon={iconClock}
      className="iconBtn"
      size="sm"
      color="black"
    />
  );

  const calendarIcon = (
    <FontAwesomeIcon
      icon={iconCalendar}
      className="iconBtn"
      size="sm"
      color="black"
    />
  );

  const itemIcon = <FontAwesomeIcon icon={iconItem} className="tabIcons" />;

  const filesIcon = <FontAwesomeIcon icon={iconImages} className="tabIcons" />;

  const historyIcon = (
    <FontAwesomeIcon icon={iconHistory} className="tabIcons" />
  );

  const reportIcon = <FontAwesomeIcon icon={iconFile} className="tabIcons" />;

  const { activeTab, showMenu, from } = useSelector((state) => state.session);
  const tab = activeTab ? activeTab : "1";

  const { selectedItem } = useSelector((state) => state.item);

  //const { isBottom, refresBottom } = useSelector((state) => state.session);

  const {
    userGroup,
    loading,
    user: { companyName, _id },
    isAuthenticated,
    appData,
    user: { company_info },
  } = useSelector((state) => state.auth);

  const company_use_customer = company_info?.has_customers;

  const switchLanguageNoLogin = () => {
    if (activeLanguage.code === "en") {
      setActiveLanguage("fi");
    } else {
      setActiveLanguage("en");
    }
  };

  const switchLanguage = () => {
    if (activeLanguage.code === "en") {
      dispatch(
        actionUpdateLanguage({ id: _id, language: "fi", userGroup: userGroup })
      );
      setActiveLanguage("fi");
    } else if (activeLanguage.code === "fi") {
      setActiveLanguage("en");
      dispatch(
        actionUpdateLanguage({ id: _id, language: "en", userGroup: userGroup })
      );
    }
  };

  const clearMemoryAndLogout = () => {
    dispatch(actionClearAlerts());
    dispatch(actionClearToasts());
    dispatch(actionClearModals2());
    dispatch(actionClearCompany());
    dispatch(actionLogout());

    window.localStorage.clear(); //try this to clear all local storage

    if (window.history.replaceState) {
      window.history.replaceState(null, null, null);
    }
  };

  const scrollTo80 = (k) => {
    dispatch(actionSelectTab(parseInt(k)));
    history.replace({
      pathname: `/item/${selectedItem._id}`,
      state: { ...location.state, tab: parseInt(k) },
    });
    if (window.pageYOffset >= 80) {
      window.scrollTo(0, 80);
    }
  };

  const goToPage = (page) => {
    history.replace(page);
  };

  const noLoginMenu =
    showMenu && !userGroup && !_id ? (
      <Translate>
        {({ translate }) => (
          <DropdownButton
            id="dropdown-basic-button"
            title={menuIcon}
            variant="none"
            alignRight
          >
            <Dropdown.Item onClick={switchLanguageNoLogin}>
              {translate("misc.lang")}
            </Dropdown.Item>
          </DropdownButton>
        )}
      </Translate>
    ) : null;

  const companyMenu =
    showMenu && !loading && userGroup && userGroup < 11 ? (
      <Translate>
        {({ translate }) => (
          <DropdownButton
            id="dropdown-basic-button"
            title={menuIcon}
            variant="none"
            alignRight
          >
            {companyName && (
              <>
                <Dropdown.Item disabled>{companyName}</Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}

            <HideCheckComponent required={["READ"]} field="userControl">
              <Dropdown.Item as={Link} to="/company/user-control">
                Käyttäjärekisteri
              </Dropdown.Item>
            </HideCheckComponent>

            {company_use_customer && (
              <HideCheckComponent
                required={["readCustomers", "readContacts"]}
                field="customersControl"
                atLeastOne={true}
              >
                <Dropdown.Item as={Link} to="/customers-control">
                  Asiakasrekisteri
                </Dropdown.Item>
              </HideCheckComponent>
            )}

            {userGroup === 1 && (
              <Dropdown.Item as={Link} to="/tags">
                Tägien hallinta
              </Dropdown.Item>
            )}

            <Dropdown.Divider />

            <Dropdown.Item as={Link} to="/changelog">
              {translate("navbar.changeLog")}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => history.push("/myprofile")}>
              {translate("navbar.myProfile")}
            </Dropdown.Item>
            {userGroup === 1 ? (
              <Dropdown.Item onClick={() => history.push("/companyprofile")}>
                {translate("navbar.companyInfo")}
              </Dropdown.Item>
            ) : null}
            <Dropdown.Divider />
            <Dropdown.Item onClick={switchLanguage}>
              {translate("misc.lang")}
            </Dropdown.Item>
            <Dropdown.Item className="logOut" onClick={clearMemoryAndLogout}>
              {translate("navbar.logout")}
            </Dropdown.Item>
          </DropdownButton>
        )}
      </Translate>
    ) : null;

  const customerMenu =
    showMenu && !loading && userGroup && userGroup > 10 ? (
      <Translate>
        {({ translate }) => (
          <DropdownButton
            id="dropdown-basic-button"
            title={
              <div className="menuDiv">
                {menuIcon}{" "}
                {appData && appData.companyInPresent && (
                  <div className="circle" />
                )}{" "}
              </div>
            }
            variant="none"
            alignRight
          >
            {appData && appData.companyInPresent ? (
              <>
                <Dropdown.Item disabled>
                  <div className="presentDiv">
                    <p>Yritys paikalla</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            ) : null}

            <Dropdown.Item as={Link} to="/changelog">
              {translate("navbar.changeLog")}
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item as={Link} to="/myprofile">
              {translate("navbar.myProfile")}
            </Dropdown.Item>

            {userGroup === 11 && (
              <Dropdown.Item as={Link} to="/customer/user-control">
                {translate("navbar.userControl")}
              </Dropdown.Item>
            )}

            <Dropdown.Divider />

            <Dropdown.Item onClick={switchLanguage}>
              {translate("misc.lang")}
            </Dropdown.Item>
            <Dropdown.Item className="logOut" onClick={clearMemoryAndLogout}>
              {translate("navbar.logout")}
            </Dropdown.Item>
          </DropdownButton>
        )}
      </Translate>
    ) : null;

  const consumerMenu =
    showMenu && !loading && !userGroup && _id ? (
      <Translate>
        {({ translate }) => (
          <DropdownButton
            id="dropdown-basic-button"
            title={menuIcon}
            variant="none"
            alignRight
          >
            <Dropdown.Item className="logOut" onClick={clearMemoryAndLogout}>
              {translate("navbar.logout")}
            </Dropdown.Item>
          </DropdownButton>
        )}
      </Translate>
    ) : null;

  const itemInfo =
    selectedItem && location.pathname.includes("item") ? (
      <div className="itemInfoDiv">
        <div className="divLeft">
          <p className="p-title">{selectedItem.title}</p>
          {userGroup < 11 ? (
            <p className="p-name">
              {selectedItem.customer?.name || "EI ASIAKASTA"}
            </p>
          ) : null}

          {userGroup > 10 ? (
            <p className="p-name">{selectedItem.company?.name}</p>
          ) : null}
        </div>

        <div className="divRight">
          {!selectedItem.useTimeRange ? (
            <>
              <p className="p-date">
                <span>{calendarIcon}</span>
                {selectedItem.date
                  ? onlyDateWithDayName(selectedItem.date)
                  : "--:--"}
              </p>

              <p>
                <span>{clockIcon}</span>
                {selectedItem.date ? onlyTime(selectedItem.date) : "--:--"}
              </p>
            </>
          ) : selectedItem.start && selectedItem.stop ? (
            <>
              <p className="p-date">
                <span>{calendarIcon}</span>{" "}
                {dateWithDayNameAndTime(selectedItem.start)}
              </p>
              <p className="p-date">
                <span>{calendarIcon}</span>{" "}
                {dateWithDayNameAndTime(selectedItem.stop)}
              </p>
            </>
          ) : (
            <p>--:--</p>
          )}
        </div>
      </div>
    ) : null;

  const isToDo = selectedItem?.itemType === "todo";

  const itemTabs =
    userGroup &&
    userGroup !== "consumer" &&
    selectedItem &&
    location.pathname.includes("item") ? (
      <div className="sticky">
        <Tabs
          activeKey={tab}
          onSelect={(k) => scrollTo80(k)}
          className="itemIconTabs"
          onContextMenu={(e) => {
            //this prevents righ-click contentmenue event on long tab to pop up the menu
            e.preventDefault();
          }}
        >
          <Tab
            eventKey="1"
            title={
              <div className="iconDiv">
                {itemIcon}
                <p className="iconText">Perustiedot</p>
              </div>
            }
          />
          <Tab
            eventKey="2"
            title={
              <div className="iconDiv">
                {filesIcon}
                <p className="iconText">
                  Liitteet (
                  {selectedItem &&
                    selectedItem.images &&
                    selectedItem.images.length}
                  )
                </p>
              </div>
            }
          />

          {!isToDo ? (
            <Tab
              eventKey="3"
              title={
                <div className="iconDiv">
                  {historyIcon}
                  <p className="iconText">Historia</p>
                </div>
              }
            />
          ) : null}

          {!isToDo ? (
            <Tab
              eventKey="5"
              title={
                <div className="iconDiv">
                  {reportIcon}
                  <p className="iconText">
                    Raportit (
                    {selectedItem && userGroup < 11
                      ? `${selectedItem.reportsCount || "0"} / ${
                          selectedItem.finalReportCount || "0"
                        }`
                      : "-"}
                    )
                  </p>
                </div>
              }
            />
          ) : null}
        </Tabs>
      </div>
    ) : null;

  /*const handleScroll = (e) => {
    // tunnistaa onko scrollattu loppuun
    // session redux arvo "isBottom"
    if (refresBottom) {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        dispatch(actionSetBottom(true));
      } else {
        if (isBottom) {
          dispatch(actionSetBottom(false));
        }
      }
    }
  };*/

  return (
    <>
      <Navbar className="navBar" sticky="top">
        <Container fluid className="navContainer">
          <div className="leftDiv">
            <div className="dates">
              <span>{formatDate(new Date(), "dd DD.MM")}</span>
              <span>
                Vko:
                {getWeekNumber(new Date())}
              </span>
            </div>
            <span
              onClick={() => {
                if (from) {
                  history.replace(from);
                } else {
                  history.goBack();
                }
              }}
              onContextMenu={(e) => {
                //this prevents righ-click contentmenue event on long tab to pop up the menu
                e.preventDefault();
                history.push("/");
              }}
            >
              {backIcon}
            </span>
          </div>

          <div className="centerDiv">
            <img alt="logo" src={logo} />
          </div>

          <div className="rightDiv">
            {userGroup && (
              <>
                <FontAwesomeIcon
                  icon={iconRefresh}
                  size="lg"
                  className="btnIcon"
                  onClick={() => window.location.reload()}
                />
                {isAuthenticated && (
                  <FontAwesomeIcon
                    icon={iconHome}
                    size="lg"
                    className="btnIcon"
                    onClick={() => goToPage("/")}
                  />
                )}
              </>
            )}
            {noLoginMenu} {companyMenu} {customerMenu} {consumerMenu}
          </div>
        </Container>
      </Navbar>

      <div
        className="children"
        //</>onScroll={handleScroll}
      >
        {!isToDo ? itemInfo : null}

        {itemTabs}
        {children}
        {userGroup && location.pathname !== "/" && (
          <footer>
            <img alt="logo" src={logo} />
          </footer>
        )}
      </div>
    </>
  );
};

export default withLocalize(CustomNavBar);
