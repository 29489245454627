import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  actionAlert,
  actionRemoveAlert,
} from "../../redux/actions/action.alert";
import { actionCompanyUploadFile } from "../../redux/actions/action.company";
import { actionCustomerUploadFile } from "../../redux/actions/action.customer";
import { actionSocketBasic } from "../../redux/actions/action.socket";

const useUpload = (setFiles, folder, id, showToCustomer) => {
  const dispatch = useDispatch();

  const [fileData, setFileData] = useState({
    myFile: null,
    fileExtension: "",
    fileName: "",
    legend: "",
    uploading: false,
  });

  const [uploading, setUploading] = useState(false);

  const onFileSelected = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      const maxImageSize = 200 * 1024 * 1024; // 80Mb
      alert[0] && dispatch(actionRemoveAlert(alert[0].id));

      let fileName = file.name;

      var extension = fileName.split(".").pop();

      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|pdf||mp4||mkv)$/)) {
        setFileData((state) => ({
          ...state,
          myFile: null,
          fileExtension: "",
          fileName: "",
          legend: "",
        }));

        //annetaan alert
        return dispatch(actionAlert("Väärä tiedostotyyppi", "danger"));
      } else if (file.size > maxImageSize) {
        // image size check

        setFileData((state) => ({
          ...state,
          myFile: null,
          fileExtension: "",
          fileName: "",
          legend: "",
        }));

        //annetaan alert
        return dispatch(
          actionAlert("Liian iso tiedosto (max 200MB)", "danger")
        );
      }

      setFileData((state) => ({
        ...state,
        myFile: file,
        fileExtension: extension,
        fileName: fileName,
        legend: fileName,
      }));
    }
  };

  const uploadFileCompany = (e, data) => {
    e.preventDefault();

    setUploading(true);
    const content = data
      ? data
      : {
          myFile: fileData.myFile,
          fileExtension: fileData.fileExtension,
          legend: fileData.legend,
          folder: folder,
          id: id,
          showToCustomer: showToCustomer,
          category: fileData.category,
        };

    dispatch(actionCompanyUploadFile(content)).then((data) => {
      if (data) {
        // live päivitetään uusi tiedosto reduxiin responsesta
        if (data.folder === "finalReport") {
          setFiles(data.newFile);

          setFileData({
            myFile: null,
            fileExtension: "",
            fileName: "",
            legend: "",
          });

          let showToCustomer = false;

          if (data.newFile && data.newFile.showToCustomer) {
            showToCustomer = true;
          }

          const socketContent = {
            event: "final_report",
            subEvent: "new_file",
            title: "Uusi kuva koosteella",
            toCompany: data.socket ? data.socket.companyId : null,
            toCustomer: showToCustomer
              ? data.socket
                ? data.socket.customerId
                : null
              : null,
            author: data.socket ? data.socket.author : "",
            data: {
              itemId: data.socket ? data.socket.itemId : null,
              reportId: data.socket ? data.socket.reportId : null,
            },
          };

          dispatch(actionSocketBasic(socketContent));
        } else if (data.folder === "item") {
          //  dispatch(actionClearModals2());
          setFiles(data.newFile);

          setFileData({
            myFile: null,
            fileExtension: "",
            fileName: "",
            legend: "",
          });
        }
        dispatch(actionAlert("Tiedosto lisätty", "success"));
      } else {
        dispatch(actionAlert("Epäonnistui", "danger"));
      }

      setUploading(false);
    });
  };

  const uploadFileCustomer = (e, data) => {
    e.preventDefault();

    setUploading(true);
    const content = data
      ? data
      : {
          myFile: fileData.myFile,
          fileExtension: fileData.fileExtension,
          legend: fileData.legend,
          folder: folder,
          id: id,
        };

    dispatch(actionCustomerUploadFile(content)).then((data) => {
      if (data) {
        if (data.folder === "item") {
          // live päivitys
          setFiles(data.newFile);

          setFileData({
            myFile: null,
            fileExtension: "",
            fileName: "",
            legend: "",
          });
        }
        dispatch(actionAlert("Tiedosto lisätty", "success"));
      } else {
        dispatch(actionAlert("Epäonnistui", "danger"));
      }

      setUploading(false);
    });
  };

  return {
    fileData,
    uploading,
    uploadFileCompany: (e, data) => uploadFileCompany(e, data),
    uploadFileCustomer: (e, data) => uploadFileCustomer(e, data),

    selectFile: (e) => onFileSelected(e),
    setFileData,
  };
};

export default useUpload;
